import { ApplicationState } from "../store/rootReducer";
import { ApiCallerWithCustomer } from "./ApiCallerWithCustomer";
import { ApiCallerWithToken } from "./ApiCallerWithToken";
import { ApiFileDownloader } from "./ApiFileDownloader";
import appSettings from "./appSettings";

export function apiCaller(customerNumberOrState: string | ApplicationState | (() => ApplicationState)): ApiCallerWithCustomer  {

    let customerNumber = '';

    if(customerNumberOrState  instanceof Function){
        customerNumber = customerNumberOrState().customerChooserData.selectedNumber;
    }
    else if(customerNumberOrState instanceof String || typeof customerNumberOrState === 'string'){
        customerNumber = customerNumberOrState as string;
    }
    else if(customerNumberOrState){
        customerNumber = (customerNumberOrState as ApplicationState).customerChooserData.selectedNumber;
    }

    // console.log(`Selected CustomerNumber: ${customerNumber}`);

    return new ApiCallerWithCustomer(appSettings.backendUrl, customerNumber);
}

export function apiFileDownloader(customerNumberOrState: string | ApplicationState | (() => ApplicationState)): ApiFileDownloader{
    
    const caller = apiCaller(customerNumberOrState);

    return new ApiFileDownloader(caller);
}


/**
 * Api Caller without the currently selected CustomerNumber
 */
export function apiCallerGlobal(): ApiCallerWithToken  {
    return new ApiCallerWithToken(appSettings.backendUrl);
}
