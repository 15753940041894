import { UserAddOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { AdminCustomerGroupItem } from '../../../models/AdminCustomerGroups';
import { useModalState } from '../../utils/modalStateHook';
import AdminUserCreateAdminOrManagerForm from './AdminUserCreateAdminOrManagerForm';

interface Props {
    customerGroups?: AdminCustomerGroupItem[];
    refresh(): void;
    admin?: boolean;
}

export default function (props: Props) {
    const modalState = useModalState();

    const buttonText = props.admin ? 'Create Admin' : 'Create Manager';

    const buttonType = props.admin ? 'primary': 'default';

    return (<>
        <Button type={buttonType} icon={<UserAddOutlined />} onClick={modalState.open} >{buttonText}</Button>
        {modalState.show && <AdminUserCreateAdminOrManagerForm show={modalState.show} onClose={modalState.close} onSuccess={props.refresh} customerGroups={props.customerGroups} isAdmin={props.admin} />}
    </>);
}