import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

interface Props {
    largeSize?: boolean;
    children?: React.ReactNode;
}

export default function (props: Props) {

    let style = { } as any;

    if(props.largeSize){
        style = {...style, fontSize: '16px'}
    }

    return (<Tooltip color="#444" title={<>{props.children}</>}>
        <InfoCircleOutlined style={style}  />
    </Tooltip>);
}