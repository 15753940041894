export const comparators = {
    stringCompare: (a?: string | null, b?: string | null)=>{
        if(!a && !b){
            return 0;
        }

        if(!a){
            return 1;
        }

        if(!b){
            return -1;
        }

        return a.localeCompare(b);
    }
}

export function gerRecordComparator<T, TProperty>(accessor: (record: T) => TProperty | null | undefined, comparatorFunc: (a?: TProperty | null, b?: TProperty | null) => number){

    return function(recordA: T, recordB: T){

        if(!recordA && !recordB){
            return 0;
        }

        const valueA = recordA ? accessor(recordA) : undefined;

        const valueB = recordB ? accessor(recordB) : undefined;

        return comparatorFunc(valueA, valueB);
    }


}