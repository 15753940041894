import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React from 'react';
import { MonthWeekInfo } from '../../../models/Documents';

interface Props {
    value: number | null;
    onChange(value: number | null): void;
    valueSet: MonthWeekInfo[];
    emptyText: string;
}

export default function (props: Props) {

    function onChange(value: SelectValue) {
        if (value && value !== '') {
            props.onChange(Number(value));
        }
        else {
            props.onChange(null);
        }
    }

    return (<Select value={props.value ?? ''} onChange={onChange} disabled={props.valueSet.length === 0} style={{width: '100%'}}>
        <Select.Option value="">{props.emptyText}</Select.Option>
        {props.valueSet.map(item => <Select.Option key={item.number} value={item.number}>{item.name}</Select.Option>)}
    </Select>);
}