import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import { apiCaller } from "../../backend/apiCaller";
import { UserRecord } from "../../models/Users";
import { ApplicationState } from "../rootReducer";

export const userSlice = createSlice({
    name: 'users',
    initialState: {
        inProgress: true,
        data: [] as UserRecord[],
        filteredData: [] as UserRecord[],

        search: '',
        type: '',
        customerNumber: '',
		isInitialized: false,
    },
    reducers: {
        startLoading(state) { state.inProgress = true },
        endLoading(state) { state.inProgress = false },
        setData(state, action: PayloadAction<UserRecord[]>) { state.data = action.payload; state.filteredData = action.payload },

        setSearch(state, action: PayloadAction<string>) {
            state.search = action.payload;
            state.filteredData = filter(state.data, state.search, state.type, state.customerNumber);
        },
        setType(state, action: PayloadAction<string>) {
            state.type = action.payload;
            state.filteredData = filter(state.data, state.search, state.type, state.customerNumber);
        },
        setCustomerNumber(state, action: PayloadAction<string>) {
            state.customerNumber = action.payload;
            state.filteredData = filter(state.data, state.search, state.type, state.customerNumber);
        },
		setInitialized(state){ state.isInitialized = true;},
        reInit(state){ state.isInitialized = false;},
    }
});


function filter(allUsers: UserRecord[], search: string, type: string, customerNumber: string): UserRecord[] {

    let result = allUsers;

    if (search) {
        search = search.trim().toLowerCase();

        result = result.filter(s => s.lastName.toLowerCase().includes(search)
            || s.firstName.toLowerCase().includes(search)
            || s.email.toLowerCase().includes(search))
            ;
    }

    if (type) {

        if (type === 'manager') {
            result = result.filter(s => s.isManager);
        }
        else if (type === 'basicuser') {
            result = result.filter(s => !s.isManager);
        }
    }

    // if (customerNumber) {
    //     result = result.filter(s => s.customerNumber === customerNumber);
    // }

    return result;
}   


export function fetchUserThunk() {
    return function (dispatch: Dispatch, getState: () => ApplicationState) {

        dispatch(userSlice.actions.startLoading());
        
        return apiCaller(getState).getJson('/api/user')
            .then(response => response.json())
            .then((results: UserRecord[]) => {
                dispatch(userSlice.actions.setData(results));
                dispatch(userSlice.actions.setInitialized());
            })
            .catch(reason => notification.error({ message: `Cant' load Users` }))
            .finally(() => dispatch(userSlice.actions.endLoading()));
    }
}