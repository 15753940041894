import { Select } from 'antd';
import React from 'react';
import { DocumentTypeEnum, documentTypeInfos } from '../../../models/Documents';

const Option = Select.Option;


interface Props {
    value: DocumentTypeEnum | null;
    onChange(value: DocumentTypeEnum): void;
    valueSet: DocumentTypeEnum[];
}


export default function (props: Props) {

    return (<Select value={props.value ?? undefined} onChange={props.onChange} placeholder="Document Type"  disabled={props.valueSet.length === 0} style={{width: '100%'}}>
        {props.valueSet.map(item => <Option key={item} value={item}>{documentTypeInfos[item].name}</Option>)}     
    </Select>);
}

