import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { apiCallerGlobal } from '../../../backend/apiCaller';
import { AdminCustomerGroupRecord } from '../../../models/AdminCustomerGroups';
import { useModalState } from '../../utils/modalStateHook';
import UfoConfirmOperationModal from '../../utils/UfoConfirmationModal/UfoConfirmOperationModal';

interface Props {
    record: AdminCustomerGroupRecord;
    onSuccess(): void;
}

export default function (props: Props) {
    const modalState = useModalState();

    const record = props.record;

    const basicUserRelationCount = record.customers.map(s => s.userCount).reduce((prev, current) => prev + current, 0);

    async function remove() {
        await apiCallerGlobal().postJson('/admin/customergroup/delete', { id: record.id });
    }

    return (<>
        <Tooltip title="Remove Customer Group">
            <Button type="primary" shape="circle" danger onClick={modalState.open}><DeleteOutlined /></Button>
        </Tooltip>
        {modalState.show && <UfoConfirmOperationModal show={modalState.show} onClose={modalState.close}
            title="Remove Customer Group"
            operation={remove}
            onSuccess={props.onSuccess}
            okText="Yes, delete!">
            <p><strong>IMPORTANT</strong></p>
            <p>Are you sure you want to remove Customer Group <strong>{props.record.name}</strong> ?</p>
            <p>It has: </p>
            <ul>
                <li>{record.managers.length} Manager(s). (Their relation will be removed)</li>
                <li>{record.customers.length} Customer(s). (The Customers will be orphaned)</li>
                {basicUserRelationCount > 0 && <li>There is at least 1 Customer which has BasicUsers assigned to. These assignment will be removed as well</li>}
            </ul>
            <p>The operation is irreversible.</p>
        </UfoConfirmOperationModal>}
    </>);
}