import React from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { useSelectorShallow } from '../../../store/selectorShallow';
import { fetchStockThunk, stockSlice } from '../../../store/slices/StockSlice';
import PageHeader from '../../utils/PageHeader/PageHeader';
import PageTitle from '../../utils/PageTitle';
import StockDailyTable from './StockDailyTable';
import StockTableFilter from './StockTableFilter';
import styles from './PageStock.module.css';
import StockProjectedTable from './StockProjectedTable';
import StockWeeklyTable from './StockWeeklyTable';
import { useInvalidatableInitEffect } from '../../utils/usePageDataLoad';

export default function () {

    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.stock);

    function refreshPage() {
        dispatch(fetchStockThunk());
    }

    useInvalidatableInitEffect(refreshPage, reduxData);

    const hasDailyData = reduxData.data.daily && reduxData.data.daily.length > 0;

    return (<>
        {/* <span>is init: {reduxData.isInitialized ? 'TRUE': 'FALSE'}</span> */}
        <PageHeader title="Stock" />
        <PageTitle title="Stock" />

        <StockTableFilter isoWeek={reduxData.data.selectedWeek} isoWeekList={reduxData.data.weekList}
            onIsoWeekChange={value => {
                dispatch(stockSlice.actions.setIsoWeek(value));
                dispatch(fetchStockThunk());
            }} />

        <div className={styles.data}>
            <div className={styles.section}>
                <h2>Closed Weekly Balance</h2>
                {reduxData.data.week && <StockWeeklyTable inProgress={reduxData.inProgress} item={reduxData.data.week} refresh={refreshPage} isoWeek={reduxData.data.selectedWeek} />}
                {!reduxData.data.week && <p className={styles.notAvailable}>Your closed weekly balance is not yet available.</p>}
            </div>

            {hasDailyData && <div className={styles.section}>
                <h2>Daily Balance</h2>
                <StockDailyTable inProgress={reduxData.inProgress} items={reduxData.data.daily} hasTpAddBack={reduxData.data.hasDailyTpAddBack} />
            </div>}

            {reduxData.data.projected && reduxData.data.projected.length > 0 && <div className={styles.section}>
                <h2>Projected Future Balance</h2>
                <StockProjectedTable items={reduxData.data.projected} />
            </div>}
        </div>
    </>);
}