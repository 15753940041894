import { Alert, Col, Form, Input, Modal, notification, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useState } from 'react';
import { apiCallerGlobal } from '../../../backend/apiCaller';
import { AdminCustomerGroupItem } from '../../../models/AdminCustomerGroups';
import { useUfoErrorHandling } from '../../utils/UfoErrors/ufoErrorHook';
import UfoErrorList from '../../utils/UfoErrors/UfoErrorList';
import PhoneFormItem from '../Common/PhoneFormItem';

interface Props {
    show: boolean;
    onClose(): void;
    onSuccess(): void;
    customerGroups?: AdminCustomerGroupItem[];
    isAdmin?: boolean;
}

export default function (props: Props) {

    const [inProgress, setInProgress] = useState(false);

    const [form] = useForm();

    const errorHandler = useUfoErrorHandling();

    async function submit(values: any) {

        setInProgress(true);

        try {
            await apiCallerGlobal().postJson('/admin/user/create', {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
                customerGroupId: values.customerGroupId,
                isAdmin: props.isAdmin,
            });

            setInProgress(false);
            notification.success({message: 'User has been created successfully'});

            form.resetFields();
            props.onClose();
            props.onSuccess();

        } catch (error) {
            setInProgress(false);
            //notification.error({ message: `Failed to create new ${props.isAdmin ? 'Administrator' : 'Manager'} user` });

            errorHandler.setError(error);
        }

    }

    const title = 'Create new ' + (props.isAdmin ? 'Administrator' : 'Manager');

    const okText = props.isAdmin ? 'Create Admin' : 'Create Manager';

    const customerGroupRequiredMessage = 'Please select a Customer Group for the Manager';

    return (<Modal visible={props.show} closable destroyOnClose onCancel={props.onClose}
        title={title} okText={okText} okButtonProps={{ disabled: inProgress }} confirmLoading={inProgress} onOk={form.submit}>
        <Form form={form} onFinish={submit} layout="vertical" autoComplete="off" onChange={errorHandler.reset}>

                {props.isAdmin && <Alert
                    message="Important"
                    description="You are creating an administrator user who will have elevated permissions. (like ability to query all Customer's data, creating new users, ...)"
                    type="warning"
                    showIcon
                    style={{marginBottom: '30px'}}
                />}
            
            <Row gutter={10}>
                <Col xs={12}>
                    <FormItem name="firstName" label="First Name" style={{ width: '100%' }}
                        rules={[{ required: true }, { max: 127 }]}>
                        <Input placeholder="First Name" autoComplete="off" />
                    </FormItem>
                </Col>
                <Col xs={12}>
                    <FormItem name="lastName" label="Last Name" style={{ width: '100%' }}
                        rules={[{ required: true }, { max: 127 }]}>
                        <Input placeholder="Last Name" autoComplete="off" />
                    </FormItem>
                </Col>
            </Row>

            <FormItem name="email" label="Email"
                rules={[{ required: true }, { max: 127 }, { type: 'email' }]}
            >
                <Input placeholder="Email" autoComplete="off" type="email" />
            </FormItem>

            <PhoneFormItem />

            {!props.isAdmin && <FormItem name="customerGroupId" label="Customer Group"
                rules={[{ required: true, message: customerGroupRequiredMessage }, { min: 1, message: customerGroupRequiredMessage }]}
            >
                <Select defaultActiveFirstOption>
                    {props.customerGroups?.map(item => <Select.Option key={item.id} value={item.id}>{item.distributorNumber} - {item.name}</Select.Option>)}
                </Select>
            </FormItem>}
            
            <UfoErrorList apiError={errorHandler.apiError} />
        </Form>
    </Modal>);
}