import React from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { useSelectorShallow } from '../../../store/selectorShallow';
import { fetchPurchasesThunk, purchaseSlice } from '../../../store/slices/PurchaseSlice';
import PageHeader from '../../utils/PageHeader/PageHeader';
import PageTitle from '../../utils/PageTitle';
import PurchasesTableFilter from './PurchasesTableFilter';
import PurchasesTable from './PurchasesTable';
import { useTableChange } from '../Common/tableChange';
import { useInvalidatableInitEffect } from '../../utils/usePageDataLoad';

export default function () {

	const dispatch = useDispatch();

	const reduxData = useSelectorShallow(s => s.purchases);

	const onTableChange = useTableChange(fetchPurchasesThunk, purchaseSlice.actions.setSortOrder, purchaseSlice.actions.setPageIndex);

	function refreshPage() {
		dispatch(fetchPurchasesThunk());
	}

	useInvalidatableInitEffect(refreshPage, reduxData);

	return (<>
		<PageTitle title="Purchases" />
		<PageHeader title="Purchases" />
		<PurchasesTableFilter

			fromDate={reduxData.fromDate}
			untilDate={reduxData.untilDate}
			type={reduxData.type}
			search={reduxData.search}
			onDateRangeChanged={(from, until) => {
				dispatch(purchaseSlice.actions.setDateRange([from, until]));
				dispatch(fetchPurchasesThunk());
			}}
			onSearchChange={value => {
				dispatch(purchaseSlice.actions.setSearch(value));
				dispatch(fetchPurchasesThunk());
			}}
			onTypeChanged={value => {
				dispatch(purchaseSlice.actions.setType(value));
				dispatch(fetchPurchasesThunk());
			}}

			page={reduxData.pageIndex}
            pageSize={reduxData.pageSize}
            sortOrder={reduxData.sortOrder}
		/>
		<PurchasesTable results={reduxData.data} inProgress={reduxData.inProgress} onTableChange={onTableChange} pageIndex={reduxData.pageIndex} pageSize={reduxData.pageSize} refresh={refreshPage}  />
	</>);
}