import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { AdminUserRecord } from '../../../models/AdminUsers';
import { useModalState } from '../../utils/modalStateHook';
import AdminUserEditAdminForm from './AdminUserEditAdminForm';

interface Props {
    refresh(): void;
    record: AdminUserRecord;
}

export default function (props: Props) {

    const modalState = useModalState();

    return (<>
        <Button type="primary" shape="circle" onClick={modalState.open} ><EditOutlined /></Button>
        {modalState.show && <AdminUserEditAdminForm show={modalState.show} onClose={modalState.close} onSuccess={props.refresh} record={props.record} />}
    </>);
}