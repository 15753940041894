import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from './MainMenuCard.module.css';

interface Props {
    title: string;
    path: string;
    icon: ReactNode;
}

export default function (props: Props) {
    return (<Link to={props.path}>
        <div className={styles.item}>
            <div className={styles.content}>
                <div className={styles.title}>{props.title}</div>
                <div className={styles.icon}>{props.icon}</div>
            </div>
        </div>
    </Link>);
}