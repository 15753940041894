import { useEffect, useRef } from "react";

type UfoCallback = (() => void | Promise<void>) ;


/// Original function: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: UfoCallback, delay?: number | null) {
    const savedCallback = useRef<UfoCallback>();
  
    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      async function tick() {
        if(savedCallback.current){
            await savedCallback.current();
        }
      }
      if (delay !== null && delay !== undefined) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }