import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';

interface Props {
    pin: string;
}

export default function (props: Props) {

    const [show, setShow] = useState(false);

    return (<>
        {!show && <Tooltip title="Show PIN">
            <Button  icon={<EyeOutlined />} onClick={() => setShow(true)} />
        </Tooltip>}

        {show && <>
            {props.pin} <Tooltip title="Hide PIN">
            <Button size="small" icon={<EyeInvisibleOutlined />}  onClick={() => setShow(false)} style={{marginLeft: 8}} />
        </Tooltip>
        </>}
    </>);
}