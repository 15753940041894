import React from 'react';
import { CustomerData } from '../../../models/Customers';
import NameNumberCell from './NameNumberCell';


interface Props {
    items: CustomerData[];
}

export default function (props: Props) {
    return (<>
        {props.items.map(item => <>
            <NameNumberCell name={item.name} number={item.number} numberTitle="Cust. No." /> <br />
        </>)}
    </>);
}