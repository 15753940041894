import * as React from 'react';
import { Col, Layout as AntLayout, Layout, Row } from 'antd';

import { ConfigProvider } from 'antd';
import enGB from 'antd/lib/locale-provider/en_GB';
import Navbar from './Navbar/Navbar';
import styles from './Layout.module.css'
import AppFooter from './Footer/AppFooter';
import MenuDrawer from './Navbar/Menu/MenuDrawer';
import MenuColumn from './Navbar/Menu/MenuColumn';

const { Header, Footer, /*Sider,*/ Content } = AntLayout;

interface Props {
	children: React.ReactNode;
}

export default function (props: Props) {

	return (
		<ConfigProvider locale={enGB}>
			<Layout className={styles.layout} >
				<Header className={styles.header}>

					<Navbar />
				</Header>
				<Content className={styles.content}>
					<Row wrap={false}>
						<MenuDrawer  />
						<MenuColumn />
						<Col flex="auto"><div className={styles.contentBox}>{props.children}</div></Col>
					</Row>
				</Content>

				<Footer className={styles.footer}>
					<AppFooter />
				</Footer>
			</Layout>
		</ConfigProvider>
	);
}