import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { notification, Spin } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { apiFileDownloader } from '../../../backend/apiCaller';
import { DocumentRecord, DocumentTypeEnum, documentTypeInfos } from '../../../models/Documents';
import styles from './DocumentItem.module.css';
import DocumentItemIcon from './DocumentItemIcon';


interface Props {
    item: DocumentRecord;
    currentCustomerNumber: string;
}

export default function (props: Props) {

    const [downloadInProgress, setDownloadInProgress] = useState(false);

    const item = props.item;

    function getDateInfo() {
        if (item.type === DocumentTypeEnum.WeeklyStatement || item.type === DocumentTypeEnum.WeeklyInvoice) {
            return `Week ${item.monthOrWeek}, ${item.year}`
        }

        return `${moment().month(item.monthOrWeek - 1).format('MMMM')} ${item.year}`;
    }

    const size = (item.sizeInBytes / 1000).toLocaleString('en-gb', { maximumFractionDigits: 0 })


    async function download() {

        if (!downloadInProgress) {

            setDownloadInProgress(true);

            try {
                await apiFileDownloader(props.currentCustomerNumber).download('/api/document/download', {
                    id: item.id,
                    type: item.type
                });

                setDownloadInProgress(false);

            } catch (error) {
                console.error(error);
                notification.error({ message: 'Can\'t download file' });
                setDownloadInProgress(false);
            }
        }
    }


    return (<div className={styles.wrapper} title={`Download: ${item.name}`} onClick={download}>
        <span className={styles.type}>{documentTypeInfos[item.type].name}</span>
        <span className={styles.date}>{getDateInfo()}</span>
        {!downloadInProgress && <div className={styles.iconBlock}>
            <div className={styles.iconWrapper}><DocumentItemIcon extension={item.extension} /></div>
            <DownloadOutlined className={styles.downloadIcon} />
        </div>}
        {downloadInProgress && <Spin size="large" style={{height: 76}} indicator={<LoadingOutlined style={{ fontSize: 24, color: '#000' }} spin />} />}
        <div className={styles.extension}>.{item.extension.toLowerCase()}</div>
        <span className={styles.size}>{size} KB</span>
    </div>);
}