import React from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { useSelectorShallow } from '../../../store/selectorShallow';
import { documentSlice, fetchDocumentsThunk } from '../../../store/slices/DocumentSlice';
import PageHeader from '../../utils/PageHeader/PageHeader';
import PageTitle from '../../utils/PageTitle';
import { useInvalidatableInitEffect } from '../../utils/usePageDataLoad';
import DocumentList from './DocumentList';
import DocumentListFilter from './DocumentListFilter';
import DocumentEmailSection from './Emails/DocumentEmailSection';

export default function () {
    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.documents);

    useInvalidatableInitEffect(() => dispatch(fetchDocumentsThunk()), reduxData);


    return (<>
        <PageHeader title="Invoices / Statements" />
        <PageTitle title="Invoices / Statements" />

        <DocumentEmailSection />

        <DocumentListFilter
            type={reduxData.selectedType}
            onTypeChange={(value) => dispatch(documentSlice.actions.setType(value))}
            types={reduxData.types}

            year={reduxData.selectedYear}
            years={reduxData.years}
            onYearChange={value => dispatch(documentSlice.actions.setYear(value))}

            monthOrWeek={reduxData.selectedMonthOrWeek}
            monthsOrWeeks={reduxData.monthsOrWeeks}
            onMonthOrWeekChange={value => dispatch(documentSlice.actions.setMonth(value))}
        />

        <DocumentList inProgress={reduxData.inProgress} items={reduxData.filteredData} />
    </>);
}