import React, { useEffect } from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { useSelectorShallow } from '../../../store/selectorShallow';
import { fetchAdminCustomerGroupChooserThunk } from '../../../store/slices/AdminCustomerGroupChooserSlice';
import { adminUserSlice, fetchAdminUserThunk } from '../../../store/slices/AdminUserSlice';
import { useDisableCustomerChooserEffect } from '../../utils/disableCustomerChooser';
import PageHeader from '../../utils/PageHeader/PageHeader';
import PageTitle from '../../utils/PageTitle';
import UfoButtonSection from '../../utils/UfoButtonSection/UfoButtonSection';
import { useTableChange } from '../Common/tableChange';
import AdminUserCreateAdminOrManagerButton from './AdminUserCreateAdminOrManagerButton';
import AdminUserTable from './AdminUserTable';
import AdminUserTableFilter from './AdminUserTableFilter';

export default function () {

    useDisableCustomerChooserEffect();

    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.adminUsers);

    const customerGroupsReduxData = useSelectorShallow(s => s.adminCustomerGroupChooserData);

    const onTableChange = useTableChange(fetchAdminUserThunk, adminUserSlice.actions.setSortOrder, adminUserSlice.actions.setPage);

    function refresh() {
        dispatch(fetchAdminUserThunk());
    }

    useEffect(() => {

        dispatch(fetchAdminCustomerGroupChooserThunk());

        refresh();
        // eslint-disable-next-line
    }, []);

    return (<>
        <PageHeader title="User Administration" />
        <PageTitle title="User Administration" />
        <UfoButtonSection>
            <AdminUserCreateAdminOrManagerButton admin refresh={refresh} />
            <AdminUserCreateAdminOrManagerButton refresh={refresh} customerGroups={customerGroupsReduxData.data} />
        </UfoButtonSection>
        <AdminUserTableFilter
            search={reduxData.search}
            onSearchChange={value => {
                dispatch(adminUserSlice.actions.setSearch(value));
                refresh();
            }}

            customerGroups={customerGroupsReduxData.data}
            customerGroupId={reduxData.customerGroupId}
            onCustomerGroupChange={id => {
                dispatch(adminUserSlice.actions.setCustomerGroupId(id));
                refresh();
            }}

            group={reduxData.group}
            onGroupChange={value => {
                dispatch(adminUserSlice.actions.setGroup(value));
                refresh();
            }}
        />

        <AdminUserTable inProgress={reduxData.inProgress} items={reduxData.data.items} totalCount={reduxData.data.totalCount} pageIndex={reduxData.pageIndex} pageSize={reduxData.pageSize} refresh={refresh} customerGroups={customerGroupsReduxData.data}
            onChange={onTableChange}
        />
    </>);
}