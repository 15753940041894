import { Col, Row, Select } from 'antd';
import React from 'react';
import ClearableTextFilter from '../../utils/ClearableTextFilter';
import UfoCsvDownloadButton from '../../utils/UfoCsvDownloadButton';
import UfoDateRangePicker from '../../utils/UfoDateRangePicker';

interface Props {
    search: string | null;
    onSearchChange(value: string): void;
    beforeDate?: string | null;
    afterDate?: string | null;
    onDateChange(from: string | null, until: string | null): void;

    product: string;
    onProductChanged(value: string): void;

    page: number;
    pageSize: number;
    sortOrder: string;

    hasTpDiesel: boolean;
    hiddenCustomer: boolean;
}

const Option = Select.Option;

export default function (props: Props) {

    let searchTitle = 'Search in Customer, Site, Tran. No., Registration fields';

    let searchPlaceHolder = 'Customer, Site, Tran. No., Registration';

    if(props.hiddenCustomer){
        searchTitle = 'Search in Site, Tran. No., Registration fields';

        searchPlaceHolder = 'Site, Tran. No., Registration';
    }

    return (<Row gutter={[20, 20]} align="bottom">
        <Col sm={11} md={7} lg={6}>
            <ClearableTextFilter value={props.search} onChange={props.onSearchChange} placeholder={searchPlaceHolder}
                title={searchTitle}
            />
        </Col>
        <Col sm={9} md={8} lg={6}>
            <UfoDateRangePicker from={props.afterDate} until={props.beforeDate} onChange={props.onDateChange}
                placeholder={['Date from', 'Date until']} />
        </Col>
        <Col md={6} sm={8}>
            <Select value={props.product} onChange={props.onProductChanged} style={{ width: '100%' }} >
                <Option value="" style={{ color: '#777', fontStyle: 'italic' }}>All Products</Option>
                <Option value='4'>Diesel</Option>
                {props.hasTpDiesel && <Option value='201'>TP Diesel</Option>}
                <Option value='6'>Gas Oil</Option>
                <Option value='7'>Lube Oil</Option>
                <Option value='11'>Unleaded</Option>
                <Option value='8'>Premium Unleaded</Option>
                <Option value='9'>Premium Diesel</Option>
                <Option value='1'>Ad Blue</Option>
                <Option value='14'>Ad Blue Pack</Option>
                <Option value='3'>Car Wash</Option>
                <Option value='2'>Bus Wash</Option>
                <Option value='10'>Super Unleaded</Option>
                <Option value='12'>Kerosene</Option>
                <Option value='13'>Lpg</Option>

                <Option value='200'>Other Products</Option>
            </Select>
        </Col>
        <Col >
            <UfoCsvDownloadButton url="/transaction/download" parameters={{ fromDate: props.afterDate, untilDate: props.beforeDate, search: props.search, product: props.product, page: props.page, pageSize: props.pageSize, sortOrder: props.sortOrder }} />
        </Col>
    </Row>);
}