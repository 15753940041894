import { Menu } from 'antd';
import React from 'react';
import { BarChartOutlined, BarsOutlined, DeliveredProcedureOutlined, FileOutlined, HomeFilled, MessageOutlined, SolutionOutlined, UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom';
import styles from './Menu.module.scss';
import useAuthenticatedUser from '../../../../auth/useAuthenticatedUser';

interface Props {
  collapsed?: boolean;
}

export default function (props: Props) {

  const isAdmin = useAuthenticatedUser().user.isAdmin;

  const location = useLocation();


  return (<Menu className={styles.menu} selectedKeys={[location.pathname]} mode="inline" inlineCollapsed={props.collapsed} theme="light"  >
    <Menu.Item key="/" icon={<HomeFilled />}><Link to="/">Home</Link></Menu.Item>
    <Menu.Item key="/stock" icon={<BarChartOutlined />}><Link to="/stock">Stock</Link></Menu.Item>
    <Menu.Item key="/purchases" icon={<DeliveredProcedureOutlined />}><Link to="/purchases">Purchases</Link></Menu.Item>
    {/* <Menu.Item key="/sales" icon={<ShopOutlined />}><Link to="/sales">Sales</Link></Menu.Item> */}
    <Menu.Item key="/transactions" icon={<BarsOutlined />}><Link to="/transactions">Transactions</Link></Menu.Item>
    {/* <Menu.Item key="/cards" icon={<CreditCardOutlined />}><Link to="/cards">Cards</Link></Menu.Item> */}
    <Menu.Item key="/invoices-statements" icon={<FileOutlined />}><Link to="/invoices-statements">Invoices / Statements</Link></Menu.Item>
    <Menu.Item key="/users" icon={<UsergroupAddOutlined />}><Link to="/users">Users</Link></Menu.Item>
    <Menu.Item key="/contact" icon={<MessageOutlined />}><Link to="/contact">Contact</Link></Menu.Item>
    {/* {isAdmin && <Menu.SubMenu key="/admin" className={styles.admin} icon={<SettingOutlined />} title="Admin">
        <Menu.Item  key="/admin/users" icon={<UserAddOutlined />}><Link to="/admin/users">Administer Users</Link></Menu.Item>
    </Menu.SubMenu> } */}
    {isAdmin && <><Menu.Divider />
      <Menu.Item className={styles.admin} key="/admin/users" icon={<UserAddOutlined />}><Link to="/admin/users">Administer Users</Link></Menu.Item>
      <Menu.Item className={styles.admin} key="/admin/customergroups" icon={<SolutionOutlined />}><Link to="/admin/customergroups">Administer Customer Groups</Link></Menu.Item>
    </>}
  </Menu>);
}