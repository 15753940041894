export const cardProducts: {id: number, name: string, shortName: string}[] = [
    {id: 1, name: 'Diesel', shortName: 'Diesel' },
    {id: 2, name: 'Unleaded', shortName: 'UNL'},
    {id: 3, name: 'Super Unleaded', shortName: 'SUN'},
    {id: 4, name: 'Ad Blue', shortName: 'Ad Blue'},
    {id: 5, name: 'LPG', shortName: 'LPG'},
    {id: 6, name: 'Car Wash', shortName: 'Car Wash'},
    {id: 7, name: 'LRP', shortName: 'LRP'},
    {id: 8, name: 'Goods', shortName: 'Goods'},
    {id: 9, name: 'Gas Oil', shortName: 'Gas Oil'},
    {id: 10, name: 'Lube Oil', shortName: 'Lube Oil'},
];