import React from 'react';

interface Props {
    children?: React.ReactNode;
}

export default function (props: Props) {
    return (<div style={{ width: '100%', maxWidth: '600px', margin: '10px auto 0 auto', padding: '20px 20px', backgroundColor: '#fff', border: '0px solid #ccc' }}>
        {props.children}
    </div>);
}