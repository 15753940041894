import  { useState } from "react";
import { ApiErrorDetails } from "../../../backend/ApiErrors";

export interface UfoErrorHandler{
    setError(apiError: any): void;
    reset(): void;
    apiError: ApiErrorDetails;
}

const apiErrorDefault = {isNetworkError: false, isServerError: false, messages: []} as ApiErrorDetails;

export function useUfoErrorHandling(): UfoErrorHandler{

    const [errorDetails, setErrorDetails] = useState<ApiErrorDetails>(apiErrorDefault);

    function setError (error: any){
        setErrorDetails(error);
    }
    return {
        setError: setError,
        reset: () => setErrorDetails(apiErrorDefault),
        apiError: errorDetails
    };
}