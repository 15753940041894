import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import {  apiCallerGlobal } from "../../backend/apiCaller";
import { AdminCustomerGroupRecord, AdminCustomerGroupResults } from "../../models/AdminCustomerGroups";
import { ApplicationState } from "../rootReducer";

const initialSortOrder = 'distributorNumber_asc';

export const adminCustomerGroupSlice = createSlice({
    name: 'admin_customer_group',
    initialState: {
        inProgress: true,
        data: {
            items: [] as AdminCustomerGroupRecord[],
            totalCount: 0
        } as AdminCustomerGroupResults, 
        pageSize: 10,
        pageIndex: 1,
        sortOrder: initialSortOrder,

        search: '',
        hasNoCustomer: false,
        hasNoManager: false,
        hasNoCustomerGroup: false,
        hasNoCustomerGroupDisabled: false,
		isInitialized: false,
    },
    reducers: {
        startLoading(state) { state.inProgress = true },
        endLoading(state) { state.inProgress = false },
        setPageIndex(state, action: PayloadAction<number>) { state.pageIndex = action.payload; },
        setSortOrder(state, action: PayloadAction<string>) {
            if (action.payload) {
                state.sortOrder = action.payload;
            }
            else {
                state.sortOrder = initialSortOrder;
            }
        },
        setData(state, action: PayloadAction<AdminCustomerGroupResults>) { state.data = action.payload },
        setSearch(state, action: PayloadAction<string>) { state.search = action.payload; state.pageIndex = 1; },
        setHasNoCustomer(state, action: PayloadAction<boolean>) { 
            state.hasNoCustomer = action.payload; state.pageIndex = 1; 
            setHasNoCustomerGroupDisabled(state);
        },
        setHasNoManager(state, action: PayloadAction<boolean>) {
            state.hasNoManager = action.payload; state.pageIndex = 1;
            setHasNoCustomerGroupDisabled(state);
        },
        setHasNoCustomerGroup(state, action: PayloadAction<boolean>) {state.hasNoCustomerGroup = action.payload; state.pageIndex = 1;},
		setInitialized(state){ state.isInitialized = true;},
        reInit(state){ state.isInitialized = false; state.pageIndex = 1;},
    }
});

export function fetchAdminCustomerGroup() {
    return function (dispatch: Dispatch<any>, getState: () => ApplicationState) {

        dispatch(adminCustomerGroupSlice.actions.startLoading());
        const state = getState().adminCustomerGroups;

        return apiCallerGlobal().getJson('/admin/customergroup', {
            page: state.pageIndex,
            pageSize: state.pageSize,
            sortOrder: state.sortOrder,
            search: state.search,
            hasNoManager: state.hasNoManager,
            hasNoCustomer: state.hasNoCustomer,
            hasNoCustomerGroup: state.hasNoCustomerGroup
        })
            .then(response => response.json())
            .then((results: AdminCustomerGroupResults) => {
                dispatch(adminCustomerGroupSlice.actions.setData(results));
                dispatch(adminCustomerGroupSlice.actions.setInitialized());
            })
            .catch(reason => notification.error({ message: 'Can\'t load Customer Groups' }))
            .finally(() => dispatch(adminCustomerGroupSlice.actions.endLoading()));
    }
}

function setHasNoCustomerGroupDisabled(state: {hasNoCustomer: boolean, hasNoManager: boolean, hasNoCustomerGroupDisabled: boolean, hasNoCustomerGroup: boolean }): void{

    state.hasNoCustomerGroupDisabled = state.hasNoCustomer || state.hasNoManager;
    if(state.hasNoCustomerGroupDisabled){
        state.hasNoCustomerGroup = false;
    }
}