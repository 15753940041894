import { Select } from 'antd';
import React from 'react';

const Option = Select.Option;

interface Props {
    value: number | null;
    onChange(value: number | null):void;
    valueSet: number[];
}

export default function (props: Props) {
    return (<Select value={props.value ?? undefined} onChange={props.onChange} disabled={props.valueSet.length === 0}>
        {props.valueSet.map(item => <Option key={item} value={item}>{item}</Option>)}
    </Select>);
}