import {  Form, Input, Modal, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useState } from 'react';
import { apiCaller } from '../../../../backend/apiCaller';
import { useSelectorShallow } from '../../../../store/selectorShallow';
import { useUfoErrorHandling } from '../../../utils/UfoErrors/ufoErrorHook';
import UfoErrorList from '../../../utils/UfoErrors/UfoErrorList';

interface Props {
    url: string;
    id: string;
    refresh(): void;
    show: boolean;
    close(): void;
    
    summaryInfo?: React.ReactNode;

    title?: string;
}

export default function (props: Props) {

    const [form] = useForm();

    const [inProgress, setInProgress] = useState(false);

    const errorHandler = useUfoErrorHandling();

    const currentCustomer = useSelectorShallow(s => s.customerChooserData.selectedNumber);

    async function submit(values: any) {
        try {
            setInProgress(true);

            await apiCaller(currentCustomer).postJson(props.url, {id: props.id, message: values.message});

            notification.success({message: 'Query has been sent successfully'});

            setInProgress(false);

            form.resetFields();
            props.close();
            props.refresh();

        } catch (error) {
            errorHandler.setError(error);
            setInProgress(false);

        }
    }

    return (<Modal visible={props.show} closable destroyOnClose onCancel={props.close}
        title={props.title ?? 'Raise a Query'} okText="Send Query" confirmLoading={inProgress} onOk={() => form.submit()}>
        <Form form={form} layout="vertical" onFinish={submit} onChange={errorHandler.reset}>
            
            {props.summaryInfo}
            <br />
            <FormItem label='Message' name="message" rules={[
                { required: true, message: 'Please enter a message' },
                { max: 500 }]}>
                <Input.TextArea autoSize={{ minRows: 4, maxRows: 9 }}  />
            </FormItem>
            <UfoErrorList apiError={errorHandler.apiError} />
        </Form>
    </Modal>);
}