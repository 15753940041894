import React from 'react';

interface Props {
    value?: number | null;
    negative?: boolean;
    positive?: boolean;
}

export default function (props: Props) {

    if(props.value === undefined || props.value === null){
        return <span></span>;
    }

    let isNegative = props.negative || (!props.positive && props.value < 0);

    const text = Math.abs(props.value).toLocaleString('en-GB');

    if (isNegative) {
        return (<span style={{color: '#ff3222'}}>({text})</span>);

    }

    return <span>{text}</span>;
}