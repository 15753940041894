import React from 'react';

interface Props {
    children?: React.ReactNode;
    marginBottom?: number;
}

export default function (props: Props) {
    return (<p style={{fontSize: '0.9rem', margin: `10px 6px ${props.marginBottom ?? 10}px 6px`, color: '#222'}}>
       {props.children} 
    </p>);
}