import React from 'react';
import styles from './UfoFilterLabel.module.css';

interface Props {
    children: React.ReactNode;
}

export default function (props: Props) {
    return (<>
        <span className={styles.label}>{props.children}</span>
    </>);
}