import { ApiCallerWithToken } from "./ApiCallerWithToken";

export class ApiCallerWithCustomer {
    caller: ApiCallerWithToken;

    customerNumber: string;

    constructor(baseUrl: string, customerNumber: string) {
        this.caller = new ApiCallerWithToken(baseUrl);

        this.customerNumber = customerNumber;
    }


    getJson(url: string, queryParameters?: {}): Promise<Response> {


        return this.caller.getJson(url, queryParameters, this.getHeader());
    }

    postJson(url: string, payload: any): Promise<Response> {

        return this.caller.postJson(url, payload, this.getHeader());
    }

    downloadFile(url: string, queryParameters?: {}): Promise<Response> {
        return this.caller.downloadFile(url, queryParameters, this.getHeader());
    }

    private getHeader(){
        return {globalSelectedCustomer: this.customerNumber};
    }

}