import { Col, Row } from 'antd';
import React from 'react';
import DateWithIsoWeek from '../Common/DateWithIsoWeek';
import PurchaseQuantity from './PurchaseQuantity';
import PurchaseType from './PurchaseType';

interface Props {
    date: string;
    orderNumber: string;
    typeCode: string;
    quantity: number;
    ticketNumber: string;
}

export default function (props: Props) {
    return (<Row>
        <Col span={12}>
            <strong>Order Number:</strong> {props.orderNumber} <br />
            <strong>Type:</strong> <PurchaseType typeCode={props.typeCode} /><br />
            <strong>Quantity:</strong> <PurchaseQuantity typeCode={props.typeCode} value={props.quantity} /><br />
        </Col>
        <Col span={12}>
            <strong>Date:</strong> <DateWithIsoWeek date={props.date} /><br />
            <strong>Ticket Number:</strong> {props.ticketNumber}
        </Col>
    </Row>);
}