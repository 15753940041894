import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import {  apiCallerGlobal } from '../../../backend/apiCaller';
import { UserRecord } from '../../../models/Users';
import { useModalState } from '../../utils/modalStateHook';
import UfoConfirmOperationModal from '../../utils/UfoConfirmationModal/UfoConfirmOperationModal';

interface Props {
    record: UserRecord;
    refresh(): void;
}

export default function (props: Props) {

    const modalState = useModalState();

    async function remove() {
        await apiCallerGlobal().postJson('/api/user/delete', {id: props.record.id});
    }

    return (<>
        <Tooltip title="Remove User">
            <Button type="primary" shape="circle" danger onClick={modalState.open}><DeleteOutlined /></Button>
        </Tooltip>
        {modalState.show && <UfoConfirmOperationModal 
            title="Confirm User Removal"
            show={modalState.show}
            onClose={modalState.close}
            onSuccess={props.refresh}
            operation={remove}
            okText="Yes, remove!">
            <p>Are you sure you want to remove user <strong>{props.record.email}</strong> ?</p>
        </UfoConfirmOperationModal>}
    </>);
}