import React, { useState } from 'react';

import { Form, Input, Button, Alert } from 'antd';
import Auth from '@aws-amplify/auth';
import { getCognitoErrorMessage } from '../cognitoErrors';

interface Props {
    navigateLogin: () => void;
    navigateReset: (userName: string) => void;
}

export default function (props: Props) {

    const [inProgress, setInProgress] = useState(false);

    const [error, setError] = useState('');


    const onFinish = async (values: any) => {
        
        setInProgress(true);

        try {
            
            await Auth.forgotPassword(values.email);

            setInProgress(false);

            props.navigateReset(values.email);

        } catch (error) {
            setInProgress(false);

            setError(getCognitoErrorMessage(error));
        }
    };


    return (<>
        <p>Please, enter your email address. We'll send you a code which you can use for reseting you password</p>

        <Form
            name="basic"
            labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
        >

            <Form.Item
                name="email"
            
                rules={[{ required: true, message: 'Please input your email!' }]}
            >
                <Input size="large" placeholder="Email" autoComplete="username" />
            </Form.Item>

            <Form.Item >
                <Button type="primary" htmlType="submit" block size="large" loading={inProgress}>
                    Reset
                </Button>
            </Form.Item>
            <div>
                <Button block type="link" onClick={props.navigateLogin}>Back to Login</Button>
            </div>

            {error !== '' && <Alert message={error} type="error" showIcon />}
        </Form>
    </>
    );
};