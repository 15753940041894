import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import { apiCaller } from "../../backend/apiCaller";
import { TransactionRecord, TransactionResults } from "../../models/Transactions";
import { ApplicationState } from "../rootReducer";


const initialSortOrder = 'dateTime_desc';



export const transactionSlice = createSlice({
	name: 'transactions',
	initialState: {
		inProgress: true,
		data: {
			page: {
				totalCount: 0,
				items: [] as TransactionRecord[],
			},
			hasSalesValue: false
		} as TransactionResults,
		sortOrder: initialSortOrder,
		pageSize: 10,
		pageIndex: 1,

		search: '',
		beforeDate: undefined as string | undefined | null,
		afterDate: undefined as string | undefined | null,
		product: '',

		isInitialized: false,
	},
	reducers: {
		startLoading(state) { state.inProgress = true },
		endLoading(state) { state.inProgress = false },
		setSearch(state, action: PayloadAction<string>) { state.search = action.payload; state.pageIndex = 1; },
		setProduct(state, action: PayloadAction<string>) { state.product = action.payload; state.pageIndex = 1; },
		setDateRange(state, action: PayloadAction<[string | null, string | null]>) { state.afterDate = action.payload[0]; state.beforeDate = action.payload[1]; state.pageIndex = 1; },

		setSortOrder(state, action: PayloadAction<string>) {
			if (action.payload) {
				state.sortOrder = action.payload;
			}
			else {
				state.sortOrder = initialSortOrder;
			}
		},
		setPageIndex(state, action: PayloadAction<number>) { state.pageIndex = action.payload },
		setData(state, action: PayloadAction<TransactionResults>) { state.data = action.payload },
		setInitialized(state) { state.isInitialized = true; },
		reInit(state) { state.isInitialized = false; state.pageIndex = 1; },
	}
});



export function fetchTransactionsThunk() {
	return function (dispatch: Dispatch<any>, getState: () => ApplicationState) {

		dispatch(transactionSlice.actions.startLoading());
		const state = getState().transactions;

		return apiCaller(getState).getJson('/transaction', {
			page: state.pageIndex,
			pageSize: state.pageSize,
			sortOrder: state.sortOrder,
			product: state.product,
			search: state.search,
			fromDate: state.afterDate,
			untilDate: state.beforeDate
		})
			.then(response => response.json())
			.then((results: TransactionResults) => {
				dispatch(transactionSlice.actions.setData(results));
				dispatch(transactionSlice.actions.setInitialized());
			})
			.catch(reason => notification.error({ message: 'Can\'t load Transactions' }))
			.finally(() => dispatch(transactionSlice.actions.endLoading()));
	}
}