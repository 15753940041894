export interface DocumentRecord{
    id: string;
    sizeInBytes: number;
    name: string;
    extension: string;
    type: DocumentTypeEnum;
    year: number;
    monthOrWeek: number;
} 

export enum DocumentTypeEnum{

    WeeklyInvoice = 1,

    MonthlyInvoice = 2,

    WeeklyStatement = 3,

}

export type DocumentIndex = Record<number, Record<number, Record<number, string>>>;

export interface MonthWeekInfo{
	number: number;
	name: string;
}

interface DocumentTypeInfo{
    name: string;
}

export const documentTypeInfos: Record<DocumentTypeEnum, DocumentTypeInfo> = {
    "1": {
        name: 'Weekly Invoice',
    },
    "2": {
        name: 'Monthly Invoice',
    },
    "3": {
        name: 'Weekly Statement'
    }
}