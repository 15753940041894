import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { customerChooserSlice } from "../../store/slices/CustomerChooserSlice";

export function useDisableCustomerChooserEffect() {

    const disaptch = useDispatch();

    useEffect(() => {
        disaptch(customerChooserSlice.actions.setDisabled(true));

        return () => { disaptch(customerChooserSlice.actions.setDisabled(false)); }
        // eslint-disable-next-line
    }, []);
}