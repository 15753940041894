import { Input } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import FormItem from 'antd/lib/form/FormItem';
import React from 'react';

interface Props {
    name?: string;
    disabled?: boolean;
    size?: SizeType;
}

export default function (props: Props) {
    return (<FormItem name={props.name ?? 'phone'} label="Phone"
        rules={[{ pattern: /^\+\d+$/g, message: 'Must begin with + sign and must contain the country code (without any special characters). For example: +4412345678' },
        {min: 8}, {max: 22}]}>
        <Input placeholder="Phone" autoComplete="off" disabled={props.disabled} size={props.size} />
    </FormItem>);
}