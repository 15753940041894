import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useModalState } from '../../../utils/modalStateHook';
import AdminCustomerGroupCreateForm from './AdminCustomerGroupCreateForm';

interface Props {
    onSuccess(): void;
}

export default function (props: Props) {
    const modalState = useModalState();


    return (<>
        <Button type="primary" icon={<PlusOutlined />} onClick={modalState.open} >Create Customer Group</Button>
        {modalState.show && <AdminCustomerGroupCreateForm show={modalState.show} onClose={modalState.close} onSuccess={props.onSuccess} />}
    </>);
}