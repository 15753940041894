import { Space, Tooltip } from 'antd';
import React from 'react';
import { AdminCustomerGroupCustomer } from '../../../models/AdminCustomerGroups';
import UfoInnerTable, { UfoInnerTableRow } from '../../utils/UfoTable/UfoInnerTable';
import styles from './AdminCustomerGroupCustomerCell.module.css';
import AdminCustomerGroupCustomerDeleteButton from './AdminCustomerGroupCustomerDeleteButton';
import AdminCustomerGroupCustomerEditButton from './CustomerEdit/AdminCustomerGroupCustomerEditButton';

interface Props {
    customers: AdminCustomerGroupCustomer[];
    customerGroupId: string;
    customerGroupName: string;
    distributorNumber: string;
    refresh(): void;
}

export default function (props: Props) {
    return (<UfoInnerTable>
        {props.customers.map(item => <UfoInnerTableRow key={item.id}>
            <td className={styles.number}>
                <Tooltip color="#444" title={<>
                    <strong>BasicUser Count: </strong><span>{item.userCount}</span><br />
                    {item.email && <><strong>Email: </strong><span>{item.email}</span><br />
                    {item.hiddenTransactionCustomer && <><br />No Customer column at Transactions.</>}
                    </>}
                </>}>{item.number}</Tooltip>
            </td>
            <td className={styles.name}>{item.name}</td>
            <td className='action'>
                <Space>
                    <AdminCustomerGroupCustomerEditButton record={item} onSuccess={props.refresh} />
                    {props.customerGroupId && <AdminCustomerGroupCustomerDeleteButton item={item} customerGroupId={props.customerGroupId} customerGroupName={props.customerGroupName}
                        onSuccess={props.refresh} distributorNumber={props.distributorNumber} />}
                </Space>
            </td>
        </UfoInnerTableRow>)}
    </UfoInnerTable>);
}
