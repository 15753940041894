import { LockOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import Auth from '@aws-amplify/auth';
import { Avatar, Dropdown, Menu } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useBlaboUser from '../../../../auth/useAuthenticatedUser';
import { useModalState } from '../../../utils/modalStateHook';
import AdminGroupChooserModal from '../AdminGroupChooser/AdminGroupChooserModal';
import styles from './ProfileMenu.module.css';

export default function () {

    const adminGroupChangeModalState = useModalState();

    const user = useBlaboUser();

    const navigator = useNavigate();

    async function signOut() {

        await Auth.signOut();

        navigator('/');

    }

    const menu = <Menu>
        <Menu.Item key="1" className={styles.userInfo}>
            <strong>{user.user.fullName}</strong>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2" icon={<UserOutlined />}>
            <Link to="/account/basics">Account Settings</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<LockOutlined />}>
            <Link to="/account/change-password">Change Password</Link>
        </Menu.Item>
        {user.user.isAdmin && <Menu.Divider />}
        {user.user.isAdmin && <Menu.Item key="adminGroupChange" icon={<SettingOutlined />} onClick={adminGroupChangeModalState.open}>
            Admin Group Change
        </Menu.Item>}
        <Menu.Divider />
        <Menu.Item key="4" icon={<LogoutOutlined />} onClick={signOut}>
            <span>Logout</span>
        </Menu.Item>
    </Menu>;



    return (<div className={styles.menu}>
        <Dropdown overlay={menu} trigger={["click"]} placement="bottomLeft" >
            <Avatar size={40} className={styles.avatar}>{user.user.monogram}</Avatar>
        </Dropdown>
        {user.user.isAdmin && <AdminGroupChooserModal show={adminGroupChangeModalState.show} close={adminGroupChangeModalState.close} />}
    </div>);
}