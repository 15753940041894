import React from 'react';
import styles from './UfoFormInfo.module.css';

interface Props {
    children?: React.ReactNode;
    marginBottom?: boolean
}

export default function (props: Props) {

    const classes = [styles.info];

    if(props.marginBottom){
        classes.push(styles.marginBottom);
    }

    return (<div className={classes.join(' ')}>
        {props.children}
    </div>);
}