import { Col, Form, Input, Modal, notification, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useState } from 'react';
import { apiCaller} from '../../../../backend/apiCaller';
import { useUfoErrorHandling } from '../../../utils/UfoErrors/ufoErrorHook';
import UfoErrorList from '../../../utils/UfoErrors/UfoErrorList';

interface Props {
    email: string;
    show: boolean;
    onClose(): void;
    onSuccess(email: string): void;
    selectedCustomerNumber: string;
}

export default function (props: Props) {

    const [inProgress, setInProgress] = useState(false);

    const [form] = useForm();

    const errorHandler = useUfoErrorHandling();

    async function submit(values: any) {

        setInProgress(true);

        try {
            await apiCaller(props.selectedCustomerNumber).postJson('/api/customer/editemail', {
                email: values.email,
            });
            
            notification.success({message: `Email has been updated successfully`});

            setInProgress(false);
            form.resetFields();
            props.onClose();
            props.onSuccess(values.email);

        } catch (error) {
            setInProgress(false);
            
            errorHandler.setError(error);
        }

    }

    return (<Modal visible={props.show} closable destroyOnClose onCancel={props.onClose} width={600}
        title="Edit Notification Email" okText="Save" okButtonProps={{ disabled: inProgress }} confirmLoading={inProgress} onOk={form.submit}>
        <Form form={form} onFinish={submit} layout="vertical" autoComplete="off"
            initialValues={{
                email: props.email
            }}
            onChange={errorHandler.reset}
        >
            {/* <div style={{ marginBottom: '30px' }}>
                
            </div> */}
            {/* <UfoFormInfo></UfoFormInfo> */}
            <p>You can unsubscribe from the email notification by leaving the field empty and saving the modification.</p>
            <Row gutter={[7, 7]}>
                <Col xs={23}>
                    <FormItem name="email" label="Email" style={{ width: '100%' }}
                        rules={[{ type: 'email'}, { max: 255 }]}>
                        <Input placeholder="Email" autoComplete="off" />
                    </FormItem>
                </Col>
            </Row>
            <UfoErrorList apiError={errorHandler.apiError} />
        </Form>
    </Modal>);
}