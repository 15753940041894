import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        collapsed: true,
    },
    reducers: {
        toogleCollapsed(state, action: PayloadAction) { state.collapsed = !state.collapsed}
    }
});

export function menuToggleCollapseThunk(){
    return function(dispatch: Dispatch<any>){
        dispatch(menuSlice.actions.toogleCollapsed());
    }
}