import { Space } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import React from 'react';
import { CustomerChooserData } from '../../../models/Customers';
import { UserRecord } from '../../../models/Users';
import { comparators, gerRecordComparator } from '../../utils/Comparators';
import UfoTable from '../../utils/UfoTable/UfoTable';
import UserDeleteButton from './UserDeleteButton';
import UserEditButton from './UserEditButton';
import UserRelatedCustomers from './UserRelatedCustomers';



interface Props {
    items: UserRecord[],
    inProgress: boolean;
    refresh(): void;
    isManagerOrAbove: boolean;
    customers: CustomerChooserData[];
}

export default function (props: Props) {



    const columns: ColumnType<UserRecord>[] = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: gerRecordComparator(s => s.firstName, comparators.stringCompare),
        }, {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: gerRecordComparator(s => s.lastName, comparators.stringCompare),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: gerRecordComparator(s => s.email, comparators.stringCompare),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            sorter: gerRecordComparator(s => s.phone, comparators.stringCompare),
        },
        // {
        //     title: 'Last Activity',
        //     key: 'lastActivity',
        //     sorter: gerRecordComparator(s => s.lastActivityDate, comparators.stringCompare),
        //     render: (_, record) => <DateWithIsoWeek date={record.lastActivityDate} displayTime />
        // },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => <Space>
                {props.isManagerOrAbove && !record.isManager && <>
                    <UserDeleteButton record={record} refresh={props.refresh} />
                    <UserEditButton record={record} refresh={props.refresh} customers={props.customers} />
                </>}
            </Space>

        }];

    if (props.isManagerOrAbove) {

        columns.splice(4, 0, {
            title: 'Customers',
            key: 'allCustomerNumbers',
            // sorter: gerRecordComparator(s => s.customerName, comparators.stringCompare),
            render: (_, record) => <UserRelatedCustomers record={record} allCustomerDetails={props.customers} />

        });
    }


    return (<UfoTable
        columns={columns}
        dataSource={props.items}
        bordered={true}
        rowKey="id"
        pagination={false}
        loading={{ delay: 300, size: 'large', spinning: props.inProgress }}
        size="small"
    />);
}
