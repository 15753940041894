import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { ReactNode } from 'react';
import { apiCallerGlobal } from '../../../backend/apiCaller';
import { AdminCustomerGroupCustomer } from '../../../models/AdminCustomerGroups';
import { useModalState } from '../../utils/modalStateHook';
import UfoConfirmOperationModal from '../../utils/UfoConfirmationModal/UfoConfirmOperationModal';
import { useUfoErrorHandling } from '../../utils/UfoErrors/ufoErrorHook';

interface Props {
    item: AdminCustomerGroupCustomer;
    onSuccess(): void;
    customerGroupId: string;
    customerGroupName: string;
    distributorNumber: string;
}

export default function (props: Props) {

    const modalState = useModalState();

    const errorHandler = useUfoErrorHandling();

    async function remove() {
        await apiCallerGlobal().postJson('/admin/customergroup/removeCustomer', { id: props.item.id, customerGroupId: props.customerGroupId });
    }

    const item = props.item;

    return (<>
        <Tooltip title="Remove Customer From Group">
            <Button type="primary" shape="circle" danger size="small"  onClick={modalState.open}><DeleteOutlined /></Button>
        </Tooltip>
        {modalState.show && <UfoConfirmOperationModal show={modalState.show} onClose={modalState.close}
            title="Remove Customer Assignment"
            operation={remove}
            onSuccess={props.onSuccess}
            okText="Yes, remove!"
            ufoErrorHandler={errorHandler}>
            <p>Are you sure you want to remove Customer <Name><strong>{item.number}</strong> - {item.name}</Name> from Group <Name>{props.customerGroupName} (Dist. No.: {props.distributorNumber})</Name> ?</p>
            {item.userCount > 0 && <p><strong>Note</strong>: It has {item.userCount} BasicUser(s), their permissions will be removed from this Customer.</p>}
        </UfoConfirmOperationModal>}
    </>);
}

function Name(props: { children?: ReactNode }): React.ReactElement{
    return (<span style={{fontWeight: 'bold'}}>
        {props.children}
    </span>);
}