import React, { useState } from 'react';

import { Form, Input, Button, Alert } from 'antd';
import Auth from '@aws-amplify/auth';
import { getCognitoErrorMessage } from '../cognitoErrors';

interface Props {
    navigateForgotPassword: () => void;
    setUser: (user: any) => void;
}

export default function (props: Props) {

    const [inProgress, setInProgress] = useState(false);

    const [error, setError] = useState('');


    const onFinish = async (values: any) => {

        setInProgress(true);

        setError('');

        try {
            const user = await Auth.signIn(values.username, values.password);

            setInProgress(false);

            props.setUser(user);

        } catch (error) {

            setInProgress(false);

            setError(getCognitoErrorMessage(error));
        }
    };


    return (<>
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            onFieldsChange={() => setError('')}
        >
            <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
                required={false}
            >
                <Input size="large" disabled={inProgress} />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
                required={false}
            >
                <Input.Password size="large" autoComplete="username" disabled={inProgress} />
            </Form.Item>

            <Form.Item >
                <Button type="primary" htmlType="submit" block size="large" loading={inProgress}>
                    Sign In
                </Button>
            </Form.Item>
            <div>
                <Button block type="link" onClick={props.navigateForgotPassword}>Forgot Your Password?</Button>
            </div>
            {error !== '' && <Alert message={error} type="error" showIcon />}
        </Form>
    </>
    );
};