import { Modal, notification, Select } from 'antd';
import React, {  useState } from 'react';
import { useAppDispatch as useDispatch } from '../../../../store/hooks';
import { apiCallerGlobal } from '../../../../backend/apiCaller';
import { useSelectorShallow } from '../../../../store/selectorShallow';
import { adminCustomerGroupChooserSlice, fetchAdminCustomerGroupChooserThunk } from '../../../../store/slices/AdminCustomerGroupChooserSlice';
import UfoFormInfo from '../../../utils/UfoFormSection/UfoFormInfo';
import { useInvalidatableInitEffect } from '../../../utils/usePageDataLoad';

interface Props {
    show: boolean;
    close(): void;
}

export default function (props: Props) {

    const [selected, setSelected] = useState('');

    const [inProgress, setInProgress] = useState(false);

    const reduxState = useSelectorShallow(s => s.adminCustomerGroupChooserData);

    const dispatch = useDispatch();

    async function submit() {
        try {
            setInProgress(true);
            await apiCallerGlobal().postJson('/admin/customergroupchooser/change', { id: selected });

            dispatch(adminCustomerGroupChooserSlice.actions.setSelected(selected));

            notification.success({ message: 'Customer Group change was successful. Reloading page...' });

            setInProgress(false);

            props.close();

            setTimeout(() => window.location.reload(), 1500);

        } catch (error) {
            notification.error({ message: 'Failed to change the Domain. Error: ' + JSON.stringify(error) });
            setInProgress(false);

        }

    }


    useInvalidatableInitEffect(() => {
        dispatch(fetchAdminCustomerGroupChooserThunk());
    }, reduxState);


    return (<>
        <Modal visible={props.show} closable destroyOnClose onCancel={props.close} title="Customer Group Change" confirmLoading={inProgress} onOk={() => submit()}  >
            {reduxState.inProgress && "Loading..."}
            {!reduxState.inProgress && reduxState.data && <div>
                <UfoFormInfo marginBottom>
                    You can change your Customer Group here because you are an admin user. After a successful change the page will be reloaded.
                </UfoFormInfo>
                <p>Customer Group:</p>
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    defaultValue={reduxState.selected}
                    filterOption={(input, option) => {

                        return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    onChange={(value) => {
                        setSelected(value);
                    }}
                    placeholder="No Domain selected. Please type to search..."
                    options={reduxState.data.map(item => ({ label: `${item.distributorNumber} - ${item.name}`, value: item.id }))}>
                </Select>
            </div>}
        </Modal>
    </>);
}