import { Select } from 'antd';
import React from 'react';

interface Props {
    value: string;
    onChange(value: string): void;
}

export default function (props: Props) {
    return (<Select value={props.value} onChange={props.onChange} style={{width: '100%'}}>
        <Select.Option key="empty" value="">All Roles</Select.Option>
        <Select.Option key="admin" value="admin">Administrators</Select.Option>
        <Select.Option key="manager" value="manager">Managers</Select.Option>
        <Select.Option key="basicUser" value="basicUser">Basic Users</Select.Option>
    </Select>);
}