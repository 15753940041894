import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { apiCallerGlobal } from '../../../backend/apiCaller';
import { useModalState } from '../../utils/modalStateHook';
import UfoConfirmOperationModal from '../../utils/UfoConfirmationModal/UfoConfirmOperationModal';
import { useUfoErrorHandling } from '../../utils/UfoErrors/ufoErrorHook';

interface Props {
    id: string;
    email: string;
    refresh(): void;
}

export default function (props: Props) {
    
    const modalState = useModalState();

    const errorHandler = useUfoErrorHandling();

    async function remove() {
        await apiCallerGlobal().postJson('/admin/user/delete', {id: props.id});
    }
    
    return (<>
        <Tooltip title="Remove User">
            <Button type="primary" shape="circle" danger onClick={modalState.open}><DeleteOutlined /></Button>
        </Tooltip>
        {modalState.show && <UfoConfirmOperationModal show={modalState.show} onClose={modalState.close} 
            title="Remove User"
            operation={remove}
            onSuccess={props.refresh}
            okText="Yes, delete!"
            ufoErrorHandler={errorHandler}>
            <p>Are you sure you want to remove user <strong>{props.email}</strong> ?</p>
            <p>The operation is irreversible.</p>
            </UfoConfirmOperationModal>}
    </>);
}