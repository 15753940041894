import moment from "moment";
import { DocumentIndex, DocumentRecord, DocumentTypeEnum, MonthWeekInfo } from "../../models/Documents";

class DocumentSliceLogic {
    getFullIndex(data: DocumentRecord[]) {

        let fullIndex: DocumentIndex = {};

        for (let index = 0; index < data.length; index++) {
            const element = data[index];


            if (!fullIndex.hasOwnProperty(element.type)) {
                fullIndex[element.type] = {};
            }

            let byType = fullIndex[element.type];

            if (!byType.hasOwnProperty(element.year)) {
                byType[element.year] = {};
            }

            let byTypeYear = byType[element.year];

            if(element.type === DocumentTypeEnum.WeeklyStatement || element.type === DocumentTypeEnum.WeeklyInvoice){
                byTypeYear[element.monthOrWeek] = `Week ${element.monthOrWeek}`;
            } else{
                const monthName = `${moment.localeData().months()[element.monthOrWeek - 1]}`;

                byTypeYear[element.monthOrWeek] = monthName;
            }
        }

        return fullIndex;
    }


    filter(data: DocumentRecord[], year: null | number, monthOrWeek: null | number, type: null | DocumentTypeEnum)
        : DocumentRecord[] {

        // I tried do some optimization by constructing the predicates outside the loop
        // I'm not sure it really faster :)

        const predicatesToApply = [] as ((item: DocumentRecord) => boolean)[];

        if (year) {
            predicatesToApply.push(s => s.year === year);
        }

        if (type !== null) {
            predicatesToApply.push(s => s.type === type);
        }

        if (monthOrWeek) {
            predicatesToApply.push(s => s.monthOrWeek === monthOrWeek);
        }

        return data.filter(item => {
            for (let index = 0; index < predicatesToApply.length; index++) {
                const predicate = predicatesToApply[index];

                if (!predicate(item)) {
                    return false;
                }
            }

            return true;
        }).sort((a, b) => (a.year * 100 + a.monthOrWeek) - (b.year * 100 + b.monthOrWeek) );
    }

    getMonthOrWeeks(dictionary: Record<number, string>): MonthWeekInfo[]{

        const result: MonthWeekInfo[] = [];

        for (const key in dictionary) {
            result.push({
                number: Number(key),
                name: dictionary[key]
            })
        }

        result.sort((a, b) => - b.number + a.number);

        return result;
    }
}

export const documentSliceLogic = new DocumentSliceLogic();