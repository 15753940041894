import { Checkbox, Col,  Row } from 'antd';
import React from 'react';
import ClearableTextFilter from '../../utils/ClearableTextFilter';
import UfoCsvDownloadButton from '../../utils/UfoCsvDownloadButton';
import UfoDateRangePicker from '../../utils/UfoDateRangePicker';
import UfoFilterLabel from '../../utils/UfoFilterLabel/UfoFilterLabel';
import CardProductFilter from './CardProductFilter';


interface Props {
    search: string | null;
    onSearchChange(value: string): void;

    stopFrom: string | null;
    stopUntil: string | null;
    onStopDateChange(from: string | null, until: string | null): void;
    active: boolean;
    onActiveChange(value: boolean): void;

    issueFrom: string | null;
    issueUntil: string | null;
    onIssueDateChange(from: string | null, until: string | null): void;

    expiryFrom: string | null;
    expiryUntil: string | null;
    onExpiryDateChange(from: string | null, until: string | null): void;

    products: string;
    onProductsChange(values: string): void;

}

export default function (props: Props) {
    return (<>
        <Row gutter={[20, 20]} align="bottom">
            <Col sm={11} md={7} lg={6}>
                <ClearableTextFilter value={props.search} onChange={props.onSearchChange} placeholder="Search..." /></Col>

            <Col md={7} sm={12} lg={6}>

                <CardProductFilter values={props.products} onChange={props.onProductsChange} />
            </Col>

            <Col>
                <UfoCsvDownloadButton url="/cardDownload" parameters={{ search: props.search, stopFrom: props.stopFrom, stopUntil: props.stopUntil, active: props.active, issueFrom: props.issueFrom, issueUntil: props.issueUntil, expiryFrom: props.expiryFrom, expiryUntil: props.expiryUntil, products: props.products }} />
            </Col>
        </Row>
        <Row gutter={[20, 0]} align="bottom">
            <Col sm={9} md={8} lg={6}>
                <UfoFilterLabel>Issue Date:</UfoFilterLabel>
                <UfoDateRangePicker from={props.issueFrom} until={props.issueUntil} onChange={props.onIssueDateChange}
                    placeholder={['Issued after', 'Issued before']}
                />

            </Col>
            <Col sm={9} md={8} lg={6}>
                <UfoFilterLabel>Expiry Date:</UfoFilterLabel>
                <UfoDateRangePicker from={props.expiryFrom} until={props.expiryUntil} onChange={props.onExpiryDateChange}
                    placeholder={['Expiry after', 'Expiry before']} />
            </Col>
            <Col sm={9} md={8} lg={6}>
                <UfoFilterLabel>Stop Date:</UfoFilterLabel>
                <UfoDateRangePicker from={props.stopFrom} until={props.stopUntil} onChange={props.onStopDateChange} disabled={props.active}
                    placeholder={['Stopped after', 'Stopped before']} />
            </Col>
            <Col>
                <Checkbox checked={props.active} onChange={e => props.onActiveChange(e.target.checked)}>Active only</Checkbox>
            </Col>
        </Row>
    </>);
}