import { Col, Row, Select } from 'antd';
import React from 'react';
import { purchaseTypes } from '../../../models/PurchaseTypes';
import ClearableTextFilter from '../../utils/ClearableTextFilter';
import UfoCsvDownloadButton from '../../utils/UfoCsvDownloadButton';
import UfoDateRangePicker from '../../utils/UfoDateRangePicker';

const Option = Select.Option;

interface Props {
    search: string | null;
    onSearchChange(value: string): void;
    untilDate: string | null;
    onDateRangeChanged(from: string | null, until: string | null): void;
    fromDate: string | null;
    type: string;
    onTypeChanged(value: string): void;

    page: number;
    pageSize: number;
    sortOrder: string;
}

export default function (props: Props) {
    return (<Row gutter={[20, 20]} align="bottom">
        
        <Col md={6} sm={8} lg={5}>

            <Select style={{ width: '100%' }} value={props.type.toString()} onChange={props.onTypeChanged}>
                <Option value="" style={{ fontStyle: 'italic', color: '#777' }}>All Types</Option>
                {purchaseTypes.map(item => <Option value={item.shortName.toString()}>{item.name} ({item.shortName})</Option>)}
            </Select>

        </Col>
        <Col sm={11} md={7} lg={6}>
            <ClearableTextFilter value={props.search} onChange={props.onSearchChange} title="Order No., Oil Company, Site, Ticket No." placeholder="Order No., Oil Co., Site, Ticket No." /></Col>

        <Col sm={9} md={8} lg={6}>
            <UfoDateRangePicker from={props.fromDate} until={props.untilDate} onChange={props.onDateRangeChanged} />
        </Col>
        

        <Col>
            <UfoCsvDownloadButton url="/api/purchase/download" parameters={{ from: props.fromDate, until: props.untilDate, type: props.type, search: props.search, page: props.page, pageSize: props.pageSize, sortOrder: props.sortOrder }} />
        </Col>
    </Row>);
}