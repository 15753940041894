import React from 'react';
import styles from './CardProductCell.module.css';
import { cardProducts } from './CardProducts';

interface Props {
    products: number[];
}

export default function (props: Props) {
    return (<div className={styles.wrapper}>
        {props.products.map(item => {

            const product = cardProducts.find(s => s.id === item);
            if(product){
                return (<span key={item} className={styles.type} title={product.name}>{product.shortName}</span>);
            }

            return (<></>);
        })}
    </div>);
}