import { Select } from 'antd';
import React from 'react';
import { cardProducts } from './CardProducts';

const Option = Select.Option;

interface Props {
    values: string;
    onChange(values: string): void;
}


export default function (props: Props) {

    return (<>
        <Select onChange={props.onChange} value={props.values} style={{width: '100%'}}  >
            <Option value="" style={{fontStyle: 'italic', color: '#777'}}>All Products</Option>
            {cardProducts.map(item => <Option key={item.id} value={item.id} >{item.name}</Option>)}
        </Select>
    </>);
}

