import { CloseCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { ApiErrorDetails } from '../../../backend/ApiErrors';
import styles from './UfoErrorList.module.css';

interface Props {
    apiError: ApiErrorDetails;
    serverErrorMessage?: string;
    networkErrorMessage?: string;

    withMarginTop?: boolean;

    smallHeader?: boolean;
}

export default function (props: Props) {

    if(!props.apiError.isNetworkError && !props.apiError.isServerError
            && (!props.apiError.messages || props.apiError.messages.length === 0)){
        return (<></>);
    }

    const errorMessagesToDisplay = [] as string[];

    if(props.apiError.isServerError){
        errorMessagesToDisplay.push(props.serverErrorMessage ?? 'There was an error on our side. We are working on the solution.');
    }

    if(props.apiError.isNetworkError){
        errorMessagesToDisplay.push(props.networkErrorMessage ?? 'Cannot connect to the server. Is your internet connection OK?');
    }

    errorMessagesToDisplay.push(...props.apiError.messages);


    const boxStyles = [styles.box];
    if(props.withMarginTop){
        boxStyles.push(styles.marginTop);
    }

    if(props.smallHeader){
        boxStyles.push(styles.smallHeader);
    }

    const isValidationError = !props.apiError.isServerError && !props.apiError.isNetworkError;

    return (<div className={boxStyles.join(' ')}>
        
        <div className={styles.header}><CloseCircleOutlined />
            {isValidationError && <span> Validation errors:</span>}
            {!isValidationError && <span> Something went wrong:</span>}
            </div>
        <ul>
        {errorMessagesToDisplay.map((item, index) => <li key={index}>
            {item}
        </li>)}
        </ul>
    </div>)
}