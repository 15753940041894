import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import {  StockWeeklyRecord } from '../../../models/Stocks2';
import UfoTable from '../../utils/UfoTable/UfoTable';
import NumberNegativable from '../Common/NumberNegativable';
import QueryButtom from '../Common/QueryModalForm/QueryButtom';
import StockWeeklyConfirmationButton from './Weekly/StockWeeklyConfirmationButton';
import StockWeeklySummary from './Weekly/StockWeeklySummary';


interface Props {
    item: StockWeeklyRecord;
    inProgress: boolean;
    refresh(): void;
    isoWeek: string;
}

export default function (props: Props) {


    const columns: ColumnsType<StockWeeklyRecord> = [
        {
            title: 'Opening Stock',
            key: 'opening',
            dataIndex: 'opening',
            render: (value: number) => <NumberNegativable value={value} />
        }, {
            title: 'Purchased',
            key: 'purchased',
            dataIndex: 'purchased',
            render: (value: number) => <NumberNegativable value={value} />
        }, {
            title: 'Stock Credit',
            key: 'credit',
            dataIndex: 'credit',
            render: (value: number) => <NumberNegativable value={value} />
        }, {
            title: 'Drawings',
            key: 'diesel', 
            dataIndex: 'diesel',
            render: (value: number) => <NumberNegativable value={value} />
        }, {
            title: 'Stock Debit',
            key: 'debit',
            dataIndex: 'debit',
            render: (value: number) => <NumberNegativable value={value} negative />
        }, {
            title: 'Closing Stock',
            key: 'closing',
            dataIndex: 'closing',
            render: (value: number) => <NumberNegativable value={value} />
        },{
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record: StockWeeklyRecord) => {
                return (<>
                    <QueryButtom id={record.id} refresh={props.refresh} url="/api/stock/query" canRaiseQuery hasQuery={record.hasQuery} summaryInfo={<StockWeeklySummary record={record}  isoWeek={props.isoWeek} />} />
                    <StockWeeklyConfirmationButton record={record} refresh={props.refresh} isoWeek={props.isoWeek} />
                </>);
            }
        }];



    return (
    <UfoTable
        columns={columns}
        dataSource={[props.item]}
        bordered={true}
        rowKey="id"
        size="small"
        pagination={false}
        scroll={{x: 'max-content'}}
    />);
}