import React from 'react';

interface Props {
    name?: string | null;
    number?: string | null;
    numberTitle?: string;
}

export default function (props: Props) {

    if (!props.name) {
        return (<></>);
    }

    if (!props.numberTitle) {
        return (<span title={props.number ?? ""}>
            {props.name}
        </span>);
    }

    return (<span title={`${props.numberTitle}: ${props.number}`}>
        {props.name}
    </span>);
}