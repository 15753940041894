import { Space } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import React from 'react';
import { AdminCustomerGroupRecord, AdminCustomerGroupResults } from '../../../models/AdminCustomerGroups';
import UfoTable from '../../utils/UfoTable/UfoTable';
import DateWithIsoWeek from '../Common/DateWithIsoWeek';
import { OnTableChangeFunc } from '../Common/tableChange';
import AdminCustomerGroupCustomerCell from './AdminCustomerGroupCustomerCell';
import AdminCustomerGroupDeleteButton from './AdminCustomerGroupDeleteButton';
import AdminCustomerGroupManagerCell from './AdminCustomerGroupManagerCell';
import AdminCustomerGroupEditButton from './Edit/AdminCustomerGroupEditButton';



interface Props {
    results: AdminCustomerGroupResults,
    pageSize: number,
    pageIndex: number,
    inProgress: boolean;
    onTableChange: OnTableChangeFunc;
    onSuccess(): void;
}

export default function (props: Props) {



    const columns: ColumnType<AdminCustomerGroupRecord>[] = [
        {
            title: 'Dist. No.',
            dataIndex: 'distributorNumber',
            key: 'distributorNumber',
            sorter: true
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: 'Date Created',
            key: 'dateCreated',
            sorter: true,
            render: (_, record) => <DateWithIsoWeek date={record.dateCreated} displayTime />
        },
        {
            title: 'Customers',
            key: 'customers',
            render: (_, record) => <AdminCustomerGroupCustomerCell customers={record.customers} customerGroupId={record.id} customerGroupName={record.name} refresh={props.onSuccess} distributorNumber={record.distributorNumber} />
        },
        {
            title: 'Managers',
            key: 'managers',
            render: (_, record) => <AdminCustomerGroupManagerCell managers={record.managers} />
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => <div>
                {record.id && <Space>
                    <AdminCustomerGroupEditButton record={record} onSuccess={props.onSuccess} />
                    <AdminCustomerGroupDeleteButton record={record} onSuccess={props.onSuccess} />
                </Space>}
            </div >

        }];


    return (<UfoTable
        columns={columns}
        dataSource={props.results.items}
        bordered={true}
        rowKey={(record, index) => {
            if (record.id) {
                return record.id;
            }

            if (record.customers.length > 0) {
                return record.customers[0].id;
            }

            return index ?? 1;
        }}
        pagination={{
            total: props.results.totalCount,
            current: props.pageIndex,
            pageSize: props.pageSize,
            showSizeChanger: false,
            showTotal: (total: number, range: any) => {
                if (total === 0) {
                    return '0 items';
                }
                if (total === 1) {
                    return '1 item';
                }
                if (range[0] === range[1]) {
                    return `Item ${range[0]} of ${total} items`;
                }
                return `Items ${range[0]}-${range[1]} of ${total} items`;
            }
        }}
        onChange={props.onTableChange}
        loading={{ delay: 300, size: 'large', spinning: props.inProgress }}
        size="small"
        verticalScroll
        darkBorder
    />);
}
