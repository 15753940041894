import { Checkbox, Col, Row, Tooltip } from 'antd';
import React from 'react';
import ClearableTextFilter from '../../utils/ClearableTextFilter';

interface Props {
    search: string;
    onSearchChange(value: string): void;

    noManagers: boolean;
    onNoManagersChange(value: boolean): void;

    noCustomers: boolean;
    onNoCustomersChange(value: boolean): void;

    noCustomerGroup: boolean;
    onNoCustomerGroupChange(value: boolean): void;

    noCustomerGroupDisabled: boolean;
}

export default function (props: Props) {
    return (<Row gutter={[20, 20]} align="bottom">
        <Col sm={11} md={7} lg={6}>
            <ClearableTextFilter value={props.search} onChange={props.onSearchChange} placeholder="Dist No, Name, Customer No, Customer Name" />
        </Col>
        <Col>
            <Checkbox checked={props.noManagers} onChange={event => props.onNoManagersChange(event.target.checked)}>Without Managers</Checkbox>
        </Col>
        <Col>
            <Checkbox checked={props.noCustomers} onChange={event => props.onNoCustomersChange(event.target.checked)}>Without Customers</Checkbox>
        </Col>
        <Col>
            <Tooltip title="By checking it you can filter for Customers (not Groups) which have no Customer Group assigned">
                <Checkbox checked={props.noCustomerGroup} onChange={event => props.onNoCustomerGroupChange(event.target.checked)} disabled={props.noCustomerGroupDisabled}>Orphan Customer</Checkbox>
            </Tooltip>
        </Col>
    </Row>);
}