import { PurchaseType } from "./Purchases";

/**
 * Purchase types. It can be used as the Sales types.
 */
export const purchaseTypes: PurchaseType[] = [
    { name: 'Stock Debit', shortName: 'SD', colorCode: '#FF3222' },
    { name: 'Stock Credit', shortName: 'SC'},
    // {name: 'Network Sale', shortName: 'NS', colorCode: '#FF3222' },
    {name: 'Purchase Order', shortName: 'PO'},
    {name: 'Bunker Delivery', shortName: 'BD', colorCode: '#00A854'},
];

export const purchaseNamedTypes = {
    StockDebit: purchaseTypes[0],
    // NetworkSale: purchaseTypes[2]
};