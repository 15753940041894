import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import { apiCaller } from "../../backend/apiCaller";
import { PurchaseRecord, PurchaseResults } from "../../models/Purchases";
import { ApplicationState } from "../rootReducer";

const initialSortOrder = 'date_desc';

export const purchaseSlice = createSlice({
    name: 'purchases',
    initialState: {
        inProgress: true,
        data: {
            items: [] as PurchaseRecord[],
            totalCount: 0
        } as PurchaseResults, 
        pageSize: 10,
        pageIndex: 1,
        sortOrder: initialSortOrder,
        search: '',
        fromDate: null as null | string, // moment().unix(),
        untilDate: null as null | string,// moment().add(-7, 'day').unix(),
        type: '',
        isInitialized: false,
    },
    reducers: {
        startLoading(state) { state.inProgress = true },
        endLoading(state) { state.inProgress = false },
        setPageIndex(state, action: PayloadAction<number>) { state.pageIndex = action.payload },
        setSortOrder(state, action: PayloadAction<string>) {
            if (action.payload) {
                state.sortOrder = action.payload;
            }
            else {
                state.sortOrder = initialSortOrder;
            }
        },
        setData(state, action: PayloadAction<PurchaseResults>) { state.data = action.payload },
        setSearch(state, action: PayloadAction<string>) { state.search = action.payload; state.pageIndex = 1; },
        setDateRange(state, action: PayloadAction<[string | null, string | null]>) { state.fromDate = action.payload[0]; state.untilDate = action.payload[1]; state.pageIndex = 1; },
        setType(state, action: PayloadAction<string>) { state.type = action.payload; state.pageIndex = 1; },
        setInitialized(state){ state.isInitialized = true;},
        reInit(state){ state.isInitialized = false; state.pageIndex = 1;},
    }
});

export function fetchPurchasesThunk() {
    return function (dispatch: Dispatch<any>, getState: () => ApplicationState) {

        dispatch(purchaseSlice.actions.startLoading());
        const state = getState().purchases;

        return apiCaller(getState).getJson('/api/purchase', {
            page: state.pageIndex,
            pageSize: state.pageSize,
            sortOrder: state.sortOrder,
            search: state.search,
            fromDate: state.fromDate,
            untilDate: state.untilDate,
            type: state.type,
        })
            .then(response => response.json())
            .then((results: PurchaseResults) => {
                dispatch(purchaseSlice.actions.setData(results));
				dispatch(purchaseSlice.actions.setInitialized());
            })
            .catch(reason => notification.error({ message: 'Can\'t load Purchases' }))
            .finally(() => dispatch(purchaseSlice.actions.endLoading()));
    }
}