import { Checkbox, Col, Form, Input, Modal, notification, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useState } from 'react';
import { apiCallerGlobal } from '../../../backend/apiCaller';
import { CustomerChooserData } from '../../../models/Customers';
import { useUfoErrorHandling } from '../../utils/UfoErrors/ufoErrorHook';
import UfoErrorList from '../../utils/UfoErrors/UfoErrorList';
import PhoneFormItem from '../Common/PhoneFormItem';

interface Props {
    show: boolean;
    onClose(): void;
    onSuccess(): void;
    customers: CustomerChooserData[];
}

export default function (props: Props) {

    const [inProgress, setInProgress] = useState(false);

    const [form] = useForm();

    const errorHandler = useUfoErrorHandling();

    async function submit(values: any) {

        setInProgress(true);

        try {
            await apiCallerGlobal().postJson('/api/user/create', {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
                customers: values.customers
            });

            setInProgress(false);
            notification.success({message: 'User has been created successfully'});
            form.resetFields();
            props.onClose();
            props.onSuccess();

        } catch (error) {
            setInProgress(false);
            errorHandler.setError(error);
        }

    }

    const options = props.customers.map(s => ({
        label: s.name,
        value: s.number
    }));

    return (<Modal visible={props.show} closable destroyOnClose onCancel={props.onClose}
        title="Create New User" okText="Create" okButtonProps={{ disabled: inProgress }} confirmLoading={inProgress} onOk={form.submit}>
        <Form form={form} onFinish={submit} layout="vertical" autoComplete="off"
            onChange={errorHandler.reset}
        >

            <Row gutter={10}>
                <Col xs={12}>
                    <FormItem name="firstName" label="First Name" style={{ width: '100%' }}
                        rules={[{ required: true }, { max: 127 }]}>
                        <Input placeholder="First Name" autoComplete="off" />
                    </FormItem>
                </Col>
                <Col xs={12}>
                    <FormItem name="lastName" label="Last Name" style={{ width: '100%' }}
                        rules={[{ required: true }, { max: 127 }]}>
                        <Input placeholder="Last Name" autoComplete="off" />
                    </FormItem>
                </Col>
            </Row>

            <FormItem name="email" label="Email"
                rules={[{ required: true }, { max: 127 }, { type: 'email' }]}
            >
                <Input placeholder="Email" autoComplete="off" type="email" />
            </FormItem>

            <PhoneFormItem />

            <FormItem name="customers" label="Customers"
                rules={[{required: true, message: 'Please check at least 1 Customer'}]} >
                <Checkbox.Group options={options} />
            </FormItem>

            <UfoErrorList apiError={errorHandler.apiError} />
        </Form>
    </Modal>);
}