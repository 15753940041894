import { Button, Result } from 'antd';
import React from 'react';

interface Props{
  hide: () => void;
}

export default function (props: Props) {
    return (<>
        <Result
    status="success"
    title="Password has been changed successfuly"
    // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
    extra={[
      <Button key="change" onClick={props.hide}>Change again</Button>,
    ]}
  />
    </>);
}