import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { AdminCustomerGroupItem } from '../../../models/AdminCustomerGroups';
import { AdminUserRecord } from '../../../models/AdminUsers';
import { useModalState } from '../../utils/modalStateHook';
import AdminUserEditManagerForm from './AdminUserEditManagerForm';

interface Props {
    refresh(): void;
    customerGroups: AdminCustomerGroupItem[];
    record: AdminUserRecord;
}

export default function (props: Props) {

    const modalState = useModalState();

    return (<>
        <Button type="primary" shape="circle" onClick={modalState.open} ><EditOutlined /></Button>
        {modalState.show && <AdminUserEditManagerForm show={modalState.show} onClose={modalState.close} onSuccess={props.refresh} record={props.record}
        customerGroups={props.customerGroups}
        />}
    </>);
}