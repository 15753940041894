import { BarChartOutlined, BarsOutlined, DeliveredProcedureOutlined, FileOutlined, MessageOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import React from 'react';
import MainMenuCard from './MainMenuCard';
import MainMenuColumn from './MainMenuColumn';
import styles from './PageHome.module.css';

const Column = MainMenuColumn;

export default function () {
    return (<>

        <div className={styles.wrapper}>
            <Row gutter={[20, 20]} align="middle">
                <Column><MainMenuCard title="Stock" path="/stock" icon={<BarChartOutlined />} /></Column>
                <Column><MainMenuCard title="Purchase History" path="/purchases" icon={<DeliveredProcedureOutlined />} /></Column>
                {/* <Column><MainMenuCard title="Sales History" path="/sales" icon={<ShopOutlined />} /></Column> */}

                <Column><MainMenuCard title="Transactions" path="/transactions" icon={<BarsOutlined />} /></Column>
                {/* <Column><MainMenuCard title="Cards" path="cards" icon={<CreditCardOutlined />} /></Column> */}
                <Column><MainMenuCard title="Invoices / Statements" path="/invoices-statements" icon={<FileOutlined />} /></Column>
                <Column><MainMenuCard title="Users" path="/users" icon={<UsergroupAddOutlined />} /></Column>
                <Column><MainMenuCard title="Contact" path="/contact" icon={<MessageOutlined />} /></Column>
            </Row>
        </div>
    </>);
}
