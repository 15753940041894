import React from 'react';
import { useAppDispatch as useDispatch } from '../../../../store/hooks';
import useAuthenticatedUser from '../../../../auth/useAuthenticatedUser';
import { useSelectorShallow } from '../../../../store/selectorShallow';
import { customerChooserSlice } from '../../../../store/slices/CustomerChooserSlice';
import UfoParagraphForTable from '../../../utils/UfoParagraph/UfoParagraphForTable';
import DocumentEditEmailButton from './DocumentEditEmailButton';


export default function () {

    const dispatch = useDispatch();

    const currentCustomer = useSelectorShallow(s => s.customerChooserData.data.find(c => c.number === s.customerChooserData.selectedNumber));
    const customerEmail = currentCustomer?.email;

    const currentUser = useAuthenticatedUser().user;
    const isAdminOrManager = currentUser.isAdmin || currentUser.isManager;

    return (<>
        {currentCustomer && customerEmail && <UfoParagraphForTable marginBottom={20} >
            The notifications about newly available documents will be delivered to this email address: <strong>{customerEmail}</strong>.
            {isAdminOrManager && <span>You can edit this email <DocumentEditEmailButton
                onSuccess={email => dispatch(customerChooserSlice.actions.setEmail({ email, customerNumber: currentCustomer.number }))}
                email={customerEmail}
                selectedCustomerNumber={currentCustomer.number}
            >here</DocumentEditEmailButton> .</span>}
        </UfoParagraphForTable>}

        
        {currentCustomer && !customerEmail && isAdminOrManager && <UfoParagraphForTable marginBottom={20} ><span>You can subscribe to document notifications <DocumentEditEmailButton
            onSuccess={email => dispatch(customerChooserSlice.actions.setEmail({ email, customerNumber: currentCustomer.number }))}
            email={""}
            selectedCustomerNumber={currentCustomer.number}
        >here</DocumentEditEmailButton> .</span></UfoParagraphForTable>}
    </>);
}