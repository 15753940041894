const appSettings = {
    cognitoRegion: getEnvironmentVariable('cognito_region'),
    cognitoUserPoolId: getEnvironmentVariable('cognito_user_pool_id'),
    cognitoUserClientId: getEnvironmentVariable('cognito_client_id'),
    useFakeBackend: (getEnvironmentVariable('use_fake_backend') ?? 'true').toLowerCase()  === "true",
    backendUrl: getEnvironmentVariable('backend_url') ?? '',
};

if(appSettings.backendUrl === '' && !appSettings.useFakeBackend){
    throw new Error('BACKEND_URL setting is required if not using fake backend');
}

// console.log(appSettings);

export default appSettings;


function getEnvironmentVariable(name: string, defaultValue?: string): string | undefined{
    let result = process.env['REACT_APP_'.concat(name)];

    if(result !== undefined){
        return result;
    }

    return defaultValue;
}