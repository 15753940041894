import { LockOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import PageHeader from '../../utils/PageHeader/PageHeader';
import FormBox from '../Common/FormBox';
import AccountBasicsForm from './AccountBasicsForm';
import ChangePasswordForm from './ChangePasswordForm';

const TabPane = Tabs.TabPane;

export default function () {
    
    const currentLocation = useLocation();
    const navigator = useNavigate();

    let activeKey = 'basics';

    if(currentLocation.pathname.endsWith('/change-password')){
        activeKey = 'change-password';
    }

    function updateUrl(key: string) {
        let url = '/account/basics';

        if(key === 'change-password'){
            url = '/account/change-password';
        }

        navigator(url, {replace: false});
    }
    
    
    return (<>
        <PageHeader title="Account Settings" />

        

        <div style={{ width: '100%', maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
            <Tabs tabPosition='top' activeKey={activeKey} onTabClick={updateUrl} destroyInactiveTabPane>
            <TabPane tab={<span><UsergroupAddOutlined /> Basic Information</span>} key="basics">
                    <FormBox>
                        <AccountBasicsForm />
                    </FormBox>
                </TabPane>
                <TabPane tab={<span><LockOutlined />Change Password</span>} key="change-password">
                    <FormBox>
                        <ChangePasswordForm />
                    </FormBox>
                </TabPane>
                
            </Tabs>
        </div>
    </>);
}