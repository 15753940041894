import { Button } from 'antd';
import React from 'react';
import { useModalState } from '../../../utils/modalStateHook';
import DocumentEditEmailForm from './DocumentEditEmailForm';

interface Props {
    onSuccess(email: string): void;
    email: string;
    selectedCustomerNumber: string;
    children: React.ReactNode;
}

export default function (props: Props) {
    const modalState = useModalState();

    return (<>
        <Button style={{paddingLeft: 0, paddingRight: 0}} onClick={modalState.open} type="link">{props.children}</Button>
        {modalState.show && <DocumentEditEmailForm show={modalState.show} onClose={modalState.close} onSuccess={props.onSuccess} email={props.email} selectedCustomerNumber={props.selectedCustomerNumber} />}
    </>);
}