import Auth from '@aws-amplify/auth';
import { Button, Form, Input, notification } from 'antd';
import React, { useState } from 'react';
import useAuthenticatedUser from '../../../auth/useAuthenticatedUser';
import { apiCallerGlobal } from '../../../backend/apiCaller';
import PageTitle from '../../utils/PageTitle';
import { useUfoErrorHandling } from '../../utils/UfoErrors/ufoErrorHook';
import UfoErrorList from '../../utils/UfoErrors/UfoErrorList';
import PhoneFormItem from '../Common/PhoneFormItem';

export default function () {
    const [inProgress, setInProgress] = useState(false);

    const currentUser = useAuthenticatedUser();

    const errorHandler = useUfoErrorHandling();


    async function submit(values: any) {
        
        setInProgress(true);

        try {

            await apiCallerGlobal().postJson('/api/account/edit', {
                firstName: values.firstName,
                lastName: values.lastName,
                phone: values.phone,
            })

            setInProgress(false);

            notification.success({message: 'The modification has been successful'});


            const refreshedUser = await Auth.currentAuthenticatedUser({bypassCache: false});

            currentUser.update(refreshedUser);

        } catch (error: any) {
            setInProgress(false);
  
            errorHandler.setError(error);
        }
        
    }


    return (<>
        <PageTitle title="Basic Account Information" />

        <Form
            onFinish={submit}
            id="accountBasicsForm"

            autoComplete="off"
            layout="vertical"
            // style={{ marginTop: 30 }}
            initialValues={{
                firstName: currentUser.user.firstName,
                lastName: currentUser.user.lastName,
                phone: currentUser.user.phone
            }}
            onChange={errorHandler.reset}
        >
            <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true }, { min: 2 }, { max: 127 }]}
            >
                <Input size="large" autoComplete="off" disabled={inProgress} />
            </Form.Item>

            <Form.Item
                label="Last Name"
                name="lastName"
                rules={[{ required: true }, { min: 2 }, { max: 127 }]}
            >
                <Input size="large" autoComplete="off" disabled={inProgress} />
            </Form.Item>

            <PhoneFormItem disabled={inProgress} size="large" />

            <Button type="primary" size="large" htmlType="submit" loading={inProgress}> 
                Save
            </Button>

            <UfoErrorList withMarginTop apiError={errorHandler.apiError} />
        </Form>
    </>);
}