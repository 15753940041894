import React from 'react';
import NumberNegativable from '../Common/NumberNegativable';

interface Props {
    value?: number | null;
}

export default function (props: Props) {

    if (!props.value && props.value !== 0) {
        return (<></>);
    }

    return (<div style={{whiteSpace: 'nowrap'}}>
        <span>£ </span><NumberNegativable value={props.value} /> 
    </div>);
}