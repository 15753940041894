export interface ApiErrorDetails{
    messages: string[];
    isServerError: boolean;
    isNetworkError: boolean;
}

export function apiError(...messages: string[]): ApiErrorDetails{
    return {
        messages: messages,
        isNetworkError: false,
        isServerError: false
    }
}



export function apiErrorServerError(message?: string): ApiErrorDetails{
    const result = {
        messages: [],
        isNetworkError: false,
        isServerError: true
    } as ApiErrorDetails;

    if(message){
        result.messages.push(message);
    }

    return result;
}

export function apiNetworkError(message: string): ApiErrorDetails{
    const result = {
        messages: [],
        isNetworkError: true,
        isServerError: false
    } as ApiErrorDetails;

    if(message){
        result.messages.push(message);
    }

    return result;
}