import Auth from "@aws-amplify/auth";
import ApiCallerWithBaseUrl from "./ApiCallerWithBaseUrl";
import { apiError, apiErrorServerError } from "./ApiErrors";

export class ApiCallerWithToken {

    private caller: ApiCallerWithBaseUrl;

    constructor(baseUrl: string) {
        this.caller = new ApiCallerWithBaseUrl(baseUrl);
    }

    getJson(url: string, queryParameters?: {}, headers?: {}): Promise<Response> {
        return this.callInternal(
            token => this.caller.getJson(url, token, queryParameters, headers)
        );
    }

    postJson(url: string, payload: any, headers?: {}): Promise<Response> {
        return this.callInternal(
            token => this.caller.postJson(url, token, payload, headers)
        );
    }

    downloadFile(url: string, queryParameters?: {}, headers?: {}): Promise<Response> {
        return this.callInternal(
            token => this.caller.downloadFile(url, token, queryParameters, headers)
        );
    }

    private callInternal(callFunc: (token: string) => Promise<Response>): Promise<Response> {
        return new Promise<Response>(async (resolve, reject) => {
            const session = await Auth.currentSession();

            if (!session.isValid()) {
                reject(apiError('User is not authenticated'));
                return;
            }

            try {
                const response = await callFunc(session.getAccessToken().getJwtToken());

                // console.log(response);

                if (response.status === 401) {
                    await Auth.signOut();
                    reject(apiError('User is not authenticated'));
                    return;
                }

                if (response.status === 400) {
                    const errorDetails = await response.json();

                    const resultErrorList = [];

                    if (errorDetails.hasOwnProperty('errors')) {


                        for (const key in errorDetails.errors) {
                            if (Object.prototype.hasOwnProperty.call(errorDetails.errors, key)) {
                                const fieldErrors = errorDetails.errors[key];

                                if (Array.isArray(fieldErrors)) {
                                    resultErrorList.push(...fieldErrors);
                                }
                            }
                        }


                    }
                    
                    if(errorDetails.hasOwnProperty('detail')){
                        resultErrorList.push(errorDetails.detail);
                    }

                    if(resultErrorList.length > 0){

                        reject(apiError(...resultErrorList));
                    }
                    
                    else {
                        console.error(errorDetails);
                        reject(apiErrorServerError());
                    }

                    return;
                }

                if (!response.ok) {
                    reject(apiErrorServerError());
                    return;
                }

                resolve(response);

            } catch (callerFuncError) {
                console.error(callerFuncError);
                reject(apiErrorServerError());
            }

        });
    }
}