import { Col, Row } from 'antd';
import React from 'react';
import { DocumentTypeEnum, MonthWeekInfo } from '../../../models/Documents';
import DocumentMonthOrWeekChooser from './DocumentMonthOrWeekChooser';
import DocumentTypeChooser from './DocumentTypeChooser';
import DocumentYearChooser from './DocumentYearChooser';


interface Props {
    type: DocumentTypeEnum | null;
    onTypeChange(value: DocumentTypeEnum): void;
    types: DocumentTypeEnum[];

    year: number | null;
    years: number[];
    onYearChange(value: number): void;

    monthOrWeek: number | null;
    monthsOrWeeks: MonthWeekInfo[];
    onMonthOrWeekChange(value: number | null): void;
}

export default function (props: Props) {

    const monthWeekEmptyText = props.type === DocumentTypeEnum.WeeklyStatement || props.type === DocumentTypeEnum.WeeklyInvoice
        ? 'All Weeks'
        : 'All Months';

    return (<Row style={{marginBottom: '15px'}} gutter={[15, 15]}>
        <Col lg={5} md={6} sm={8}>
            <DocumentTypeChooser value={props.type} onChange={props.onTypeChange} valueSet={props.types} />
        </Col>
        <Col>
            <DocumentYearChooser value={props.year} onChange={props.onYearChange} valueSet={props.years} />
        </Col>
        <Col sm={7} md={5} lg={4}>
            <DocumentMonthOrWeekChooser value={props.monthOrWeek} onChange={props.onMonthOrWeekChange} valueSet={props.monthsOrWeeks}
                emptyText={monthWeekEmptyText} />
        </Col>
    </Row>);
}