import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import { apiCallerGlobal } from "../../backend/apiCaller";
import { AdminCustomerGroupChooserResults, AdminCustomerGroupItem } from "../../models/AdminCustomerGroups";

export const adminCustomerGroupChooserSlice = createSlice({
	name: 'admin_customer_group_chooser',
	initialState: {
		inProgress: false,
		data: [] as AdminCustomerGroupItem[],
		selected: '',
		isInitialized: false,
	},
	reducers: {
		startLoading(state) { state.inProgress = true },
		endLoading(state) { state.inProgress = false },
		setData(state, action: PayloadAction<AdminCustomerGroupItem[]>) { state.data = action.payload },
		setInitialized(state, action: PayloadAction<boolean>) { state.isInitialized = action.payload; },
		setSelected(state, action: PayloadAction<string>) { state.selected = action.payload },
	}
});

export function fetchAdminCustomerGroupChooserThunk() {
	return function (dispatch: Dispatch) {

		dispatch(adminCustomerGroupChooserSlice.actions.startLoading());

		return apiCallerGlobal().getJson('/admin/customergroupchooser', {
		})
			.then(response => response.json())
			.then((results: AdminCustomerGroupChooserResults) => {

				const items = results.items.map(s => ({
					id: s.id,
					distributorNumber: s.distributorNumber,
					name: `${s.name} ${s.customerNumbers.length > 0 ? '[' + s.customerNumbers.join(', ') + ']' : ''}`
				}) as AdminCustomerGroupItem);

				dispatch(adminCustomerGroupChooserSlice.actions.setData(items));
				dispatch(adminCustomerGroupChooserSlice.actions.setSelected(results.selected));
				dispatch(adminCustomerGroupChooserSlice.actions.setInitialized(true));
				dispatch(adminCustomerGroupChooserSlice.actions.endLoading());

			})
			.catch(reason => notification.error({ message: 'Can\'t load Customer Groups' }))
			.finally(() => dispatch(adminCustomerGroupChooserSlice.actions.endLoading()));
	}
}