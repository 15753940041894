import { AnyAction } from "@reduxjs/toolkit";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { useDispatch } from "react-redux";

export interface OnTableChangeFunc {
    (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[]): void;
}

/**
 * 
 * @param fetchThunk This type must have a Thunk shape
 * @param setSort The redux action for setting the sort order (it is dispatched by the func)
 * @param setPage The redux action for setting the page index (it is dispatched by the func)
 * @returns 
 */
export function useTableChange(fetchThunk: () => any, setSort: (sortOrder: string) => AnyAction, setPage?: (pageIndex: number) => AnyAction): OnTableChangeFunc {


    const dispatch = useDispatch();

    return function (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any) {

        if (setPage) {
            // console.log(pagination);
            dispatch(setPage(pagination.current ?? 1));
        }

            // console.log(sorter);
        if (sorter.column) {
            let sortOrder = sorter.columnKey;
            if (sorter.order === 'descend') {
                sortOrder += '_desc';
            } else {
                sortOrder += '_asc'
            }

            dispatch(setSort(sortOrder));

        } else {
            dispatch(setSort(''));

        }



        dispatch(fetchThunk());
    }


}