import { SizeType } from 'antd/lib/config-provider/SizeContext';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';
import React from 'react';

interface Props {
    from?: string | null;
    until?: string | null;

    onChange(from?: string | null, to?: string | null): void;

    placeholder?: [string, string];
    size?: SizeType;
    disabled?: [boolean, boolean] | boolean;
    style?: React.CSSProperties;
}

export const UfoDateFormatString = 'YYYY-MM-DD';

export default function (props: Props) {


    return (<>
        <DatePicker.RangePicker value={[getMomentValue(props.from), getMomentValue(props.until)]} onChange={(values) => {
            props.onChange(values?.[0]?.format(UfoDateFormatString), values?.[1]?.format(UfoDateFormatString));
        }}
        style={{ width: '100%', ...props.style}}
        size={props.size}
        disabled={props.disabled}
        placeholder={props.placeholder}
        allowEmpty={[true, true]}
        />
    </>);
}

function getMomentValue(value: string | null | undefined): moment.Moment  | null {
    if(!value || value === ''){
        return null;
    }

    return moment(value);
}