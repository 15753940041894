import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import { Hub } from '@aws-amplify/core';
import appSettings from '../backend/appSettings';
import { cognitoGroups } from './cognitoGroups';
import BlaboUser from './components/BlaboUser';


export function initAmplify(onSignOut: () => void) {

    Amplify.configure({
        Auth: {

            // REQUIRED - Amazon Cognito Region
            region: appSettings.cognitoRegion,

            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: appSettings.cognitoUserPoolId,

            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: appSettings.cognitoUserClientId,

            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
            mandatorySignIn: true,

            // // OPTIONAL - Configuration for cookie storage
            // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
            // cookieStorage: {
            //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            //     domain: '.yourdomain.com',
            //     // OPTIONAL - Cookie path
            //     path: '/',
            //     // OPTIONAL - Cookie expiration in days
            //     expires: 365,
            //     // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            //     sameSite: "strict" | "lax",
            //     // OPTIONAL - Cookie secure flag
            //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            //     secure: true
            // },

            // // OPTIONAL - customized storage object
            // storage: MyStorage,

            // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
            authenticationFlowType: 'USER_SRP_AUTH',

            // // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
            // clientMetadata: { myCustomKey: 'myCustomValue' },

            // // OPTIONAL - Hosted UI configuration
            // oauth: {
            //     domain: 'lion-blabosoft-dev',
            //     scope: ['phone', 'email', 'profile', 'openid'],
            //     // redirectSignIn: 'd11dg6ibs6ryby.cloudfront.net',
            //     // redirectSignOut: 'https://lion-blabosoft-dev.auth.eu-west-2.amazoncognito.com',

            //     redirectSignIn: 'http://localhost:3000',
            //     redirectSignOut: 'http://localhost:3001',
            //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
            // }
        }
    });


    const listener = (data: any) => {
        // console.log(data);

        switch (data.payload.event) {
            // case 'signIn':
            //     logger.info('user signed in');
            //     break;
            // case 'signUp':
            //     logger.info('user signed up');
            //     break;
            case 'signOut':
                onSignOut();

                break;
            // case 'signIn_failure':
            //     logger.error('user sign in failed');
            //     break;
            // case 'tokenRefresh':
            //     logger.info('token refresh succeeded');
            //     break;
            case 'tokenRefresh_failure':
                Auth.signOut();
                break;
        }
    }

    Hub.listen('auth', listener);
}

export function cognitoToBlaboUser(user: any): BlaboUser{

    // console.log(user);

    const attributes = user.attributes;

    const lastName = attributes['family_name'] as string;

    const firstName = attributes['given_name'] as string;

    let monogram = 'AZ';

    if(lastName && firstName && lastName.length > 0 && firstName.length > 0){
        monogram = firstName[0].toUpperCase() + lastName[0].toUpperCase();
    }

    const groups = user.signInUserSession?.idToken?.payload['cognito:groups'] as string[] | undefined ?? [] as string[];



    return {
        email: attributes.email,
        lastName: lastName,
        firstName: firstName,
        monogram: monogram,
        fullName: ''.concat(firstName, ' ', lastName),
        userName: user.username,
        cognitoUser: user,
        isAuthenticated: true,
        phone: attributes['phone_number'] ?? '',
        isAdmin: groups.includes(cognitoGroups.Admin),
        isManager: groups.includes(cognitoGroups.Manager)
    };
}





