export default interface BlaboUser{
    firstName: string;
    lastName: string;
    email: string;
    
    /**
     * Calculated from firstName and lastName
     */
    monogram: string;

    /**
     * Calculated from firstName and lastName
     */
    fullName: string;

    /**
     * The unique identifier of the user.
     * Currently it has the same value as the email.
     */
    userName: string;

    /**
     * The user object of the Cognito Amplify library
     */
    cognitoUser: any;

    isAuthenticated: boolean;

    phone: string;

    isManager: boolean;

    isAdmin: boolean;
}

export const blaboUserDefault: BlaboUser = {
    cognitoUser: {},
    email: '',
    firstName: '',
    fullName: '',
    lastName: '',
    monogram: '',
    userName: '',
    isAuthenticated: false,
    phone: '',
    isAdmin: false,
    isManager: false,
}