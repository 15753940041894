import { Checkbox, Col, Form, Input, Modal, notification, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useState } from 'react';
import { useAppDispatch as useDispatch } from '../../../../store/hooks';
import { apiCallerGlobal } from '../../../../backend/apiCaller';
import { AdminCustomerGroupCustomer } from '../../../../models/AdminCustomerGroups';
import { customerChooserSlice } from '../../../../store/slices/CustomerChooserSlice';
import { useUfoErrorHandling } from '../../../utils/UfoErrors/ufoErrorHook';
import UfoErrorList from '../../../utils/UfoErrors/UfoErrorList';
import UfoInfoTooltip from '../../../utils/UfoInfoTooltip';

interface Props {
    record: AdminCustomerGroupCustomer;
    show: boolean;
    onClose(): void;
    onSuccess(): void;
}

export default function (props: Props) {

    const [inProgress, setInProgress] = useState(false);

    const [form] = useForm();

    const errorHandler = useUfoErrorHandling();

    const dispatch = useDispatch();


    async function submit(values: any) {

        setInProgress(true);

        console.log(values);

        try {
            await apiCallerGlobal().postJson('/admin/customergroup/customerEdit', {
                id: props.record.id,
                name: values.name,
                email: values.email,
                hiddenTransactionCustomer: values.hiddenTransactionCustomer
            });

            dispatch(customerChooserSlice.actions.setEmail({ email: values.email, customerNumber: props.record.number }));
            notification.success({ message: `Customer ${props.record.number} has been updated successfully` });

            setInProgress(false);
            form.resetFields();
            props.onClose();
            props.onSuccess();

        } catch (error) {
            setInProgress(false);
            errorHandler.setError(error);
        }

    }

    return (<Modal visible={props.show} closable destroyOnClose onCancel={props.onClose} width={600}
        title="Edit Customer" okText="Save" okButtonProps={{ disabled: inProgress }} confirmLoading={inProgress} onOk={form.submit}>
        <Form form={form} onFinish={submit} layout="vertical" autoComplete="off"
            initialValues={{
                name: props.record.name,
                email: props.record.email,
                hiddenTransactionCustomer: props.record.hiddenTransactionCustomer
            }}
            onChange={errorHandler.reset}
        >
            <div style={{ marginBottom: '30px' }}>
                Customer Number: <strong>{props.record.number}</strong>
            </div>
            <Row gutter={[7, 7]}>

                <Col xs={17}>
                    <FormItem name="name" label="Customer Name" style={{ width: '100%' }}
                        rules={[{ required: true }, { max: 127 }]}>
                        <Input placeholder="Name" autoComplete="off" />
                    </FormItem>
                </Col>
                <Col xs={17}>
                    <FormItem name="email" label="Email"
                        rules={[{ type: 'email' }, { max: 255 }]}
                    >
                        <Input placeholder="Email" autoComplete="off" suffix={<UfoInfoTooltip>This the email address where the Invoices and Statement file notifications will be delivered. <br /><br />The Managers can update this address through the Documents page as well.</UfoInfoTooltip>} />

                    </FormItem>
                </Col>
                <Col xs={17}>
                    <FormItem name="hiddenTransactionCustomer" style={{ width: '100%' }} valuePropName="checked">
                        <Checkbox onChange={value => { console.log(value) }}>
                            Hide Customer Column at Transactions <UfoInfoTooltip>
                                You can hide the Customer column for this Customer on the Transaction page by checking this box.<br /><br />
                                It can be useful when every row contains the same Customer name (the same as displayed on the top of the page), so you can eliminate displaying redundant information.
                            </UfoInfoTooltip>
                        </Checkbox>

                    </FormItem>
                </Col>
            </Row>
            <UfoErrorList apiError={errorHandler.apiError} />
        </Form>
    </Modal>);
}