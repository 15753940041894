import { RightSquareOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AppFooter.module.css';

export default function () {
    return (<div className={styles.footer}>
        <Row gutter={[20, 40]}>
            
            <Col sm={8}>
                {/* <a className={styles.velocityLink} href="https://www.velocityfleet.com/" rel="noreferrer"  target="_blank">Velocity</a> <br /> */}
                <Button href="https://www.velocityfleet.com/"  target="_blank" title="Open Velocity in new tab" rel="noreferrer" type="primary" style={{ margin: '0 0 10px 0', backgroundColor: '#288ecd'}} size="middle"> Visit Velocity <RightSquareOutlined /> </Button><br />
                <a href="https://ukfuels.co.uk" rel="noreferrer" target="_blank">UK Fuels</a> <br />
                <Link to="/disclaimer">Disclaimer</Link>
            </Col>

            <Col sm={10}>
                <strong>UK Fuels Limited</strong><br />
                Radius Campus, Emperor Way, Crewe Business Park, Crewe, CW1 6BD<br />
                Company No. 02212080<br />
            </Col>

            <Col sm={6} className={styles.logoWrapper}>
                <img src="/images/footerlogo.png" height='28' alt="UK Fuels Ltd." />

            </Col>
        </Row>
    </div>);
}