import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { apiCaller, apiCallerGlobal } from "../../backend/apiCaller";
import { CustomerChooserData, CustomerChooserResults } from "../../models/Customers";
import { ApplicationState } from "../rootReducer";
import { cardSlice } from "./CardSlice";
import { documentSlice } from "./DocumentSlice";
import { purchaseSlice } from "./PurchaseSlice";
import { stockSlice } from "./StockSlice";
import { transactionSlice } from "./TransactionSlice";
import { userSlice } from "./UserSlice";

export const customerChooserSlice = createSlice({
    name: 'customerChooser',
    initialState: {
        data: [] as CustomerChooserData[],

        error: '' as any,
        selectedNumber: '',

        inProgressForSelection: false,
        disabled: false,
        hadFirstInit: false,

        isInitialized: false,

    },
    reducers: {
        setData(state, action: PayloadAction<CustomerChooserResults>) {
            state.data = action.payload.items;
        },
        setCustomerNumber(state, action: PayloadAction<string>) {
            state.selectedNumber = action.payload;
        },
        setInProgressForSelection(state, action: PayloadAction<boolean>) { state.inProgressForSelection = action.payload },
        setDisabled(state, action: PayloadAction<boolean>) { state.disabled = action.payload },
        setHadFirstInit(state, action: PayloadAction<boolean>) { state.hadFirstInit = action.payload; },
        setError(state, action: PayloadAction<any>) { state.error = action.payload },
        setInitialized(state, action: PayloadAction<boolean>) { state.isInitialized = action.payload },
        setEmail(state, action:  PayloadAction<{email: string, customerNumber: string}>){
            const customer = state.data.find(s => s.number === action.payload.customerNumber);
            if(customer){
                customer.email = action.payload.email;
            }
        }
    },
});

export function fetchCustomerChooserThunk() {
    return function (dispatch: Dispatch, getState: () => ApplicationState) {

        const state = getState().customerChooserData;

        return apiCallerGlobal().getJson('/customerchooser')
            .then(response => response.json())
            .then((results: CustomerChooserResults) => {
                dispatch(customerChooserSlice.actions.setError(''));
                dispatch(customerChooserSlice.actions.setData(results));

                if (state.selectedNumber === ''
                    || (state.selectedNumber !== results.selectedNumber && !results.items.find(s => s.number === state.selectedNumber))) {
                    
                    dispatch(customerChooserSlice.actions.setCustomerNumber(results.selectedNumber));
                    invalidateAllDependentData(dispatch);
                }


                dispatch(customerChooserSlice.actions.setHadFirstInit(true));
                dispatch(customerChooserSlice.actions.setInitialized(true));
            })
            .catch(reason => customerChooserSlice.actions.setError(reason))
    }
}

export function setCustomerThunk(newNumber: string) {
    return async function (dispatch: Dispatch, getState: () => ApplicationState) {

        dispatch(customerChooserSlice.actions.setInProgressForSelection(false));

        dispatch(customerChooserSlice.actions.setCustomerNumber(newNumber));

        // Invalidate the all other stored data
        invalidateAllDependentData(dispatch);

        try {

            await apiCaller(getState).postJson('/customerchooser/change', { number: newNumber });

            dispatch(customerChooserSlice.actions.setInProgressForSelection(false));

        } catch (error) {
            dispatch(customerChooserSlice.actions.setInProgressForSelection(false));
            // notification.error({message: 'Can\'t change your current Customer'});
        }

    }
}


function invalidateAllDependentData(dispatch: Dispatch) {
    dispatch(cardSlice.actions.reInit());
    dispatch(documentSlice.actions.reInit());
    dispatch(purchaseSlice.actions.reInit());
    dispatch(stockSlice.actions.reInit());
    dispatch(transactionSlice.actions.reInit());
    dispatch(userSlice.actions.reInit());
}