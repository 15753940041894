import { DownloadOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import React, { useState } from 'react';
import { apiFileDownloader } from '../../backend/apiCaller';
import { useSelectorShallow } from '../../store/selectorShallow';

interface Props {
    url: string;
    parameters?: {};

    errorMessage?: string;
    text?: string;
}

export default function (props: Props) {

    const currentCustomerNumber = useSelectorShallow(s => s.customerChooserData.selectedNumber);


    const [inProgress, setInProgress] = useState(false);

    async function download() {

        setInProgress(true);

        try {
            await apiFileDownloader(currentCustomerNumber).download(props.url, props.parameters);
            setInProgress(false);

        } catch (error) {
            notification.error({ message: props.errorMessage ?? 'Can\'t download the CSV file' });
            setInProgress(false);
        }

    }

    return (<Button size="middle" onClick={download} loading={inProgress} disabled={inProgress} >
        <DownloadOutlined />  {props.text ?? 'Download as CSV'}</Button>);
}