import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import PageAccount from "./components/Pages/Account/PageAccount";
import PageAdminCustomerGroup from "./components/Pages/AdminCustomerGroups/PageAdminCustomerGroup";
import PageAdminUser from "./components/Pages/AdminUsers/PageAdminUser";
import PageCards from "./components/Pages/Cards/PageCards";
import PageContact from "./components/Pages/Contact/PageContact";
import PageDisclaimer from "./components/Pages/Disclaimer/PageDisclaimer";
import PageHome from "./components/Pages/Home/PageHome";
import PageInvoicesStatements from "./components/Pages/InvoicesStatements/PageInvoicesStatements";
import PageNotFound from "./components/Pages/PageNotFound";
import PagePurchases from "./components/Pages/Purchases/PagePurchases";
// import PageSales from "./components/Pages/Sales/PageSales";
import PageStock from "./components/Pages/Stock2/PageStock";
import PageTransaction from "./components/Pages/Transactions/PageTransaction";
import PageUsers from "./components/Pages/Users/PageUsers";

export default function () {
    return (
        <Layout >
            <Routes>
                <Route path='/' element={<PageHome />} />
                
                <Route path="/account/change-password" element={<PageAccount />} />
                <Route path="/account/basics" element={<PageAccount />} />

                <Route path='/purchases' element={<PagePurchases />} />
                <Route path='/stock' element={<PageStock />} />
                <Route path='/transactions' element={<PageTransaction />} />
                <Route path='/cards' element={<PageCards />} />
                <Route path='/invoices-statements' element={<PageInvoicesStatements />} />
                <Route path='/users' element={<PageUsers />} />
                <Route path='/contact' element={<PageContact />} />

                <Route path='/admin/users' element={<PageAdminUser />} />
                <Route path='/admin/customergroups' element={<PageAdminCustomerGroup />} />

                <Route path='/disclaimer' element={<PageDisclaimer />} />



                <Route path='*' element={<PageNotFound />} />
            </Routes> 
        </Layout>)
}
