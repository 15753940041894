import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import { apiCaller } from "../../backend/apiCaller";
import { CardRecord, CardResults } from "../../models/Cards";
import { ApplicationState } from "../rootReducer";


const initialSortOrder = 'issueDate_desc';



export const cardSlice = createSlice({
	name: 'cards',
	initialState: {
		inProgress: true,
		data: {
			totalCount: 0,
			items: [] as CardRecord[],
		} as CardResults,

		sortOrder: initialSortOrder,
		pageSize: 10,
		pageIndex: 1,

		// Filters
		search: '',
		stopFromDate: null as string | null,
		stopUntilDate:  null as string | null,
		issueFromDate:  null as string | null,
		issueUntilDate:  null as string | null,
		expiryFromDate: null as string | null,
		expiryUntilDate: null as string | null,
		products: '',
		active: true,

		isInitialized: false,
	},
	reducers: {
		startLoading(state) { state.inProgress = true },
		endLoading(state) { state.inProgress = false },

		// Filters
		setSearch(state, action: PayloadAction<string>) { state.search = action.payload; state.pageIndex = 1; },
		setProducts(state, action: PayloadAction<string>) { state.products = action.payload; state.pageIndex = 1; },
		setStopRange(state, action: PayloadAction<[string | null, string | null]>) { state.stopFromDate = action.payload[0]; state.stopUntilDate = action.payload[1]; state.pageIndex = 1; },
		setIssueRange(state, action: PayloadAction<[string | null, string | null]>) { state.issueFromDate = action.payload[0]; state.issueUntilDate = action.payload[1]; state.pageIndex = 1; },
		setExpiryRange(state, action: PayloadAction<[string | null, string | null]>) { state.expiryFromDate = action.payload[0]; state.expiryUntilDate = action.payload[1]; state.pageIndex = 1; },
		setActive(state, action: PayloadAction<boolean>) { 
			state.active = action.payload;
			if(action.payload){
				state.stopFromDate = '';
				state.stopUntilDate = '';
			}
			state.pageIndex = 1; },


		setSortOrder(state, action: PayloadAction<string>) {
			if (action.payload) {
				state.sortOrder = action.payload;
			}
			else {
				state.sortOrder = initialSortOrder;
			}
		},
		setPageIndex(state, action: PayloadAction<number>) { state.pageIndex = action.payload },
		setData(state, action: PayloadAction<CardResults>) { state.data = action.payload },
		setInitialized(state){ state.isInitialized = true;},
        reInit(state){ state.isInitialized = false; state.pageIndex = 1;},
	}
});



export function fetchCardsThunk() {
	return function (dispatch: Dispatch<any>, getState: () => ApplicationState) {

		dispatch(cardSlice.actions.startLoading());
		const state = getState().cards;

		return apiCaller(getState).getJson('/card', {
			page: state.pageIndex,
			pageSize: state.pageSize,
			sortOrder: state.sortOrder,
			types: state.products,
			search: state.search,
			stopFrom: state.stopFromDate,
			stopUntil: state.stopUntilDate,
			active: state.active,
			issueFrom: state.issueFromDate,
			issueUntil: state.issueUntilDate
		})
			.then(response => response.json())
			.then((results: CardResults) => {
				dispatch(cardSlice.actions.setData(results));
				dispatch(cardSlice.actions.setInitialized());
			})
			.catch(reason => notification.error({ message: 'Can\'t load Cards' }))
			.finally(() => dispatch(cardSlice.actions.endLoading()));
	}
}