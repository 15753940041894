import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import { apiCaller } from "../../backend/apiCaller";
import { DocumentIndex, DocumentRecord, DocumentTypeEnum, MonthWeekInfo } from "../../models/Documents";
import { ApplicationState } from "../rootReducer";
import { documentSliceLogic } from "../sliceLogics/DocumentSliceLogic";

export const documentSlice = createSlice({
    name: 'documents',
    initialState: {
        inProgress: false,
        data: [] as DocumentRecord[],
        filteredData: [] as DocumentRecord[],
        selectedYear: null as null | number,
        selectedMonthOrWeek: null as null | number,
        selectedType: null as null | DocumentTypeEnum,

        fullIndex: {} as DocumentIndex,
        years: [] as number[],
        monthsOrWeeks: [] as MonthWeekInfo[],
        types: [] as DocumentTypeEnum[],  
        isInitialized: false,
        
    },
    reducers: {
        startLoading(state) {
            state.inProgress = true;
        },
        endLoading(state) {
            state.inProgress = false;
        },
        setData(state, action: PayloadAction<DocumentRecord[]>) {
            state.data = action.payload;

            const fullIndex = documentSliceLogic.getFullIndex(action.payload);
            state.fullIndex = fullIndex;

            const typeStrings = Object.keys(fullIndex).sort((a, b) => a.localeCompare(b) * -1);

            // If there are documents to show
            if (typeStrings.length > 0) {

                // Types
                state.selectedType = Number(typeStrings[0]) as DocumentTypeEnum;
                state.types = typeStrings.map(s => Number(s) as DocumentTypeEnum);

                const yearStrings = Object.keys(fullIndex[state.selectedType]).sort((a, b) => a.localeCompare(b) * -1);
                state.selectedYear = Number(yearStrings[0]);
                state.years = yearStrings.map(s => Number(s));

                state.monthsOrWeeks = documentSliceLogic.getMonthOrWeeks(fullIndex[state.selectedType][state.selectedYear]);
                state.selectedMonthOrWeek = null;
            }

            // Do the filtering
            state.filteredData = documentSliceLogic.filter(action.payload, state.selectedYear, state.selectedMonthOrWeek, state.selectedType);

        },

        setType(state, action: PayloadAction<DocumentTypeEnum>) {
            state.selectedType = action.payload;


            const yearStrings = Object.keys(state.fullIndex[state.selectedType]).sort((a, b) => a.localeCompare(b) * -1);
            state.selectedYear = Number(yearStrings[0]);
            state.years = yearStrings.map(s => Number(s));

            state.monthsOrWeeks = documentSliceLogic.getMonthOrWeeks(state.fullIndex[state.selectedType][state.selectedYear]);
            state.selectedMonthOrWeek = null;


            state.filteredData = documentSliceLogic.filter(state.data, state.selectedYear, state.selectedMonthOrWeek, state.selectedType);
        },

        setYear(state, action: PayloadAction<number>) {
            state.selectedYear = action.payload;

            state.monthsOrWeeks =  documentSliceLogic.getMonthOrWeeks(state.fullIndex[state.selectedType ?? -1][state.selectedYear]);
            state.selectedMonthOrWeek = null;

            state.filteredData = documentSliceLogic.filter(state.data, state.selectedYear, state.selectedMonthOrWeek, state.selectedType);
        },

        setMonth(state, action: PayloadAction<number | null>) {
            state.selectedMonthOrWeek = action.payload

            state.filteredData = documentSliceLogic.filter(state.data, state.selectedYear, state.selectedMonthOrWeek, state.selectedType);
        },
        setInitialized(state){ state.isInitialized = true;},
        reInit(state){ state.isInitialized = false;},
    }
});



export function fetchDocumentsThunk() {
    return function (dispatch: Dispatch, getState: ()=> ApplicationState) {
        dispatch(documentSlice.actions.startLoading());

        return apiCaller(getState).getJson('/api/document')
            .then(response => response.json())
            .then((results: DocumentRecord[]) => {
                dispatch(documentSlice.actions.setData(results));
				dispatch(documentSlice.actions.setInitialized());
            })
            .catch(reason => notification.error({message: 'Unable to fetch Documents'}))
            .finally(() => dispatch(documentSlice.actions.endLoading()));
    }
}