import { MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useAppDispatch as useDispatch } from '../../../../store/hooks';
import { menuToggleCollapseThunk } from '../../../../store/slices/MenuSlice';

export default function () {

    const dispatch = useDispatch();

    return (<div style={{}}>
        <Button icon={<MenuOutlined />} type="default" size="middle" onClick={() => dispatch(menuToggleCollapseThunk())} />
        {/* <MenuOutlined style={{fontSize: 18, color: '#555'}} /> */}
    </div>);
}