import React from 'react';
import { CustomerChooserData } from '../../../models/Customers';
import { UserRecord } from '../../../models/Users';

interface Props {
    record: UserRecord,
    allCustomerDetails: CustomerChooserData[];
}

export default function (props: Props) {
    
    if(props.record.isManager){
        return (<span style={{fontStyle: 'italic', fontWeight: 'bold', color: '#6677cc'}} title="Manager can see all data of the Customers">Manager</span>)
    }
    
    const customerList =  props.allCustomerDetails.filter(s => {
        const customerNumber = props.record.allCustomerNumbers.find(d => d=== s.number);

        if(customerNumber){
            return true;
        }

        return false;
    });

    return (<>
        {customerList.map(item => <div title={item.number}>{item.name}</div>)}
    </>);
}