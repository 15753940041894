import React, { useEffect, useState } from 'react';
import BlaboAuthLayout from './BlaboAuthLayout';
import BlaboForgotPassword from './BlaboForgotPassword';
import BlaboLogin from './BlaboLogin';
import BlaboResetPassword from './BlaboResetPassword';
import Auth from '@aws-amplify/auth';
import BlaboUser, { blaboUserDefault } from './BlaboUser';
import BlaboAuthContext from './BlaboAuthContext';
import { cognitoToBlaboUser, initAmplify } from '../cognito';
import BlaboResetPasswordSuccess from './BlaboResetPasswordSuccess';
import AppLoading from '../../components/Layout/AppLoading/AppLoading';
import { useInterval } from '../../components/utils/intervalHook';

interface Props {
    children: React.ReactNode;
}

export default function (props: Props) {

    const [currentUser, setCurrentUser] = useState<BlaboUser>(blaboUserDefault);


    const [isLoading, setIsLoading] = useState(true);

    const [route, setRoute] = useState('login');

    const [userNameForReset, setUserNameForReset] = useState<string | null>(null);

    function navigateReset(userName: string) {
        setUserNameForReset(userName);

        setRoute('resetPassword');
    }

    function setUser(user: any | null) {

        if (!user) {
            setCurrentUser(blaboUserDefault);
            return;
        }

        setCurrentUser(cognitoToBlaboUser(user));
    }

    function handleAuthError(error: any) {
        setUser(null);
        setIsLoading(false);
        setRoute('login');
        console.error(error);
    }



    useEffect(() => {

        initAmplify(() => setUser(null));

        Auth.currentAuthenticatedUser({ bypassCache: false })
            .then((user) => {
                // Calling set user with the cached data to prevent login dialog's "flashing in" effect
                setUser(user);

                setIsLoading(false);

                // Refreshing the user data
                Auth.currentAuthenticatedUser({ bypassCache: true })
                    .then(setUser)
                    .catch(handleAuthError);

            })
            .catch(handleAuthError);

        // eslint-disable-next-line
    }, []);

    useInterval(async () => {

        if (currentUser.isAuthenticated) {
            try {
                await Auth.currentSession();
            } catch (error) {
                console.error(error);

                if(currentUser.isAuthenticated){
                    setUser(null);
                }
            }
        }
    }, currentUser.isAuthenticated ? 4000: null);


    return (<BlaboAuthContext.Provider value={{ user: currentUser, update: setUser }}>
        {!isLoading && currentUser.isAuthenticated && props.children}
        {!isLoading && !currentUser.isAuthenticated && <BlaboAuthLayout>
            {route === 'login' && <BlaboLogin navigateForgotPassword={() => setRoute('forgotPassword')} setUser={setUser} />}
            {route === 'forgotPassword' && <BlaboForgotPassword navigateLogin={() => setRoute('login')} navigateReset={navigateReset} />}
            {route === 'resetPassword' && userNameForReset && <BlaboResetPassword
                navigateLogin={() => setRoute('login')}
                navigateForgot={() => setRoute('forgotPassword')}
                navigateSuccess={() => setRoute('resetPasswordSuccess')}
                userName={userNameForReset} />}
            {route === 'resetPasswordSuccess' && <BlaboResetPasswordSuccess navigateLogin={() => setRoute('login')} />}
        </BlaboAuthLayout>}
        {isLoading && <AppLoading />}
    </BlaboAuthContext.Provider>);
}