import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import { apiCaller } from "../../backend/apiCaller";
import { StockIsoWeekItem, StockWeekData } from "../../models/Stocks2";
import { ApplicationState } from "../rootReducer";
import { stockSliceLogic } from "../sliceLogics/StockSliceLogic";



export const stockSlice = createSlice({
    name: 'stock',
    initialState: {
        inProgress: true,
        data: {
            daily: [],
            selectedWeek: '',
            weekList: [],
            projected: [],
            week: null,
            hasDailyTpAddBack: false
        } as StockWeekData<StockIsoWeekItem>,
        isInitialized: false,
    },
    reducers: {
        startLoading(state) { state.inProgress = true },
        endLoading(state) { state.inProgress = false },
        setData(state, action: PayloadAction<StockWeekData<string>>) {
            state.data = {
                ...action.payload,
                weekList: stockSliceLogic.transformIsoWeeks(action.payload.weekList)
            }
        },

        setIsoWeek(state, action: PayloadAction<string>) { state.data.selectedWeek = action.payload },
        setInitialized(state){ state.isInitialized = true;},
        reInit(state){ state.isInitialized = false;},
    }
});

export function fetchStockThunk() {
    return function (dispatch: Dispatch, getState: () => ApplicationState) {

        dispatch(stockSlice.actions.startLoading());
        
        const selectedWeek = getState().stock.data.selectedWeek;

        return apiCaller(getState).getJson('/api/stock', { isoWeek: selectedWeek })
            .then(response => response.json())
            .then((results: StockWeekData<string>) => {
                dispatch(stockSlice.actions.setData(results));
				dispatch(stockSlice.actions.setInitialized());
            })
            .catch(reason => notification.error({ message: `Cant' load Stock Balances of week '${selectedWeek}'` }))
            .finally(() => dispatch(stockSlice.actions.endLoading()));
    }
}