import { Select, Tooltip } from 'antd';
import React from 'react';
import { useAppDispatch as useDispatch } from '../../../../store/hooks';
import { useSelectorShallow } from '../../../../store/selectorShallow';
import { setCustomerThunk } from '../../../../store/slices/CustomerChooserSlice';
import styles from './CustomerChooser.module.css';

export default function () {

    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.customerChooserData);

    async function onChange(value: string) {
        return dispatch(setCustomerThunk(value));
    }



    return (
        <div className={styles.chooserWrapper}>
            <div className={styles.chooserWrapperInner}>
                <WithTooltip disabled={reduxData.disabled}>
                    <Select value={reduxData.selectedNumber} onChange={onChange} size="middle" className={styles.chooser} bordered={true} disabled={reduxData.disabled} >
                        {reduxData.data.map(item => <Select.Option value={item.number} key={item.number}><strong>{item.number}</strong> - {item.name}</Select.Option>)}
                    </Select>
                </WithTooltip>
            </div>
        </div>);
}

function WithTooltip(props: { disabled: boolean, children: React.ReactNode }): React.ReactElement {

    if (props.disabled) {
        return (<Tooltip placement="leftBottom" title="The Customer Chooser is disabled because the contents of the current page is not related to only one particular Customer">
            {props.children}
        </Tooltip>);
    }

    return <>{props.children}</>;
}