import { UserAddOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { CustomerChooserData } from '../../../models/Customers';
import { useModalState } from '../../utils/modalStateHook';
import UserCreateModalForm from './UserCreateModalForm';

interface Props {
    refresh(): void;
    customers: CustomerChooserData[];
}

export default function (props: Props) {

    const modalState = useModalState();

    return (<>
        <Button type="primary" icon={<UserAddOutlined />} onClick={modalState.open} >Create User</Button>
        {modalState.show && <UserCreateModalForm show={modalState.show} onClose={modalState.close} onSuccess={props.refresh} customers={props.customers}/>}
    </>);
}