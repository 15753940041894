import { Tooltip } from "antd";
import { ColumnType} from "antd/lib/table/interface";
import { TransactionRecord, TransactionResults } from "../../../models/Transactions";
import UfoTable from "../../utils/UfoTable/UfoTable";
// import DateWithIsoWeek from "../Common/DateWithIsoWeek";
import NameNumberCell from "../Common/NameNumberCell";
import NumberNegativable from "../Common/NumberNegativable";
import { OnTableChangeFunc } from "../Common/tableChange";
import TransactionSalesCell from "./TransactionSalesCell";
import styles from './TransactionTable.module.css';



interface Props {
    results: TransactionResults,
    pageSize: number,
    pageIndex: number,
    inProgress: boolean;
    onTableChange: OnTableChangeFunc;
    hiddenCustomer?: boolean | null;
}

export default function (props: Props) {

    const results = props.results;

    const columns: ColumnType<TransactionRecord>[] = [
        {
            title: 'Customer',
            key: 'batchCustomer' ,
            render: (text: string, record: TransactionRecord) => <NameNumberCell number={record.customerNumberOther} name={record.customerNameOther} numberTitle="Customer Number" />
        }, {
            title: 'Site',
            key: 'site',
            render: (text: string, record: TransactionRecord) => <NameNumberCell numberTitle="Site Number" number={record.siteNumber} name={record.siteName} />
    
        }, {
            title: 'ISO / Card No.',
            key: 'isoNumber',
            render: (text: string, record: TransactionRecord) => <>{record.isoNumber}-{record.cardNumber}</>
        }, {
            title: 'Tran No.',
            dataIndex: 'transactionNumber',
            key: 'transactionNumber',
        }, {
            title: 'Registration',
            dataIndex: 'registration',
            key: 'registration',
        }, {
            title: 'Isoweek',
            key: 'isoWeek',
            dataIndex: 'isoWeek',
        }, {
            title: 'Date/Time',
            key: 'dateTime' ,
            sorter: true,
            dataIndex: 'dateTime'
        }, {
            title: 'Entry Date',
            dataIndex: 'entryDate',
            key: 'entryDate',
            // render: (text: string) => <div className='nowrap'>{moment(text).format('DD-MMM-YY')}</div>
        }, {
            title: 'Mileage',
            align: 'right',
            dataIndex: 'mileage',
            key: 'mileage',
            render: (value?: number) => <NumberNegativable value={value} />
        },
        {
            title: 'Product',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Quantity',
            dataIndex: 'amount',
            align: 'right',
            key: 'amount',
            render: (value: number) => <NumberNegativable value={value} />
        }];

    if(results.hasSalesValue){
        columns.push({
            title: <Tooltip title="Net Retail Sales Value (£) of the 'TP Diesel' Transactions"><span className={styles.headerInfo}>NET TP Diesel</span></Tooltip>,
            key: 'tpDiesel',
            align: 'right',
            render: (_, record) => <TransactionSalesCell value={record.salesValue} />
        })
    }

    if(props.hiddenCustomer){
        columns.splice(0, 1);
    }

    return (<UfoTable size="small"
        onChange={props.onTableChange}
        columns={ columns}
        dataSource={results.page.items}
        bordered={true}
        rowKey="id"
        pagination={{total: props.results.page.totalCount, current: props.pageIndex, pageSize: props.pageSize, showSizeChanger: false }}
        loading={{ delay: 400, size: 'large', spinning: props.inProgress }}
        verticalScroll
    />);
}