import React from 'react';
import 'antd/dist/antd.css';
import './App.css';
import AppRoutes from './AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import BlaboAuth from './auth/components/BlaboAuth';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
// import { initMockedBackend } from './backend/mocks/mockedBackend';
import CustomerChooserOrchestrator from './components/Layout/Navbar/CustomerChooser/CustomerChooserOrchestrator';

// initMockedBackend();

const store = configureStore();

function App() {




  return (
    <Provider store={store}>
      <BlaboAuth>
        <CustomerChooserOrchestrator>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </CustomerChooserOrchestrator>

      </BlaboAuth>
    </Provider>
  );
}

export default App;