import React, { ReactElement, ReactNode } from 'react';
import styles from './UfoInnerTable.module.scss';

interface Props {
    children?: ReactNode;
}

export default function (props: Props) {

    if (!props.children) {
        return (<></>);
    }

    return (<table className={styles.table}>
        <tbody>
            {props.children}
        </tbody>
    </table>);
}

export function UfoInnerTableRow(props: { children?: JSX.IntrinsicElements['td'] | JSX.IntrinsicElements['td'][] }): ReactElement {

    return (<tr className={styles.row}>
        {props.children as any}
    </tr>);

}