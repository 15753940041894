import React from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { useSelectorShallow } from '../../../store/selectorShallow';
import { fetchTransactionsThunk, transactionSlice } from '../../../store/slices/TransactionSlice';
import PageHeader from '../../utils/PageHeader/PageHeader';
import PageTitle from '../../utils/PageTitle';
import { useTableChange } from '../Common/tableChange';
import TransactionFilter from './TransactionFilter';
import TransactionTable from './TransactionTable';
import { useInvalidatableInitEffect } from '../../utils/usePageDataLoad';


export default function () {

    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.transactions);

    const hiddenCustomer = useSelectorShallow(s => s.customerChooserData.data.find(c => c.number === s.customerChooserData.selectedNumber)?.hiddenTransactionCustomer);

    const onTableChange = useTableChange(fetchTransactionsThunk, transactionSlice.actions.setSortOrder, transactionSlice.actions.setPageIndex);


	useInvalidatableInitEffect(() => dispatch(fetchTransactionsThunk()), reduxData);

    return (<>
        <PageHeader title="Transactions" />
        <PageTitle title="Transactions" />

        <TransactionFilter
            afterDate={reduxData.afterDate}
            beforeDate={reduxData.beforeDate}
            product={reduxData.product}
            search={reduxData.search}
            onDateChange={(from, until) => {
                dispatch(transactionSlice.actions.setDateRange([from, until]));
                dispatch(fetchTransactionsThunk());
            }}
            onSearchChange={value => {
                dispatch(transactionSlice.actions.setSearch(value));
                dispatch(fetchTransactionsThunk());
            }}
            onProductChanged={value => {
                dispatch(transactionSlice.actions.setProduct(value));
                dispatch(fetchTransactionsThunk());
            }}
            page={reduxData.pageIndex}
            pageSize={reduxData.pageSize}
            sortOrder={reduxData.sortOrder}
            hasTpDiesel={reduxData.data.hasSalesValue}

            hiddenCustomer={Boolean(hiddenCustomer)}
        />

        <TransactionTable inProgress={reduxData.inProgress} results={reduxData.data} pageSize={reduxData.pageSize} pageIndex={reduxData.pageIndex} onTableChange={onTableChange} hiddenCustomer={hiddenCustomer} />
    </>);
}