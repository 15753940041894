import { Col, Form, Input, Modal, notification, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useState } from 'react';
import { apiCallerGlobal } from '../../../backend/apiCaller';
import { AdminUserRecord } from '../../../models/AdminUsers';
import { useUfoErrorHandling } from '../../utils/UfoErrors/ufoErrorHook';
import UfoErrorList from '../../utils/UfoErrors/UfoErrorList';
import PhoneFormItem from '../Common/PhoneFormItem';

interface Props {
    record: AdminUserRecord;
    show: boolean;
    onClose(): void;
    onSuccess(): void;
}

export default function (props: Props) {

    const [inProgress, setInProgress] = useState(false);

    const [form] = useForm();

    const errorHandler = useUfoErrorHandling();


    async function submit(values: any) {

        setInProgress(true);

        try {
            await apiCallerGlobal().postJson('/admin/user/editadmin', {
                firstName: values.firstName,
                lastName: values.lastName,
                phone: values.phone,
                id: props.record.id,
            });

            setInProgress(false);
            notification.success({message: 'Admin user has been edited successfully'});

            form.resetFields();
            props.onClose();
            props.onSuccess();

        } catch (error) {
            setInProgress(false);
            errorHandler.setError(error);
        }

    }

    return (<Modal visible={props.show} closable destroyOnClose onCancel={props.onClose}
        title="Edit Admin User" okText="Save" okButtonProps={{ disabled: inProgress }} confirmLoading={inProgress} onOk={form.submit}>
        <Form form={form} onFinish={submit} layout="vertical" autoComplete="off"
            initialValues={{
                firstName: props.record.firstName,
                lastName: props.record.lastName,
                email: props.record.email,
                phone: props.record.phone
            }}
            onChange={errorHandler.reset}
            >

            <Row gutter={10}>
                <Col xs={12}>
                    <FormItem name="firstName" label="First Name" style={{ width: '100%' }}
                        rules={[{ required: true }, { max: 127 }]}>
                        <Input placeholder="First Name" autoComplete="off" />
                    </FormItem>
                </Col>
                <Col xs={12}>
                    <FormItem name="lastName" label="Last Name" style={{ width: '100%' }}
                        rules={[{ required: true }, { max: 127 }]}>
                        <Input placeholder="Last Name" autoComplete="off" />
                    </FormItem>
                </Col>
            </Row>

            <FormItem name="email" label="Email">
                <Input placeholder="Email" autoComplete="off" type="email" disabled readOnly />
            </FormItem>

            <PhoneFormItem />
            <UfoErrorList apiError={errorHandler.apiError} />
        </Form>
    </Modal>);
}