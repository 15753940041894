import moment from 'moment';
import React from 'react';

interface Props {
    date: string;
}

export default function (props: Props) {
    
    const momentDate = moment(props.date);

    return (<>
        <strong>{momentDate.format('dddd')}</strong> <span>({momentDate.format('DD-MMM-YY')})</span>
    </>);
}