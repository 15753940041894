import { Divider } from 'antd';
import React from 'react';
import styles from './UfoFormSection.module.scss';

interface Props {
    children?: React.ReactNode;
    title?: string;
}

export default function (props: Props) {
    return (<div className={styles.section}>
        {props.title && <Divider>{props.title}</Divider>}
        {!props.title && <Divider />}
        {props.children}
    </div>);
}