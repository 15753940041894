import { Col, Row} from 'antd';
import React from 'react';
import Logo from './Logo/Logo';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import CustomerChooser from './CustomerChooser/CustomerChooser';
import MenuButton from './Menu/MenuButton';

export default function () {
    return (<Row wrap={false} gutter={[20, 0]}>
        <Col flex="50px"  ><MenuButton /></Col>
        <Col flex="120px" ><Logo /></Col>
        <Col flex="auto">
            <CustomerChooser />
        </Col>

        <Col flex="40px" style={{ alignSelf: 'flex-end' }}><ProfileMenu /></Col>
    </Row>);
}