import { Col, Row } from 'antd';
import React from 'react';
import styles from './PageHeader.module.css';

interface Props {
    title: string;
    children?: React.ReactNode;
}

export default function (props: Props) {

    let headerClass = styles.header;

    let leftColumn = { xs: 4, sm: 4 };

    if(props.children){
        headerClass = headerClass.concat(' ', styles.headerResponsive);

        leftColumn = {xs: 0, sm: 0};
    }

    return (<Row gutter={[0, 20]}>
        <Col {...leftColumn} md={4}></Col>
        <Col xs={16} sm={16} md={16}>
            <h1 className={headerClass}>{props.title}</h1>
        </Col>
        <Col xs={4} sm={4} md={4}>{props.children && props.children}</Col>
    </Row>);
}