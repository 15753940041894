import moment from 'moment';
import React from 'react';

interface Props {
    name: string;
    date: string;
}

export default function (props: Props) {

    return (<>
        <strong>{props.name}</strong> ({moment(props.date).format('DD-MMM-yyy')})
    </>);
}