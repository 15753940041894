import { Tooltip } from 'antd';
import React from 'react';
import styles from './PurchaseType.module.css';
import { purchaseTypes } from '../../../models/PurchaseTypes';

interface Props {
    typeCode: string;
}

export default function (props: Props) {

    const type = purchaseTypes.find(s => s.shortName === props.typeCode);

    if (!type) {
        return (<></>);
    }
    return (<Tooltip title={type.name}>
        <span className={styles.type} style={{ color: type.colorCode }}>{type.shortName}</span>
    </Tooltip>)
}