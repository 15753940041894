import { ColumnType } from 'antd/lib/table';
import React from 'react';
import { CardRecord } from '../../../models/Cards';
import UfoTable from '../../utils/UfoTable/UfoTable';
import DateWithIsoWeek from '../Common/DateWithIsoWeek';
import { OnTableChangeFunc } from '../Common/tableChange';
import CardPin from './CardPin';
import CardTypes from './CardProductCell';

interface Props {
    items: CardRecord[];
    totalCount: number;
    pageSize: number,
    pageIndex: number,
    inProgress: boolean;
    onTableChange: OnTableChangeFunc;
}


const columns: ColumnType<CardRecord>[] = [
    {
        title: 'Pan No.',
        dataIndex: 'panNumber',
        key: 'panNumber',
    },
    {
        title: 'Embossed Lines',
        key: 'embossedLines',
        render: (_, record) => <>{record.embossed1}{record.embossed2 && <><br />{record.embossed2}</>}</>
    },
    {
        title: 'Issue No.',
        dataIndex: 'issueNumber',
        key: 'issueNumber',
    },
    {
        title: 'Issue Date',
        key: 'issueDate',
        dataIndex: 'issueDate',
        render: (value: string) => <DateWithIsoWeek date={value} />
    },
    {
        title: 'Expiry Date',
        key: 'expiryDate',
        dataIndex: 'expiryDate',
        render: (value: string) => <DateWithIsoWeek date={value} />
    },
    {
        title: 'Stop Date',
        key: 'stopDate',
        dataIndex: 'stopDate',
        render: (value: string) => <DateWithIsoWeek date={value} />
    },
    {
        title: 'Products',
        key: 'products',
        dataIndex: 'products',
        render: (value: number[]) => <CardTypes products={value} /> 
    },
    {
        title: 'Cost Centre',
        key: 'costCentre',
        dataIndex: 'costCentre',
    },
    {
        title: 'PIN',
        key: 'pinNumber',
        dataIndex: 'pinNumber',
        align: 'center',
        width: 100,
        render: (value: string) => <CardPin pin={value} />
    }
]

export default function (props: Props) {


    return (<UfoTable size="small"
        onChange={props.onTableChange}
        columns={columns}
        dataSource={props.items}
        bordered={true}
        rowKey="id"
        loading={{ delay: 300, size: 'large', spinning: props.inProgress }}
        scroll={{ x: 'max-content' }}
        pagination={{total: props.totalCount, current: props.pageIndex, pageSize: props.pageSize}}
    />);
}