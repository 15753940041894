import { SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { apiCallerGlobal } from '../../../backend/apiCaller';
import PageHeader from '../../utils/PageHeader/PageHeader';
import PageTitle from '../../utils/PageTitle';
import { useUfoErrorHandling } from '../../utils/UfoErrors/ufoErrorHook';
import UfoErrorList from '../../utils/UfoErrors/UfoErrorList';
import FormBox from '../Common/FormBox';

export default function () {


    const [inProgress, setInProgress] = useState(false);

    const errorHandler = useUfoErrorHandling();

    const [form] = useForm();

    const onFinish = async (values: any) => {

        setInProgress(true);

        try {

            await apiCallerGlobal().postJson('/api/contact/send', {
                message: values.message
            });

            setInProgress(false);

            form.resetFields();
            
            notification.success({message: 'Your message has been sent successfully'});


        } catch (error) {

            setInProgress(false);

            errorHandler.setError(error);
        }
    };


    return (<>
        <PageHeader title="Contact" />
        <PageTitle title="Contact Us" />
        <FormBox>
            <p>You can contact us either sending an email to <a href="mailto:cust.support@ukfuels.co.uk" target="_blank" rel="noreferrer">cust.support@ukfuels.co.uk</a>  address or using the below form. <br /> If you reach out to us using the form, we will send a copy of your message to your email address.</p>
            <Form form={form} onFinish={onFinish} layout="vertical" onChange={errorHandler.reset}>
                <Form.Item
                    // label="Message"
                    name="message"
                    rules={[{ required: true }, {max: 4000}, {min: 10}]}
                >
                    <Input.TextArea  autoComplete="off" disabled={inProgress} rows={12} />
                </Form.Item>
                <Button block icon={<SendOutlined />} size="large" type="primary" htmlType="submit" loading={inProgress}>Send</Button>
                <UfoErrorList withMarginTop apiError={errorHandler.apiError} />
            </Form>
        </FormBox>
    </>);
}