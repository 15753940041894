import { Col } from 'antd';
import React, { ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

export default function (props: Props) {
    return (<Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={7}>
        {props.children}
    </Col>);
}