import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { StockProjectedRecord } from '../../../models/Stocks2';
import UfoTable from '../../utils/UfoTable/UfoTable';
import NumberNegativable from '../Common/NumberNegativable';
import StockProjectedDate from './StockProjectedDate';

interface Props {
    items: StockProjectedRecord[];
}

export default function (props: Props) {


    const columns: ColumnsType<StockProjectedRecord> = [
        {
            title: 'Date',
            key: 'date',
            render: (_, record) => <StockProjectedDate name={record.name} date={record.date}  />
        },
        {
            title: 'Projected Stock Balance - Midnight (Litres)',
            key: 'midnight',
            dataIndex: 'midnight',
            render: (value: number) => <NumberNegativable value={value} />
        },
        {
            title: 'Projected Number of Days Stock',
            key: 'days',
            dataIndex: 'days',
            render: (value: number) => <NumberNegativable value={value} />
        }, 
    ];



    return (
        <UfoTable
            columns={columns}
            dataSource={props.items}
            bordered={true}
            rowKey="name"
            size="small"
            pagination={false}
            scroll={{ x: 'max-content' }}
        />);
}