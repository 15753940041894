import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { CustomerChooserData } from '../../../models/Customers';
import { UserRecord } from '../../../models/Users';
import { useModalState } from '../../utils/modalStateHook';
import UserEditModalForm from './UserEditModalForm';

interface Props {
    refresh(): void;
    record: UserRecord;
    customers: CustomerChooserData[];
}

export default function (props: Props) {

    const modalState = useModalState();

    return (<>
        <Button type="default" shape="circle" onClick={modalState.open} className='' ><EditOutlined /></Button>
        {modalState.show && <UserEditModalForm show={modalState.show} onClose={modalState.close} onSuccess={props.refresh} customers={props.customers} record={props.record} />}
    </>);
}