import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { StockDailyRecord } from '../../../models/Stocks2';
import UfoTable from '../../utils/UfoTable/UfoTable';
import NumberNegativable from '../Common/NumberNegativable';
import StockDate from './StockDate';

interface Props {
    items: StockDailyRecord[];
    inProgress: boolean;
    hasTpAddBack: boolean;
}

export default function (props: Props) {


    const columns: ColumnsType<StockDailyRecord> = [
        {
            title: 'Week to Date',
            dataIndex: 'date',
            key: 'date',
            render: (value: string) => <StockDate date={value} />
        },
        {
            title: 'Opening Stock',
            key: 'opening',
            dataIndex: 'opening',
            render: (value: number) => <NumberNegativable value={value} />
        }, {
            title: 'Purchased',
            key: 'purchased',
            dataIndex: 'purchased',
            render: (value: number) => <NumberNegativable value={value} />
        }, {
            title: 'Stock Credit',
            key: 'credit',
            dataIndex: 'credit',
            render: (value: number) => <NumberNegativable value={value} />
        }, {
            title: 'Drawings',
            key: 'diesel',
            dataIndex: 'diesel',
            render: (value: number) => <NumberNegativable value={value} />
        }, {
            title: 'Stock Debit',
            key: 'debit',
            dataIndex: 'debit',
            render: (value: number) => <NumberNegativable value={value} negative />
        }, {
            title: 'Closing Stock',
            key: 'closing',
            dataIndex: 'closing',
            render: (value: number) => <NumberNegativable value={value} />
        }];


    if (props.hasTpAddBack) {
        columns.splice(5, 0, {
            title: 'TP Add-back',
            key: 'tpAddBack',
            render: (_, record) => <NumberNegativable value={record.tpAddBack} />
        });
    }


    return (
        <UfoTable
            columns={columns}
            dataSource={props.items}
            bordered={true}
            rowKey="id"
            loading={{ delay: 300, size: 'large', spinning: props.inProgress }}
            size="small"
            pagination={false}
            scroll={{ x: 'max-content' }}
        />);
}