import { Space } from 'antd';
import React from 'react';
import styles from './UfoButtonSection.module.css';

interface Props {
    children?: React.ReactNode;
    disableMargin?: boolean;
}

export default function (props: Props) {

    const classes = [styles.row];

    if(!props.disableMargin){
        classes.push(styles.margin);
    }

    return (<div className={classes.join(' ')}>
        <Space>
            {props.children}
        </Space>
    </div>);
}