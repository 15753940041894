import { FileExcelOutlined, FileOutlined, FilePdfOutlined, FileTextOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './DocumentItemIcon.module.css';

interface Props {
    extension: string;
}

export default function (props: Props) {
    return (<div className={styles.wrapper}>
        <SpecificIcon extension={props.extension} />
        {/* <FileOutlined className={styles.file} /> */}
    </div>)
}


function SpecificIcon (props: Props) {

    const extension = props.extension.toLowerCase();

    if(extension === 'pdf'){
        return (<FilePdfOutlined className={styles.pdf} />)
    }

    if(extension === 'xls' || extension === 'xlsx'){
        return (<FileExcelOutlined className={styles.excel} />)
    }

    if(extension === 'csv'){
        return (<FileTextOutlined className={styles.csv} />);
    }

    return (<FileOutlined className={styles.general} />);
}