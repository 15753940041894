import Auth from '@aws-amplify/auth';
import { Alert, Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { getCognitoErrorMessage } from '../../../auth/cognitoErrors';
import { cognitoPasswordPattern } from '../../../auth/cognitoPassword';
import useAuthenticatedUser from '../../../auth/useAuthenticatedUser';
import PageTitle from '../../utils/PageTitle';
import ChangePasswordSuccess from './ChangePasswordSuccess';


export default function () {

    const [inProgress, setInProgress] = useState(false);

    const [error, setError] = useState('');

    const [showSuccess, setShowSuccess] = useState(false);

    const currentUser = useAuthenticatedUser();

    const [form] = useForm();

    const onFinish = async (values: any) => {

        setInProgress(true);

        try {
            await Auth.changePassword(currentUser.user.cognitoUser, values.oldPassword, values.newPassword);

            setInProgress(false);

            form.resetFields();
            setError('');

            //message.success('Password change was successful', 20);
            setShowSuccess(true);


        } catch (error) {

            setInProgress(false);

            setError(getCognitoErrorMessage(error));
        }
    };

    return (<>
        <PageTitle title="Change Password" />

        {!showSuccess && <>
            <p>
                The password length must be 12 characters at least and it must contain:<br />
            </p>
            <ul>
                <li>lowercase character</li>
                <li>uppercase character</li>
                <li>number</li>
                <li>special character: <i>_#$!|()=.,?*+-</i></li>
            </ul>

            <Form
                method="post"
                action="https://cognito-idp.eu-west-2.amazonaws.com/ukfuelsonline"
                id="changePassword"
                name="changePassword"
                key="changePasswordForm"

                form={form}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
                style={{ marginTop: 30 }}
            >
                <input type="text" name="Username" autoComplete="username"
                    value={currentUser.user.userName} style={{ display: 'none' }} readOnly />
                <Form.Item
                    label="Old Password"
                    name="oldPassword"
                    rules={[{ required: true }]}
                >
                    <Input.Password size="large" autoComplete="current-password" disabled={inProgress} />
                </Form.Item>

                <Form.Item
                    label="New Password"
                    name="newPassword"
                    rules={[{ required: true, message: 'Please input your new password!' },
                    { min: 12, message: 'Minimum length is 12' },
                    { pattern: RegExp(cognitoPasswordPattern), message: 'The new password doesn\'t match the above criteria' }]}
                >
                    <Input.Password size="large" autoComplete="new-password" disabled={inProgress} minLength={12} pattern={cognitoPasswordPattern} />
                </Form.Item>

                {/* <Form.Item
                name="confirmPassword"
                label="Confirm New Password"
                rules={[{ required: true, message: 'Please confirm you password' },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('The password doesn\'t match with your desired new password!'));
                    },
                })]}
            > 
                <Input.Password size="large" autoComplete="new-password" disabled={inProgress} />
            </Form.Item> */}

                <Form.Item >
                    <Button type="primary" onClick={form.submit} size="middle" loading={inProgress}>
                        Change
                    </Button>
                </Form.Item>
                {error !== '' && !inProgress && <Alert message={error} type="error" showIcon />}
            </Form>
        </>}
        {showSuccess && <ChangePasswordSuccess hide={() => setShowSuccess(false)} />}
    </>);
}