import { useEffect, useState } from "react";

export function useInvalidatableInitEffect(refreshMethod: () => void, data: {isInitialized: boolean}){
    
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if(!data.isInitialized || !loaded){
            refreshMethod();
            setLoaded(true);
        }

    }, [data.isInitialized]);
}