import React from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { useSelectorShallow } from '../../../store/selectorShallow';
import { reloadAdminCustomerRelatedData } from '../../../store/sliceLogics/AdminCustomerGroupSliceLogic';
import { adminCustomerGroupSlice, fetchAdminCustomerGroup } from '../../../store/slices/AdminCustomerGroupSlice';
import { useDisableCustomerChooserEffect } from '../../utils/disableCustomerChooser';
import PageHeader from '../../utils/PageHeader/PageHeader';
import PageTitle from '../../utils/PageTitle';
import UfoButtonSection from '../../utils/UfoButtonSection/UfoButtonSection';
import { useInvalidatableInitEffect } from '../../utils/usePageDataLoad';
import { useTableChange } from '../Common/tableChange';
import AdminCustomerGroupTable from './AdminCustomerGroupTable';
import AdminCustomerGroupTableFilter from './AdminCustomerGroupTableFilter';
import AdminCustomerGroupCreateButton from './Create/AdminCustomerGroupCreateButton';

export default function () {
    useDisableCustomerChooserEffect();

    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.adminCustomerGroups);

    function refresh() {
        dispatch(fetchAdminCustomerGroup());
    }

    function onFormSuccess() {
        refresh();
        reloadAdminCustomerRelatedData(dispatch);
    }

    useInvalidatableInitEffect(refresh, {isInitialized: true});

    const onTableChange = useTableChange(fetchAdminCustomerGroup, adminCustomerGroupSlice.actions.setSortOrder, adminCustomerGroupSlice.actions.setPageIndex);



    return (<>
        <PageHeader title="Customer Group Administration" />
        <PageTitle title="Customer Group Administration" />

        <UfoButtonSection>
            <AdminCustomerGroupCreateButton onSuccess={onFormSuccess} />
        </UfoButtonSection>

        <AdminCustomerGroupTableFilter
            search={reduxData.search}
            onSearchChange={value => {
                dispatch(adminCustomerGroupSlice.actions.setSearch(value));
                refresh();
            }}

            noCustomerGroupDisabled={reduxData.hasNoCustomerGroupDisabled}
            noCustomerGroup={reduxData.hasNoCustomerGroup}
            onNoCustomerGroupChange={value => {
                dispatch(adminCustomerGroupSlice.actions.setHasNoCustomerGroup(value));
                refresh();
            }}

            noCustomers={reduxData.hasNoCustomer}
            onNoCustomersChange={value => {
                dispatch(adminCustomerGroupSlice.actions.setHasNoCustomer(value));
                refresh();
            }}

            noManagers={reduxData.hasNoManager}
            onNoManagersChange={value => {
                dispatch(adminCustomerGroupSlice.actions.setHasNoManager(value));
                refresh();
            }}
        />

        <AdminCustomerGroupTable onSuccess={onFormSuccess} inProgress={reduxData.inProgress} pageIndex={reduxData.pageIndex} pageSize={reduxData.pageSize} results={reduxData.data}
            onTableChange={onTableChange} />
    </>);
}