import { Col, Row } from 'antd';
import React from 'react';
import { StockWeeklyRecord } from '../../../../models/Stocks2';
import NumberNegativable from '../../Common/NumberNegativable';

interface Props {
    record: StockWeeklyRecord;
    isoWeek: string;
}

export default function (props: Props) {

    const record = props.record;

    return (<div>
        <Row>
            <Col span={24}>
                ISO Week: {props.isoWeek}<br />
                <br />
            </Col>
            <Col span={12}>
                <table style={{width:'15em'}}>
                    <tbody>
                        <tr>
                            <td>Open Stock:</td>
                            <td style={{textAlign: 'right'}}> <NumberNegativable value={record.opening} /></td>
                        </tr>
                        <tr>
                            <td>Purchased:</td>
                            <td style={{textAlign: 'right'}}><NumberNegativable value={record.purchased} /></td>
                        </tr>
                        <tr>
                            <td>Stock Credits:</td>
                            <td style={{textAlign: 'right'}}><NumberNegativable value={record.credit} /></td>
                        </tr>
                        <tr>
                            <td>Drawings:</td>
                            <td style={{textAlign: 'right'}}><NumberNegativable value={record.diesel} /></td>
                        </tr>
                        <tr>
                            <td>Stock Debits:</td>
                            <td style={{textAlign: 'right'}}><NumberNegativable value={record.debit} /></td>
                        </tr>
                        <tr>
                            <td>Closing Stock:</td>
                            <td style={{textAlign: 'right'}}><NumberNegativable value={record.closing} /></td>
                        </tr>
                    </tbody>
                </table>
            </Col>
        </Row>
        <br />
    </div>);
}