import { Table, TableProps } from 'antd';
import React from 'react';
import styles from './UfoTable.module.scss';

interface Props{
    verticalScroll?: boolean;
    darkBorder?: boolean;
}

export default function<TRecord  extends object = any> (props: TableProps<TRecord> & Props) {
    
    const classNames = [styles.table, props.className ?? ''];


    if(props.darkBorder){
        classNames.push(styles.darkBorder);
    }

    

    let scroll = {
        ...props.scroll
    };

    if(props.verticalScroll){
        scroll = {
            ...scroll,
            x: 'max-content'
        };
    }

    if(props.darkBorder){
        
    }
    
    return (<Table className={classNames.join(' ')} {...props} scroll={scroll} />);
}