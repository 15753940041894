import { ApiCallerWithCustomer } from "./ApiCallerWithCustomer";


export class ApiFileDownloader {
    private caller: ApiCallerWithCustomer;

    constructor(caller: ApiCallerWithCustomer) {
        this.caller = caller;
    }

    async download(url: string, queryParameters?: {}) {

        // getting the url from the api

        const response = await this.caller.getJson(url, queryParameters);
        const urlResult = await response.json();

        // Downloading the file based on the received URL
        const fileResponse = await fetch(urlResult.url, {
            method: 'GET',
            
        });

        const fileName = this.getFileNameFromContentDispostionHeader(fileResponse.headers.get('content-disposition'));

        if (!fileName) {
            throw new Error('There is no file received from the server');
        }

        const blob = await fileResponse.blob();

        this.finishDownloadLocally(blob, fileName);
    }

    private getFileNameFromContentDispostionHeader(
        contentDisposition: string | null,
    ): string | undefined {
        const standardPattern = /filename=(["']?)(.+)\1/i;
        const wrongPattern = /filename=([^"'][^;"'\n]+)/i;

        if (contentDisposition) {
            if (standardPattern.test(contentDisposition)) {
                const match = contentDisposition.match(standardPattern);
                if (match && match.length > 2) {
                    return match[2];
                }
            }

            if (wrongPattern.test(contentDisposition)) {
                const match = contentDisposition.match(wrongPattern);
                if (match && match.length > 1) {
                    return match[1];
                }
            }
        }
    }

    private finishDownloadLocally(blob: Blob, fileName: string) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again 
    }
}