export function getCognitoErrorMessage(error: any): string {

    if(!error){
        return "An internal error occured. We are working on the solution";
    }

    if(error.message){
        return error.message;
    }

    switch (error.code) {
        case 'InvalidPasswordException':
            return 'Wrong password format';
        
        case 'NotAuthorizedException':
            return 'Wrong username or password';
        default:
            console.error(error);
            return 'An error occured'
    }
}