import { ColumnType } from 'antd/lib/table/interface';
import React from 'react';
import { PurchaseRecord, PurchaseResults } from '../../../models/Purchases';
import UfoTable from '../../utils/UfoTable/UfoTable';
import DateWithIsoWeek from '../Common/DateWithIsoWeek';
import NameNumberCell from '../Common/NameNumberCell';
import QueryButtom from '../Common/QueryModalForm/QueryButtom';
import { OnTableChangeFunc } from '../Common/tableChange';
import PurchaseQuantity from './PurchaseQuantity';
import PurchaseSummary from './PurchaseSummary';
import PurchaseType from './PurchaseType';



interface Props {
    results: PurchaseResults,
    pageSize: number,
    pageIndex: number,
    inProgress: boolean;
    onTableChange: OnTableChangeFunc;
    refresh(): void;
}

export default function (props: Props) {

    const pagination = {
        total: props.results.totalCount,
        current: props.pageIndex,
        pageSize: props.pageSize,
        showSizeChanger: false,
        showTotal: (total: number, range: any) => {
            if (total === 0) {
                return '0 items';
            }
            if (total === 1) {
                return '1 item';
            }
            if (range[0] === range[1]) {
                return `Item ${range[0]} of ${total} items`;
            }
            return `Items ${range[0]}-${range[1]} of ${total} items`;
        }
    }


    const columns: ColumnType<PurchaseRecord>[] = [ 
        {
            title: 'Type',
            dataIndex: 'typeCode',
            key: 'typeCode',
            render: (value: string) => <PurchaseType typeCode={value} />,
            align: 'center'
        },
        {
            title: 'Order No.',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
        },
        {
            title: 'Date',
            key: 'date',
            sorter: true,
            render: (text: string, record: PurchaseRecord) => <DateWithIsoWeek date={record.date} />
        }, {
            title: 'Isoweek',
            key: 'isoWeek',
            dataIndex: 'isoWeek',
        }, {
            title: 'Oil Company',
            key: 'oilCompany',
            dataIndex: 'oilCompany'
        }, {
            title: 'Site',
            key: 'site',
            render: (_, record: PurchaseRecord) => <NameNumberCell numberTitle='Site Number' name={record.siteName} number={record.siteNumber} />
        }, {
            title: 'Ticket No.',
            key: 'ticketNumber',
            dataIndex: 'ticketNumber',
        }, {
            title: 'Quantity (ltr)',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'right',
            render: (text: string, record: PurchaseRecord) => <PurchaseQuantity value={record.quantity} typeCode={record.typeCode} />
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => <QueryButtom id={record.id} refresh={props.refresh} url="/api/purchase/query" canRaiseQuery hasQuery={record.hasQuery} summaryInfo={<PurchaseSummary {...record} />} />
        }];


    return (<UfoTable
        onChange={props.onTableChange}
        columns={columns}
        dataSource={props.results.items}
        bordered={true}
        rowKey="id"
        pagination={pagination}
        loading={{ delay: 300, size: 'large', spinning: props.inProgress }}
        size="small"
    />);
}
