import { Col, Row } from 'antd';
import React from 'react';
import { AdminCustomerGroupItem } from '../../../models/AdminCustomerGroups';
import ClearableTextFilter from '../../utils/ClearableTextFilter';
import UfoCsvDownloadButton from '../../utils/UfoCsvDownloadButton';
import AdminUserRoleFilter from './AdminUserRoleFilter';

interface Props {
    customerGroups: AdminCustomerGroupItem[];
    customerGroupId: string;
    onCustomerGroupChange(id: string): void;

    search: string;
    onSearchChange(value: string): void;

    group: string;
    onGroupChange(value: string): void;
}

export default function (props: Props) {
    return (<Row gutter={[20, 20]} align="bottom">
        <Col sm={11} md={7} lg={6}>
            <ClearableTextFilter value={props.search} onChange={props.onSearchChange} placeholder="First Name, Last Name, Email" title="Search in First Name, Last Name and Email fields" />
        </Col>
        {/* <Col sm={11} md={7} lg={6}>
            <AdminCustomerGroupFilter items={props.customerGroups} value={props.customerGroupId} onChange={props.onCustomerGroupChange} />
        </Col> */}
        <Col  sm={8} md={5} lg={4}>
            <AdminUserRoleFilter value={props.group} onChange={props.onGroupChange} />
        </Col>
        <Col  sm={5} md={5} lg={4}>
        <UfoCsvDownloadButton url="/admin/user/download" parameters={{ search: props.search, role: props.customerGroupId}} />
        </Col>
    </Row>);
}