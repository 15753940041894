import { Tooltip } from 'antd';
import React from 'react';
import { purchaseNamedTypes } from '../../../models/PurchaseTypes';
import NumberNegativable from '../Common/NumberNegativable';

interface Props {
    typeCode: string;
    value: number;
}

export default function (props: Props) {

    let tooltipText: string | undefined = undefined;
    let isNegative = false;

    // console.log(purchaseNamedTypes);

    if(props.typeCode === purchaseNamedTypes.StockDebit.shortName){
        tooltipText = purchaseNamedTypes.StockDebit.name;
        isNegative = true;
    }


    return (<>
        {tooltipText && <Tooltip title={tooltipText}>
            <span><NumberNegativable value={props.value} negative={isNegative} /> </span>
        </Tooltip>}
        {!tooltipText && <NumberNegativable value={props.value} negative={isNegative} />}
    </>);
}