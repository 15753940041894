import { useState } from "react";

export function useModalState(defaultState = false){

    const [show, setShow] = useState(defaultState);

    function close(){
        // console.log({s: show, i: 'close'});
        setShow(false);
    }

    function open(){
        // console.log({s: show, i: 'open'});

        setShow(true);
    }

    return {
        show: show,
        close: close,
        open: open,
    };
}