import { Checkbox, Col, Form, Input, Modal, notification, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useState } from 'react';
import { apiCallerGlobal } from '../../../backend/apiCaller';
import { AdminCustomerGroupItem } from '../../../models/AdminCustomerGroups';
import { AdminUserRecord } from '../../../models/AdminUsers';
import { useUfoErrorHandling } from '../../utils/UfoErrors/ufoErrorHook';
import UfoErrorList from '../../utils/UfoErrors/UfoErrorList';
import PhoneFormItem from '../Common/PhoneFormItem';

interface Props {
    show: boolean;
    onClose(): void;
    onSuccess(): void;
    customerGroups: AdminCustomerGroupItem[];
    record: AdminUserRecord;
}

export default function (props: Props) {

    const [inProgress, setInProgress] = useState(false);

    const [form] = useForm();

    const errorHandler = useUfoErrorHandling();

    const [newCustomerGroupId, setNewCustomerGroupId] = useState('');

    const [confirmedSave, setConfirmedSave] = useState(true);

    const hasCustomerChange = newCustomerGroupId && newCustomerGroupId !== props.record.customerGroupId;

    async function submit(values: any) {

        setInProgress(true);

        try {
            await apiCallerGlobal().postJson('/admin/user/editManager', {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
                customerGroupId: values.customerGroupId,
                id: props.record.id
            });

            setInProgress(false);
            notification.success({message: 'Manager user has been edited successfully'});
            form.resetFields();
            props.onClose();
            props.onSuccess();

        } catch (error) {
            setInProgress(false);
            errorHandler.setError(error);
        }

    }

    const customerGroupRequiredMessage = 'Please select a Customer Group for the Manager';

    return (<Modal visible={props.show} closable destroyOnClose onCancel={props.onClose}
        title="Edit Manager User" okText="Save" okButtonProps={{ disabled: inProgress || !confirmedSave }} confirmLoading={inProgress} onOk={form.submit}>
        <Form form={form} onFinish={submit} layout="vertical" autoComplete="off"

            onFieldsChange={(changedFields => {
                // console.log(changedFields);
                const customerGroupdIdField = changedFields.find(s => (s.name as string[])?.includes('customerGroupId'));

                if (customerGroupdIdField) {

                    setNewCustomerGroupId(customerGroupdIdField.value);
                    setConfirmedSave(false);
                }
            })}

            initialValues={{
                firstName: props.record.firstName,
                lastName: props.record.lastName,
                email: props.record.email,
                phone: props.record.phone,
                customerGroupId: props.record.customerGroupId
            }}
            onChange={errorHandler.reset}
        >

            <Row gutter={10}>
                <Col xs={12}>
                    <FormItem name="firstName" label="First Name" style={{ width: '100%' }}
                        rules={[{ required: true }, { max: 127 }]}>
                        <Input placeholder="First Name" autoComplete="off" />
                    </FormItem>
                </Col>
                <Col xs={12}>
                    <FormItem name="lastName" label="Last Name" style={{ width: '100%' }}
                        rules={[{ required: true }, { max: 127 }]}>
                        <Input placeholder="Last Name" autoComplete="off" />
                    </FormItem>
                </Col>
            </Row>

            <FormItem name="email" label="Email">
                <Input placeholder="Email" autoComplete="off" type="email" disabled readOnly />
            </FormItem>

            <PhoneFormItem />

            <FormItem name="customerGroupId" label="Customer Group"
                rules={[{ required: true, message: customerGroupRequiredMessage }, { min: 1, message: customerGroupRequiredMessage }]}
            >
                <Select>
                    {props.customerGroups?.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                </Select>
            </FormItem>

            {hasCustomerChange && <div>
            <Checkbox checked={confirmedSave} onChange={event => setConfirmedSave(event.target.checked)}>You are about to modify the user's Customer Group from '{props.record.customerGroupName}'. Please check to confirm before save.</Checkbox>
            </div>}

            <UfoErrorList apiError={errorHandler.apiError} />

        </Form>
    </Modal>);
}