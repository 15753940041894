import React, { useEffect } from 'react';
import { useAppDispatch as useDispatch } from '../../../../store/hooks';
import useAuthenticatedUser from '../../../../auth/useAuthenticatedUser';
import { useSelectorShallow } from '../../../../store/selectorShallow';
import { fetchCustomerChooserThunk } from '../../../../store/slices/CustomerChooserSlice';
import AppLoading from '../../AppLoading/AppLoading';

interface Props {
    children: React.ReactNode;
}

export default function (props: Props) {

    const dispatch = useDispatch();

    const currentUser = useAuthenticatedUser();

    const hadFirstInit = useSelectorShallow(s => s.customerChooserData.hadFirstInit);

    useEffect(() => {

        if (currentUser.user.isAuthenticated || !hadFirstInit) {

            dispatch(fetchCustomerChooserThunk());
        }

        // eslint-disable-next-line
    }, [currentUser.user.isAuthenticated, hadFirstInit]);

    if (!currentUser.user.isAuthenticated || !hadFirstInit) {
        return (<AppLoading />);
    }

    return (<>
        {props.children}
    </>);
}