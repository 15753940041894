import { Col } from 'antd';
import React from 'react';
import Menu from './Menu';
import styles from './MenuColumn.module.css';

export default function () {

    return (<Col flex={"70px"} className={styles.hideable}>
    <Menu collapsed/>
</Col>);
}