import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { apiCaller } from '../../../../backend/apiCaller';
import { StockWeeklyRecord } from '../../../../models/Stocks2';
import { useSelectorShallow } from '../../../../store/selectorShallow';
import { useModalState } from '../../../utils/modalStateHook';
import UfoConfirmOperationModal from '../../../utils/UfoConfirmationModal/UfoConfirmOperationModal';
import { useUfoErrorHandling } from '../../../utils/UfoErrors/ufoErrorHook';
import StockWeeklySummary from './StockWeeklySummary';

interface Props {
    record: StockWeeklyRecord;
    refresh(): void;
    isoWeek: string;
}

export default function (props: Props) {

    const modalState = useModalState();
    const currentCustomerNumber = useSelectorShallow(s => s.customerChooserData.selectedNumber);

    const errorHandler = useUfoErrorHandling();


    async function operation() {
        await apiCaller(currentCustomerNumber).postJson('/api/stock/confirmation', { id: props.record.id });
    }

    if (props.record.isUnconfirmed) {



        return (<>
            <Tooltip title='Confirm Stock Record' placement='left'>
                <Button shape='circle' type='primary' icon={<CheckCircleOutlined />} onClick={modalState.open} />
            </Tooltip>

            {modalState.show && <UfoConfirmOperationModal onClose={modalState.close} onSuccess={props.refresh} operation={operation} show={modalState.show} okText="Confirm" okPrimary ufoErrorHandler={errorHandler} checkBoxText="Can you confirm the Stock data above?" >
                <StockWeeklySummary record={props.record} isoWeek={props.isoWeek} />
            </UfoConfirmOperationModal>}
        </>);

    }

    return (<Tooltip title='Already confirmed' placement='left'><Button shape='circle' type='primary' icon={<CheckCircleOutlined />} disabled /></Tooltip>);
}