import React, { useState } from 'react';

import { Form, Input, Button, Alert } from 'antd';
import Auth from '@aws-amplify/auth';
import { getCognitoErrorMessage } from '../cognitoErrors';
import { cognitoPasswordPattern } from '../cognitoPassword';

interface Props {
    navigateLogin: () => void
    navigateForgot: () => void;
    navigateSuccess: () => void;
    userName: string;
}

export default function (props: Props) {

    const [inProgress, setInProgress] = useState(false);

    const [error, setError] = useState('');

    const onFinish = async (values: any) => {

        setInProgress(true);

        try {
            await Auth.forgotPasswordSubmit(props.userName, values.code, values.password);

            setInProgress(false);

            props.navigateSuccess();

        } catch (error) {

            setInProgress(false);

            setError(getCognitoErrorMessage(error));
        }
    };


    return (<>
        <p>Please enter the numeric code that the Portal sent you in email and the desired new password.</p>
        
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            onFinish={onFinish}
            layout="vertical"
        >
            <input type="text" name="Username" autoComplete="username"
                value={props.userName} style={{ display: 'none' }} readOnly />
            <Form.Item
                name="code"

                rules={[{ required: true, message: 'Please input the code!' }]}
            >
                <Input size="large" placeholder="Code" autoComplete="off" disabled={inProgress} />
            </Form.Item>

            <Form.Item
                name="password"

                rules={[{ required: true, message: 'Please input your new password!' },
                    { min: 12, message: 'Minimum length is 12' },
                    { pattern: RegExp(cognitoPasswordPattern), message: 'The password must contain uppercase and lowercase letters, number and and special a character: _#$!|()=.,?*+-' }]}
            >
                <Input.Password size="large" placeholder="New Password" autoComplete="new-password" disabled={inProgress}
                    minLength={12} pattern={cognitoPasswordPattern} />
            </Form.Item>

            <Form.Item
                name="confirmPassword"

                rules={[{ required: true, message: 'Please confirm you password' },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                })]}
            >
                <Input.Password size="large" placeholder="Confirm New Password" autoComplete="new-password" disabled={inProgress} />
            </Form.Item>

            <Form.Item >
                <Button type="primary" htmlType="submit" block size="large" loading={inProgress}>
                    Save
                </Button>
            </Form.Item>
            <div>
                <Button block type="link" onClick={props.navigateForgot}>Resend Code</Button>
            </div>
            <div>
                <Button block type="link" onClick={props.navigateLogin}>Back to Login</Button>
            </div>
            {error !== '' && <Alert message={error} type="error" showIcon />}

        </Form>
    </>
    );
};