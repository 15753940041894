import React from 'react';

export default function () {
    

    // if(appLoadingData.userAuthError){
        
    //     console.error(appLoadingData.userAuthError);

    //     return (<Result
    //         status="500"
    //         title="Oops!"
    //         subTitle="Sorry, something went wrong during the authentication. We are working on the solution. Please visit us again a few moments later."
    //       />);
    // }

    // if(loading){
    //     return (<div>Loading...</div>)
    // }



    return (<div>Loading...</div>)
}