import { combineReducers } from "@reduxjs/toolkit";
import { adminCustomerGroupChooserSlice } from "./slices/AdminCustomerGroupChooserSlice";
import { adminCustomerGroupSlice } from "./slices/AdminCustomerGroupSlice";
import { adminUserSlice } from "./slices/AdminUserSlice";
import { cardSlice } from "./slices/CardSlice";
import { customerChooserSlice } from "./slices/CustomerChooserSlice";
import { documentSlice } from "./slices/DocumentSlice";
import { menuSlice } from "./slices/MenuSlice";
import { purchaseSlice } from "./slices/PurchaseSlice";
import { stockSlice } from "./slices/StockSlice";
import { transactionSlice } from "./slices/TransactionSlice";
import { userSlice } from "./slices/UserSlice";

export const rootReducer = combineReducers({


    transactions: transactionSlice.reducer,
    purchases: purchaseSlice.reducer,
    menu: menuSlice.reducer,
    stock: stockSlice.reducer,
    cards: cardSlice.reducer,
    documents: documentSlice.reducer,
    users: userSlice.reducer,
    customerChooserData: customerChooserSlice.reducer,
    
    adminUsers: adminUserSlice.reducer,
    adminCustomerGroupChooserData: adminCustomerGroupChooserSlice.reducer,
    adminCustomerGroups: adminCustomerGroupSlice.reducer,
});

export type ApplicationState = ReturnType<typeof rootReducer>;

