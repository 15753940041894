import { Space } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import React from 'react';
import { cognitoGroups } from '../../../auth/cognitoGroups';
import { AdminCustomerGroupItem } from '../../../models/AdminCustomerGroups';
import { AdminUserRecord } from '../../../models/AdminUsers';
import UfoTable from '../../utils/UfoTable/UfoTable';
import DateWithIsoWeek from '../Common/DateWithIsoWeek';
import NameNumberCell from '../Common/NameNumberCell';
import { OnTableChangeFunc } from '../Common/tableChange';
// import { OnTableChangeFunc } from '../Common/tableChange';
import UserCustomers from '../Common/UserCustomers';
import AdminUserDeleteButton from './AdminUserDeleteButton';
import AdminUserEditAdminButton from './AdminUserEditAdminButton';
import AdminUserEditManagerButton from './AdminUserEditManagerButton';



interface Props {
    items: AdminUserRecord[];
    totalCount: number;

    onChange: OnTableChangeFunc;
    pageSize: number;
    pageIndex: number;
    inProgress: boolean;
    // onTableChange: OnTableChangeFunc;
    refresh(): void;
    customerGroups: AdminCustomerGroupItem[];
}

export default function (props: Props) {



    const columns: ColumnType<AdminUserRecord>[] = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        }, {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Date Created',
            key: 'dateCreated',
            render: (_, record) => <DateWithIsoWeek date={record.dateCreated} displayTime />
        },
        {
            title: 'Role',
            key: 'role',
            dataIndex: 'groups'
        },
        {
            title: 'Customer Group',
            key: 'customerGroup',
            render: (_, record) => <NameNumberCell numberTitle='Customer' number={record.distributorNumber} name={record.customerGroupName} />
        },
        {
            title: 'Customers',
            key: 'customers',
            render: (_, record) => <UserCustomers items={record.customers} />
        },
        {
            title: 'Last Activity',
            key: 'lastActivity',
            render: (_, record) => <DateWithIsoWeek date={record.lastActivityDate} displayTime />
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => <div style={{ display: 'inline-block', width: '90px', textAlign: 'right' }}><Space>
                {record.groups.includes(cognitoGroups.Admin) && !record.groups.includes(cognitoGroups.Manager) && <AdminUserEditAdminButton refresh={props.refresh} record={record} />}
                {record.groups.includes(cognitoGroups.Manager) && <AdminUserEditManagerButton refresh={props.refresh} record={record} customerGroups={props.customerGroups} />}
                <AdminUserDeleteButton id={record.id} email={record.email} refresh={props.refresh} />
            </Space>
            </div >

        }];

    const pagination = {
        total: props.totalCount,
        current: props.pageIndex,
        pageSize: props.pageSize,
        showSizeChanger: false,
        showTotal: (total: number, range: any) => {
            if (total === 0) {
                return '0 items';
            }
            if (total === 1) {
                return '1 item';
            }
            if (range[0] === range[1]) {
                return `Item ${range[0]} of ${total} items`;
            }
            return `Items ${range[0]}-${range[1]} of ${total} items`;
        }
    }

    return (<UfoTable
        columns={columns}
        dataSource={props.items}
        bordered={true}
        rowKey="id"
        pagination={pagination}
        onChange={props.onChange}
        loading={{ delay: 300, size: 'large', spinning: props.inProgress }}
        size="small"
        verticalScroll
    />);
}
