import React from 'react';
import { AdminCustomerGroupCustomer } from '../../../../models/AdminCustomerGroups';

interface Props {
    customers: AdminCustomerGroupCustomer[];
}

export default function (props: Props) {

    if(props.customers.length === 0){
        return (<></>);
    }

    return (<div style={{marginBottom: '15px'}}>
        {props.customers.map(item => <div key={item.id}>
            <strong>{item.number}</strong> - <span>{item.name}</span>
        </div>)}
    </div>);
}