import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import { apiCallerGlobal } from "../../backend/apiCaller";
import { AdminUserRecord, AdminUserResults } from "../../models/AdminUsers";
import { ApplicationState } from "../rootReducer";

const initialSortOrder = 'createdAt_desc';

export const adminUserSlice = createSlice({
    name: 'admin_users',
    initialState: {
        inProgress: true,
        data: {
            items: [] as AdminUserRecord[],
            totalCount: 0
        } as AdminUserResults,
        pageSize: 10,
        pageIndex: 1,
        sortOrder: initialSortOrder,

        search: '',
        customerGroupId: '',
        group: '',
        isInitialized: false,
    },
    reducers: {
        startLoading(state) { state.inProgress = true },
        endLoading(state) { state.inProgress = false },
        setPage(state, action: PayloadAction<number>) { state.pageIndex = action.payload; },

        setSortOrder(state, action: PayloadAction<string>) {
            if (action.payload) {
                state.sortOrder = action.payload;
            }
            else {
                state.sortOrder = initialSortOrder;
            }
        },
        setData(state, action: PayloadAction<AdminUserResults>) {
            state.data = action.payload;

        },
        setSearch(state, action: PayloadAction<string>) { state.search = action.payload; state.pageIndex = 1; },
        setCustomerGroupId(state, action: PayloadAction<string>) { state.customerGroupId = action.payload;  state.pageIndex = 1; },
        setGroup(state, action: PayloadAction<string>) { state.group = action.payload;  state.pageIndex = 1; },
    }
});

export function fetchAdminUserThunk() {
    return function (dispatch: Dispatch<any>, getState: () => ApplicationState) {

        dispatch(adminUserSlice.actions.startLoading());
        const state = getState().adminUsers;

        return apiCallerGlobal().getJson('/admin/user', {
            page: state.pageIndex,
            pageSize: state.pageSize,
            search: state.search,
            customerGroupId: state.customerGroupId,
            role: state.group,
        })
            .then(response => response.json())
            .then((results: AdminUserResults) => {
                dispatch(adminUserSlice.actions.setData(results));

            })
            .catch(reason => notification.error({ message: 'Can\'t load Users' }))
            .finally(() => dispatch(adminUserSlice.actions.endLoading()));
    }
}