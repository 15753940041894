import React, { useEffect } from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import { useSelectorShallow } from '../../../store/selectorShallow';
import { cardSlice, fetchCardsThunk } from '../../../store/slices/CardSlice';
import PageHeader from '../../utils/PageHeader/PageHeader';
import PageTitle from '../../utils/PageTitle';
import { useTableChange } from '../Common/tableChange';
import CardTable from './CardTable';
import CardTableFilter from './CardTableFilter';

export default function () {

    const dispatch = useDispatch();

    const tableChange = useTableChange(fetchCardsThunk, cardSlice.actions.setSortOrder, cardSlice.actions.setPageIndex);

    const reduxData = useSelectorShallow(s => s.cards);

    useEffect(() => {
        if(!reduxData.isInitialized){
            dispatch(fetchCardsThunk());
        }
        // eslint-disable-next-line
    }, [reduxData.isInitialized]);

    return (<>
        <PageHeader title="Cards" />
        <PageTitle title="Cards" />

        <CardTableFilter

            active={reduxData.active}
            onActiveChange={value => dispatch(cardSlice.actions.setActive(value))}

            stopFrom={reduxData.stopFromDate}
            stopUntil={reduxData.stopUntilDate}
            onStopDateChange={(from, until) => dispatch(cardSlice.actions.setStopRange([from, until]))} 

            search={reduxData.search}
            onSearchChange={value => dispatch(cardSlice.actions.setSearch(value))}

            issueFrom={reduxData.issueFromDate}
            issueUntil={reduxData.issueUntilDate}
            onIssueDateChange={(from, until) => dispatch(cardSlice.actions.setIssueRange([from, until]))}

            expiryFrom={reduxData.expiryFromDate}
            expiryUntil={reduxData.expiryUntilDate}
            onExpiryDateChange={(from, until) => dispatch(cardSlice.actions.setExpiryRange([from, until]))}

            products={reduxData.products}
            onProductsChange={products => dispatch(cardSlice.actions.setProducts(products)) }

        />

        <CardTable inProgress={reduxData.inProgress} items={reduxData.data.items} onTableChange={tableChange} pageIndex={reduxData.pageIndex} pageSize={reduxData.pageSize} totalCount={reduxData.data.totalCount} />
    </>);
}