import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { AdminCustomerGroupRecord } from '../../../../models/AdminCustomerGroups';
import { useModalState } from '../../../utils/modalStateHook';
import AdminCustomerGroupEditForm from './AdminCustomerGroupEditForm';

interface Props {
    record: AdminCustomerGroupRecord;
    onSuccess(): void;
}

export default function (props: Props) {
    const modalState = useModalState();

    return (<>
        <Button type="primary" shape="circle" onClick={modalState.open} ><EditOutlined /></Button>
        {modalState.show && <AdminCustomerGroupEditForm show={modalState.show} onClose={modalState.close} onSuccess={props.onSuccess} record={props.record} />}
    </>);
}