import { MessageOutlined } from '@ant-design/icons';
import { Badge, Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import QueryModalForm from './QueryModalForm';

interface Props {
    refresh(): void;
    hasQuery?: boolean;
    canRaiseQuery?: boolean;
    id: string;
    url: string;
    summaryInfo?: React.ReactNode; 
}

export default function (props: Props) {

    const [show, setShow] = useState(false);

    let tooltip = props.hasQuery ? 'Raise a Query (already raised earlier)' : 'Raise a Query';

    if (props.canRaiseQuery) {
        return (<>
            <Badge dot={props.hasQuery} offset={['-8px', '4px']}>
                <Tooltip title={tooltip}>
                    <Button shape='circle' type='primary' onClick={() => setShow(true)} style={{ marginRight: '5px' }}>
                        <MessageOutlined />
                    </Button>
                </Tooltip>
            </Badge>
            {show && <QueryModalForm show={show} close={() => setShow(false)} refresh={props.refresh} id={props.id} url={props.url} summaryInfo={props.summaryInfo}   />}
        </>);


    }

    return (<Button shape='circle' type='primary' style={{ marginRight: '5px' }} disabled>
        <MessageOutlined />
    </Button>);
}