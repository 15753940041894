import moment from 'moment';
import React from 'react';
import styles from './DateWithIsoWeek.module.css';

interface Props {
    date?: string | null;
    isoWeek?: string;
    displayTime?: boolean
}

export default function (props: Props) {

    if(!props.date || props.date === ''){
        return (<></>);
    }

    const momentDate = moment(props.date);

    // console.log(props.date);

    return (<> 
        <span>{momentDate.format('DD-MMM-YYYY')}</span> {props.displayTime && <span>{momentDate.format('HH:mm')}</span>} {props.isoWeek && <><br />
        <span className={styles.isoWeek}>(Iso week: {props.isoWeek})</span></>}
    </>);
}