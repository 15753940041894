import React from 'react';

interface Props {
    children: React.ReactNode;
}

export default function (props: Props) {
    return (<div style={{paddingTop: '8%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <div style={{marginBottom: 30}}>
            <img src="/images/ukfuelsonline_horizontal.svg" alt="UK Fuels Online logo" width="300px"  />
            {/* style={{border: '1px solid #888'}} */}
        </div>
        <div style={{width: '100%', maxWidth: '450px', padding: '30px 40px', border: '1px solid #ccc'}}>{props.children}</div>
    </div>);
}
