import React from 'react';
import { useAppDispatch as useDispatch } from '../../../store/hooks';
import useAuthenticatedUser from '../../../auth/useAuthenticatedUser';
import { useSelectorShallow } from '../../../store/selectorShallow';
import { fetchUserThunk } from '../../../store/slices/UserSlice';
import { useDisableCustomerChooserEffect } from '../../utils/disableCustomerChooser';
import PageHeader from '../../utils/PageHeader/PageHeader';
import PageTitle from '../../utils/PageTitle';
import UfoParagraphForTable from '../../utils/UfoParagraph/UfoParagraphForTable';
import { useInvalidatableInitEffect } from '../../utils/usePageDataLoad';
import UserCreateButton from './UserCreateButton';
import UserTable from './UserTable';

export default function () {

    const dispatch = useDispatch();

    const reduxData = useSelectorShallow(s => s.users);

    const customers = useSelectorShallow(s => s.customerChooserData.data);

    const currentUser = useAuthenticatedUser().user;

    if (currentUser.isAdmin || currentUser.isManager) {
        useDisableCustomerChooserEffect();
    }

    function refresh() {
        dispatch(fetchUserThunk());
    }

    useInvalidatableInitEffect(refresh, reduxData);

    return (<>
        <PageTitle title="Users" />
        <PageHeader title="Users" />

        {(currentUser.isAdmin || currentUser.isManager) && <>
            <UfoParagraphForTable> You can see the users of all Customers within you Customer Group in the below list, because you are {currentUser.isManager ? 'a Customer Group Manager' : 'an Administrator'}, so the currently selected Customer (global filter on the top of the page) doesn't affect the below listing.</UfoParagraphForTable>
            <div style={{ textAlign: 'right' }}>
                <UserCreateButton refresh={refresh} customers={customers} />
            </div>
        </>}
        <UserTable items={reduxData.filteredData} inProgress={reduxData.inProgress} isManagerOrAbove={currentUser.isManager || currentUser.isAdmin} refresh={refresh} customers={customers} />
    </>);
}