import { CloseCircleFilled } from '@ant-design/icons';
import { Drawer } from 'antd';
import React from 'react';
import { useAppDispatch as useDispatch } from '../../../../store/hooks';
import { useSelectorShallow } from '../../../../store/selectorShallow';
import { menuSlice } from '../../../../store/slices/MenuSlice';
import Menu from './Menu';
import styles from './MenuDrawer.module.css';

interface Props {
}

export default function (props: Props) {

    const dispatch = useDispatch();

    const show = !useSelectorShallow(s => s.menu.collapsed);

    return (<Drawer visible={show} className={styles.drawer} onClose={() => dispatch(menuSlice.actions.toogleCollapsed())} placement="left"
        closeIcon={<CloseCircleFilled />}
        width="100%"
    >
        <div className={styles.filler}></div>
        <Menu />
    </Drawer>);
}