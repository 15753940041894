import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { AdminCustomerGroupCustomer } from '../../../../models/AdminCustomerGroups';
import { useModalState } from '../../../utils/modalStateHook';
import AdminCustomerGroupCustomerEditForm from './AdminCustomerGroupCustomerEditForm';

interface Props {
    record: AdminCustomerGroupCustomer;
    onSuccess(): void;
}

export default function (props: Props) {
    const modalState = useModalState();

    return (<>
        <Button title="Edit Basic Information" type="primary" shape="circle" size="small" onClick={modalState.open} ><EditOutlined /></Button>
        {modalState.show && <AdminCustomerGroupCustomerEditForm show={modalState.show} onClose={modalState.close} onSuccess={props.onSuccess} record={props.record} />}
    </>);
}