import { List } from "antd";
import { DocumentRecord } from "../../../models/Documents";
import { useSelectorShallow } from "../../../store/selectorShallow";
import DocumentItem from "./DocumentItem";

interface Props {
  items: DocumentRecord[];
  inProgress: boolean;
}

export default function (props: Props) {

  const currentCustomerNumber = useSelectorShallow(s => s.customerChooserData.selectedNumber);

  return (<List
    loading={props.inProgress}
    grid={{
      gutter: 16,
      xs: 3,
      sm: 3,
      md: 4,
      lg: 5,
      xl: 7,
      xxl: 7,
    }}
    dataSource={props.items}
    renderItem={(record) => (
      <List.Item>
        <DocumentItem item={record} currentCustomerNumber={currentCustomerNumber} />
      </List.Item>
    )}
    style={{ marginTop: '25px' }}
  />);
}