import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { useState } from 'react';
import { apiCallerGlobal } from '../../../../backend/apiCaller';
import { AdminCustomerGroupRecord } from '../../../../models/AdminCustomerGroups';
import { useUfoErrorHandling } from '../../../utils/UfoErrors/ufoErrorHook';
import UfoErrorList from '../../../utils/UfoErrors/UfoErrorList';
import UfoFormInfo from '../../../utils/UfoFormSection/UfoFormInfo';
import UfoFormSection from '../../../utils/UfoFormSection/UfoFormSection';
import AdminCustomerGroupCustomerList from './AdminCustomerGroupCustomerList';

interface Props {
    record: AdminCustomerGroupRecord;
    show: boolean;
    onClose(): void;
    onSuccess(): void;
}

export default function (props: Props) {

    const [inProgress, setInProgress] = useState(false);

    const [form] = useForm();

    const errorHandler = useUfoErrorHandling();


    async function submit(values: any) {

        setInProgress(true);

        try {
            await apiCallerGlobal().postJson('/admin/customergroup/edit', {
                id: props.record.id,
                name: values.name,
                newCustomers: values.newCustomers,
                existingCustomerNumbers: values.existingCustomerNumbers,
            });

            setInProgress(false);
            form.resetFields();
            props.onClose();
            props.onSuccess();

        } catch (error) {
            setInProgress(false);
            errorHandler.setError(error);
        }

    }

    return (<Modal visible={props.show} closable destroyOnClose onCancel={props.onClose} width={600}
        title="Edit Customer Group" okText="Save" okButtonProps={{ disabled: inProgress }} confirmLoading={inProgress} onOk={form.submit}>
        <Form form={form} onFinish={submit} layout="vertical" autoComplete="off"
            initialValues={{
                name: props.record.name,
            }}
            onChange={errorHandler.reset}
        >

            <Row gutter={10}>
                <Col xs={18}>
                    <FormItem name="name" label="Customer Group Name" style={{ width: '100%' }}
                        rules={[{ required: true }, { max: 127 }]}>
                        <Input placeholder="Group Name" autoComplete="off" />
                    </FormItem>
                </Col>
            </Row>
            <UfoFormSection title="New Customers">
                <UfoFormInfo>
                    <p>
                        You can add new Customers (who are not already added to the Portal) .
                    </p>
                </UfoFormInfo>
                <Form.List name="newCustomers">
                    {(fields, { add, remove }, { errors }) => (<>
                        {fields.map(({ key, name, ...restField }) => (<Row gutter={8} key={key}>
                            <Col sm={6}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'number']}
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    <Input placeholder="Cust. No." title='Customer Number' />
                                </Form.Item>
                            </Col>
                            <Col sm={15}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'name']}
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    <Input placeholder="Customer Name" />
                                </Form.Item>
                            </Col>

                            <Tooltip title="Remove"><CloseOutlined onClick={() => remove(name)} /></Tooltip>
                        </Row>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                Add New Customer
                            </Button>
                        </Form.Item>
                    </>)}
                </Form.List>
            </UfoFormSection>
            <UfoFormSection title="Existing Customers">
                <UfoFormInfo>
                    <p>
                        You can add existing Customers to this new Group. You can select Customers which
                    </p>
                    <ul>
                        <li>are not already attached to a Group or </li>
                    </ul>
                    <p>
                        At the moment, you can type any Customer Number here and you receive a validation message during the submission if the provided Customer Numbers don't fit to the above rules.<br />
                        <strong>Note: </strong> A further restriction is that you cannot remove an existing Customer from the Group. Please use the "Remove" button next to the Customer in the table to remove the assignment.
                    </p>
                </UfoFormInfo>
            </UfoFormSection>
            <AdminCustomerGroupCustomerList customers={props.record.customers} />
            <Form.List name="existingCustomerNumbers">
                {(fields, { add, remove }, { errors }) => (<>
                    <Row gutter={[12, 12]} >
                        {fields.map(({ key, name, ...restField }) => (
                            <><Col sm={10} key={`${key}_x`}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'number']}
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    <Input placeholder="Cust. No." title='Customer Number' />
                                </Form.Item>
                            </Col>
                                <Col key={`${key}_y`}>
                                    <Tooltip title="Remove"><CloseOutlined onClick={() => remove(name)} /></Tooltip>

                                </Col>
                            </>
                        ))
                        }
                    </Row>
                    <Form.Item>
                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                            Add Existing Customer
                        </Button>
                    </Form.Item>
                </>)}
            </Form.List>
            <UfoErrorList apiError={errorHandler.apiError} />
        </Form>
    </Modal>);
}