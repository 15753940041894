import { Button, Result } from 'antd';
import React from 'react';

interface Props {
    navigateLogin: () => void;
}

export default function (props: Props) {
    return (<Result
        status="success"
        title="Password reset was successful!"
        subTitle="Now you can use your new password on the Login Page."
        extra={[
          <Button type="link" key="console" onClick={props.navigateLogin}>
            Go to Login Page
          </Button>,
        ]}
      />);
}