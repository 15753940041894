import { Checkbox, Modal, notification } from 'antd';
import React, { useState } from 'react';
import { UfoErrorHandler } from '../UfoErrors/ufoErrorHook';
import UfoErrorList from '../UfoErrors/UfoErrorList';

interface Props {
    show: boolean;
    onClose(): void;
    onSuccess(): void;
    title?: string;
    okText?: string;
    okPrimary?: boolean;
    operation(): Promise<void>;
    successMessage?: string;
    errorMessage?: string;
    children?: React.ReactNode;
    checkBoxText?: string;

    ufoErrorHandler?: UfoErrorHandler;
}

export default function (props: Props) {

    const [inProgress, setInProgress] = useState(false);

    const [confirmed, setConfirmed] = useState(false);

    function onClose() {
        setInProgress(false);
        setConfirmed(false);

        if(props.ufoErrorHandler){
            props.ufoErrorHandler.reset();
        }

        props.onClose();
    }

    async function submit() {
        try {
            setInProgress(true);

            await props.operation();

            if (props.successMessage) {
                notification.success({ message: props.successMessage });
            }

            setInProgress(false);
            onClose();
            props.onSuccess();
            
        } catch (error) {
            if(props.ufoErrorHandler){
                props.ufoErrorHandler.setError(error);
            } else{
                notification.error({message: props.errorMessage ?? 'The operation was not successful'});
            }
            setInProgress(false);
        }
    }



    return (<Modal visible={props.show} closable destroyOnClose onCancel={onClose}
        title={props.title ?? 'Confirmation'} okText={props.okText ?? 'Yes'} okButtonProps={{ danger: props.okPrimary !== true, disabled: !confirmed || inProgress }} confirmLoading={inProgress} onOk={submit}>
            {props.children}
        <div>
            <Checkbox checked={confirmed} onChange={event => setConfirmed(event.target.checked)}>{props.checkBoxText ?? 'Please check before confirming'} </Checkbox>
        </div>
        {props.ufoErrorHandler && <UfoErrorList smallHeader withMarginTop apiError={props.ufoErrorHandler.apiError} />}
    </Modal>);
}