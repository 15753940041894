import { Tooltip } from 'antd';
import React from 'react';
import { AdminCustomerGroupManager } from '../../../models/AdminCustomerGroups';
import UfoInnerTable, { UfoInnerTableRow } from '../../utils/UfoTable/UfoInnerTable';

interface Props {
    managers: AdminCustomerGroupManager[];
}

export default function (props: Props) {
    return (<UfoInnerTable>
        {props.managers.map(item => <UfoInnerTableRow key={item.id}>
            <td><Tooltip title={`Email: ${item.email}`}><span style={{textDecorationLine: 'underline', textDecorationStyle: 'dashed', textDecorationColor: '#666', textUnderlineOffset: '2px'}}>{item.name}</span></Tooltip></td>
        </UfoInnerTableRow>)}
    </UfoInnerTable>);
}